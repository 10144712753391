export const codeList = [{
  'code': '600101',
  'children': [{
    'code': '8440100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '广州市'
  }, {
    'code': '8440200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '韶关市'
  }, {
    'code': '8440300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '深圳市'
  }, {
    'code': '8440400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '珠海市'
  }, {
    'code': '8440500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '汕头市'
  }, {
    'code': '8440600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '佛山市'
  }, {
    'code': '8440700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '江门市'
  }, {
    'code': '8440800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '湛江市'
  }, {
    'code': '8440900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '茂名市'
  }, {
    'code': '8441200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '肇庆市'
  }, {
    'code': '8441300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '惠州市'
  }, {
    'code': '8441400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '梅州市'
  }, {
    'code': '8441500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '汕尾市'
  }, {
    'code': '8441600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '河源市'
  }, {
    'code': '8441700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '阳江市'
  }, {
    'code': '8441800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '清远市'
  }, {
    'code': '8441900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '东莞市'
  }, {
    'code': '8442000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '中山市'
  }, {
    'code': '8445100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '潮州市'
  }, {
    'code': '8445200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '揭阳市'
  }, {
    'code': '8445300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '云浮市'
  }],
  'text': '广东省'
}, {
  'code': '600102',
  'children': [{
    'code': '8310100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '市辖区'
  }],
  'text': '上海市'
}, {
  'code': '600103',
  'children': [{
    'code': '8320100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '南京市'
  }, {
    'code': '8320200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '无锡市'
  }, {
    'code': '8320300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '徐州市'
  }, {
    'code': '8320400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '常州市'
  }, {
    'code': '8320500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '苏州市'
  }, {
    'code': '8320600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '南通市'
  }, {
    'code': '8320700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '连云港市'
  }, {
    'code': '8320800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '淮安市'
  }, {
    'code': '8320900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '盐城市'
  }, {
    'code': '8321000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '扬州市'
  }, {
    'code': '8321100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '镇江市'
  }, {
    'code': '8321200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '泰州市'
  }, {
    'code': '8321300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '宿迁市'
  }],
  'text': '江苏省'
}, {
  'code': '600104',
  'children': [{
    'code': '8330100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '杭州市'
  }, {
    'code': '8330200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '宁波市'
  }, {
    'code': '8330300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '温州市'
  }, {
    'code': '8330400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '嘉兴市'
  }, {
    'code': '8330500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '湖州市'
  }, {
    'code': '8330600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '绍兴市'
  }, {
    'code': '8330700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '金华市'
  }, {
    'code': '8330800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '衢州市'
  }, {
    'code': '8330900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '舟山市'
  }, {
    'code': '8331000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '台州市'
  }, {
    'code': '8331100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '丽水市'
  }],
  'text': '浙江省'
}, {
  'code': '600105',
  'children': [{
    'code': '8350100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '福州市'
  }, {
    'code': '8350200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '厦门市'
  }, {
    'code': '8350300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '莆田市'
  }, {
    'code': '8350400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '三明市'
  }, {
    'code': '8350500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '泉州市'
  }, {
    'code': '8350600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '漳州市'
  }, {
    'code': '8350700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '南平市'
  }, {
    'code': '8350800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '龙岩市'
  }, {
    'code': '8350900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '宁德市'
  }],
  'text': '福建省'
}, {
  'code': '600201',
  'children': [{
    'code': '8510100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '成都市'
  }, {
    'code': '8510300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '自贡市'
  }, {
    'code': '8510400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '攀枝花市'
  }, {
    'code': '8510500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '泸州市'
  }, {
    'code': '8510600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '德阳市'
  }, {
    'code': '8510700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '绵阳市'
  }, {
    'code': '8510800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '广元市'
  }, {
    'code': '8510900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '遂宁市'
  }, {
    'code': '8511000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '内江市'
  }, {
    'code': '8511100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '乐山市'
  }, {
    'code': '8511300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '南充市'
  }, {
    'code': '8511400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '眉山市'
  }, {
    'code': '8511500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '宜宾市'
  }, {
    'code': '8511600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '广安市'
  }, {
    'code': '8511700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '达州市'
  }, {
    'code': '8511800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '雅安市'
  }, {
    'code': '8511900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '巴中市'
  }, {
    'code': '8512000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '资阳市'
  }, {
    'code': '8513200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '阿坝藏族羌族自治州'
  }, {
    'code': '8513300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '甘孜藏族自治州'
  }, {
    'code': '8513400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '凉山彝族自治州'
  }],
  'text': '四川省'
}, {
  'code': '600202',
  'children': [{
    'code': '8420100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '武汉市'
  }, {
    'code': '8420200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '黄石市'
  }, {
    'code': '8420300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '十堰市'
  }, {
    'code': '8420500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '宜昌市'
  }, {
    'code': '8420600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '襄阳市'
  }, {
    'code': '8420700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '鄂州市'
  }, {
    'code': '8420800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '荆门市'
  }, {
    'code': '8420900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '孝感市'
  }, {
    'code': '8421000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '荆州市'
  }, {
    'code': '8421100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '黄冈市'
  }, {
    'code': '8421200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '咸宁市'
  }, {
    'code': '8421300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '随州市'
  }, {
    'code': '8422800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '恩施土家族苗族自治州'
  }, {
    'code': '8429000',
    'children': [{
      'code': '429021',
      'text': '神农架林区'
    }],
    'text': '省直辖县级行政区划'
  }, {
    'code': '8429004',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '仙桃市'
  }, {
    'code': '8429005',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '潜江市'
  }, {
    'code': '8429006',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '天门市'
  }, {
    'code': '8429021',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '林区（神农架）'
  }],
  'text': '湖北省'
}, {
  'code': '600203',
  'children': [{
    'code': '8430100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '长沙市'
  }, {
    'code': '8430200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '株洲市'
  }, {
    'code': '8430300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '湘潭市'
  }, {
    'code': '8430400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '衡阳市'
  }, {
    'code': '8430500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '邵阳市'
  }, {
    'code': '8430600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '岳阳市'
  }, {
    'code': '8430700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '常德市'
  }, {
    'code': '8430800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '张家界市'
  }, {
    'code': '8430900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '益阳市'
  }, {
    'code': '8431000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '郴州市'
  }, {
    'code': '8431100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '永州市'
  }, {
    'code': '8431200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '怀化市'
  }, {
    'code': '8431300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '娄底市'
  }, {
    'code': '8433100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '湘西土家族苗族自治州'
  }],
  'text': '湖南省'
}, {
  'code': '600204',
  'children': [{
    'code': '8610100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '西安市'
  }, {
    'code': '8610200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '铜川市'
  }, {
    'code': '8610300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '宝鸡市'
  }, {
    'code': '8610400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '咸阳市'
  }, {
    'code': '8610500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '渭南市'
  }, {
    'code': '8610600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '延安市'
  }, {
    'code': '8610700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '汉中市'
  }, {
    'code': '8610800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '榆林市'
  }, {
    'code': '8610900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '安康市'
  }, {
    'code': '8611000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '商洛市'
  }],
  'text': '陕西省'
}, {
  'code': '600205',
  'children': [{
    'code': '8530100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '昆明市'
  }, {
    'code': '8530300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '曲靖市'
  }, {
    'code': '8530400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '玉溪市'
  }, {
    'code': '8530500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '保山市'
  }, {
    'code': '8530600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '昭通市'
  }, {
    'code': '8530700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '丽江市'
  }, {
    'code': '8530800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '普洱市'
  }, {
    'code': '8530900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '临沧市'
  }, {
    'code': '8532300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '楚雄彝族自治州'
  }, {
    'code': '8532500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '红河哈尼族彝族自治州'
  }, {
    'code': '8532600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '文山壮族苗族自治州'
  }, {
    'code': '8532800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '西双版纳傣族自治州'
  }, {
    'code': '8532900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '大理白族自治州'
  }, {
    'code': '8533100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '德宏傣族景颇族自治州'
  }, {
    'code': '8533300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '怒江傈僳族自治州'
  }, {
    'code': '8533400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '迪庆藏族自治州'
  }],
  'text': '云南省'
}, {
  'code': '600301',
  'children': [{
    'code': '8340100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '合肥市'
  }, {
    'code': '8340200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '芜湖市'
  }, {
    'code': '8340300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '蚌埠市'
  }, {
    'code': '8340400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '淮南市'
  }, {
    'code': '8340500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '马鞍山市'
  }, {
    'code': '8340600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '淮北市'
  }, {
    'code': '8340700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '铜陵市'
  }, {
    'code': '8340800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '安庆市'
  }, {
    'code': '8341000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '黄山市'
  }, {
    'code': '8341100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '滁州市'
  }, {
    'code': '8341200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '阜阳市'
  }, {
    'code': '8341300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '宿州市'
  }, {
    'code': '8341400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '巢湖市'
  }, {
    'code': '8341500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '六安市'
  }, {
    'code': '8341600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '亳州市'
  }, {
    'code': '8341700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '池州市'
  }, {
    'code': '8341800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '宣城市'
  }],
  'text': '安徽省'
}, {
  'code': '600302',
  'children': [{
    'code': '8450100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '南宁市'
  }, {
    'code': '8450200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '柳州市'
  }, {
    'code': '8450300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '桂林市'
  }, {
    'code': '8450400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '梧州市'
  }, {
    'code': '8450500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '北海市'
  }, {
    'code': '8450600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '防城港市'
  }, {
    'code': '8450700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '钦州市'
  }, {
    'code': '8450800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '贵港市'
  }, {
    'code': '8450900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '玉林市'
  }, {
    'code': '8451000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '百色市'
  }, {
    'code': '8451100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '贺州市'
  }, {
    'code': '8451200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '河池市'
  }, {
    'code': '8451300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '来宾市'
  }, {
    'code': '8451400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '崇左市'
  }],
  'text': '广西壮族自治区'
}, {
  'code': '600303',
  'children': [{
    'code': '8650100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '乌鲁木齐市'
  }, {
    'code': '8650200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '克拉玛依市'
  }, {
    'code': '8650208',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '和丰'
  }, {
    'code': '8652100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '吐鲁番地区'
  }, {
    'code': '8652200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '哈密地区'
  }, {
    'code': '8652300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '昌吉回族自治州'
  }, {
    'code': '8652700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '博尔塔拉蒙古自治州'
  }, {
    'code': '8652800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '巴音郭楞蒙古自治州'
  }, {
    'code': '8652900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '阿克苏地区'
  }, {
    'code': '8653000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '克孜勒苏柯尔克孜自治州'
  }, {
    'code': '8653100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '喀什地区'
  }, {
    'code': '8653200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '和田地区'
  }, {
    'code': '8654000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '伊犁哈萨克自治州'
  }, {
    'code': '8654003',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '奎屯市'
  }, {
    'code': '8654004',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '五五新镇'
  }, {
    'code': '8654005',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '独山子'
  }, {
    'code': '8654006',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '乌苏'
  }, {
    'code': '8654200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '塔城地区'
  }, {
    'code': '8654300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '阿勒泰地区'
  }, {
    'code': '8659001',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '石河子市'
  }, {
    'code': '8659008',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '沙湾'
  }],
  'text': '新疆维吾尔自治区'
}, {
  'code': '600304',
  'children': [{
    'code': '8500100',
    'children': [{
      'code': '8500101',
      'text': '万州区'
    }, {
      'code': '8500102',
      'text': '涪陵区'
    }, {
      'code': '8500103',
      'text': '渝中区'
    }, {
      'code': '8500104',
      'text': '大渡口区'
    }, {
      'code': '8500105',
      'text': '江北区'
    }, {
      'code': '8500106',
      'text': '沙坪坝区'
    }, {
      'code': '8500107',
      'text': '九龙坡区'
    }, {
      'code': '8500108',
      'text': '南岸区'
    }, {
      'code': '8500109',
      'text': '北碚区'
    }, {
      'code': '8500110',
      'text': '万盛区'
    }, {
      'code': '8500111',
      'text': '双桥区'
    }, {
      'code': '8500112',
      'text': '渝北区'
    }, {
      'code': '8500113',
      'text': '巴南区'
    }, {
      'code': '8500114',
      'text': '黔江区'
    }, {
      'code': '8500115',
      'text': '长寿区'
    }, {
      'code': '8500116',
      'text': '江津区'
    }, {
      'code': '8500117',
      'text': '合川区'
    }, {
      'code': '8500118',
      'text': '永川区'
    }, {
      'code': '8500119',
      'text': '南川区'
    }],
    'text': '市辖区'
  }],
  'text': '重庆市'
}, {
  'code': '600305',
  'children': [{
    'code': '8360100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '南昌市'
  }, {
    'code': '8360200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '景德镇市'
  }, {
    'code': '8360300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '萍乡市'
  }, {
    'code': '8360400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '九江市'
  }, {
    'code': '8360500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '新余市'
  }, {
    'code': '8360600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '鹰潭市'
  }, {
    'code': '8360700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '赣州市'
  }, {
    'code': '8360800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '吉安市'
  }, {
    'code': '8360900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '宜春市'
  }, {
    'code': '8361000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '抚州市'
  }, {
    'code': '8361100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '上饶市'
  }],
  'text': '江西省'
}, {
  'code': '600401',
  'children': [{
    'code': '8620100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '兰州市'
  }, {
    'code': '8620200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '嘉峪关市'
  }, {
    'code': '8620300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '金昌市'
  }, {
    'code': '8620400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '白银市'
  }, {
    'code': '8620500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '天水市'
  }, {
    'code': '8620600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '武威市'
  }, {
    'code': '8620700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '张掖市'
  }, {
    'code': '8620800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '平凉市'
  }, {
    'code': '8620900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '酒泉市'
  }, {
    'code': '8621000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '庆阳市'
  }, {
    'code': '8621100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '定西市'
  }, {
    'code': '8621200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '陇南市'
  }, {
    'code': '8622900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '临夏回族自治州'
  }, {
    'code': '8623000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '甘南藏族自治州'
  }],
  'text': '甘肃省'
}, {
  'code': '600402',
  'children': [{
    'code': '8520100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '贵阳市'
  }, {
    'code': '8520200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '六盘水市'
  }, {
    'code': '8520300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '遵义市'
  }, {
    'code': '8520400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '安顺市'
  }, {
    'code': '8522200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '铜仁地区'
  }, {
    'code': '8522300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '黔西南布依族苗族自治州'
  }, {
    'code': '8522400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '毕节地区'
  }, {
    'code': '8522600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '黔东南苗族侗族自治州'
  }, {
    'code': '8522700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '黔南布依族苗族自治州'
  }, {
    'code': '8529000',
    'children': [{
      'code': '8529001',
      'text': '湖潮生态新城'
    }, {
      'code': '8529002',
      'text': '马场科技新城'
    }, {
      'code': '8529003',
      'text': '花溪大学城'
    }],
    'text': '贵安新区'
  }],
  'text': '贵州省'
}, {
  'code': '600403',
  'children': [{
    'code': '8460100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '海口市'
  }],
  'text': '海南省'
}, {
  'code': '600404',
  'children': [{
    'code': '8640100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '银川市'
  }, {
    'code': '8640200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '石嘴山市'
  }, {
    'code': '8640300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '吴忠市'
  }, {
    'code': '8640400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '固原市'
  }, {
    'code': '8640500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '中卫市'
  }],
  'text': '宁夏回族自治区'
}, {
  'code': '600405',
  'children': [{
    'code': '8630100',
    'children': [{
      'code': '630101',
      'text': '西宁'
    }, {
      'code': '630121',
      'text': '大通'
    }, {
      'code': '630122',
      'text': '湟中'
    }, {
      'code': '630123',
      'text': '湟源'
    }],
    'text': '西宁市'
  }, {
    'code': '8632100',
    'children': [{
      'code': '632121',
      'text': '平安县'
    }, {
      'code': '632122',
      'text': '民和回族土族自治县'
    }, {
      'code': '632123',
      'text': '乐都县'
    }, {
      'code': '632126',
      'text': '互助土族自治县'
    }, {
      'code': '632127',
      'text': '化隆回族自治县'
    }, {
      'code': '632128',
      'text': '循化撒拉族自治县'
    }],
    'text': '海东地区'
  }, {
    'code': '8632200',
    'children': [{
      'code': '632221',
      'text': '门源回族自治县'
    }, {
      'code': '632222',
      'text': '祁连县'
    }, {
      'code': '632223',
      'text': '海晏县'
    }, {
      'code': '632224',
      'text': '刚察县'
    }, {
      'code': '632299',
      'text': '西海镇'
    }],
    'text': '海北藏族自治州'
  }, {
    'code': '8632300',
    'children': [{
      'code': '632321',
      'text': '同仁县'
    }, {
      'code': '632322',
      'text': '尖扎县'
    }, {
      'code': '632323',
      'text': '泽库县'
    }, {
      'code': '632324',
      'text': '河南蒙古族自治县'
    }],
    'text': '黄南藏族自治州'
  }, {
    'code': '8632500',
    'children': [{
      'code': '632521',
      'text': '共和县'
    }, {
      'code': '632522',
      'text': '同德县'
    }, {
      'code': '632523',
      'text': '贵德县'
    }, {
      'code': '632524',
      'text': '兴海县'
    }, {
      'code': '632525',
      'text': '贵南县'
    }],
    'text': '海南藏族自治州'
  }, {
    'code': '8632600',
    'children': [{
      'code': '632621',
      'text': '玛沁县'
    }, {
      'code': '632622',
      'text': '班玛县'
    }, {
      'code': '632623',
      'text': '甘德县'
    }, {
      'code': '632624',
      'text': '达日县'
    }, {
      'code': '632625',
      'text': '久治县'
    }, {
      'code': '632626',
      'text': '玛多县'
    }],
    'text': '果洛藏族自治州'
  }, {
    'code': '8632700',
    'children': [{
      'code': '632721',
      'text': '玉树县'
    }, {
      'code': '632722',
      'text': '杂多县'
    }, {
      'code': '632723',
      'text': '称多县'
    }, {
      'code': '632724',
      'text': '治多县'
    }, {
      'code': '632725',
      'text': '囊谦县'
    }, {
      'code': '632726',
      'text': '曲麻莱县'
    }],
    'text': '玉树藏族自治州'
  }, {
    'code': '8632800',
    'children': [{
      'code': '632802',
      'text': '德令哈市'
    }, {
      'code': '632821',
      'text': '乌兰县'
    }, {
      'code': '632822',
      'text': '都兰县'
    }, {
      'code': '632823',
      'text': '天峻'
    }, {
      'code': '632897',
      'text': '茫崖'
    }, {
      'code': '632898',
      'text': '冷湖'
    }, {
      'code': '632899',
      'text': '大柴旦'
    }],
    'text': '海西蒙古族藏族自治州'
  }, {
    'code': '8632801',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '格尔木市'
  }],
  'text': '青海省'
}, {
  'code': '600406',
  'children': [{
    'code': '8540100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '拉萨市'
  }, {
    'code': '8542100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '昌都地区'
  }, {
    'code': '8542200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '山南地区'
  }, {
    'code': '8542300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '日喀则地区'
  }, {
    'code': '8542400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '那曲地区'
  }, {
    'code': '8542500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '阿里地区'
  }, {
    'code': '8542600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '林芝地区'
  }],
  'text': '西藏自治区'
}, {
  'code': '609001',
  'children': [{
    'code': '8110100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '市辖区'
  }],
  'text': '北京'
}, {
  'code': '609801',
  'children': [{
    'code': '/',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '/'
  }],
  'text': '港澳台'
}, {
  'code': '609902',
  'children': [{
    'code': '8120100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '市辖区'
  }],
  'text': '天津市'
}, {
  'code': '609903',
  'children': [{
    'code': '8370100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '济南市'
  }, {
    'code': '8370200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '青岛市'
  }, {
    'code': '8370300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '淄博市'
  }, {
    'code': '8370400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '枣庄市'
  }, {
    'code': '8370500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '东营市'
  }, {
    'code': '8370600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '烟台市'
  }, {
    'code': '8370700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '潍坊市'
  }, {
    'code': '8370800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '济宁市'
  }, {
    'code': '8370900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '泰安市'
  }, {
    'code': '8371000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '威海市'
  }, {
    'code': '8371100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '日照市'
  }, {
    'code': '8371300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '临沂市'
  }, {
    'code': '8371400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '德州市'
  }, {
    'code': '8371500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '聊城市'
  }, {
    'code': '8371600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '滨州市'
  }, {
    'code': '8371700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '菏泽市'
  }],
  'text': '山东省'
}, {
  'code': '609904',
  'children': [{
    'code': '8410100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '郑州市'
  }, {
    'code': '8410200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '开封市'
  }, {
    'code': '8410300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '洛阳市'
  }, {
    'code': '8410400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '平顶山市'
  }, {
    'code': '8410500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '安阳市'
  }, {
    'code': '8410600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '鹤壁市'
  }, {
    'code': '8410700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '新乡市'
  }, {
    'code': '8410800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '焦作市'
  }, {
    'code': '8410900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '濮阳市'
  }, {
    'code': '8411000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '许昌市'
  }, {
    'code': '8411100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '漯河市'
  }, {
    'code': '8411200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '三门峡市'
  }, {
    'code': '8411300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '南阳市'
  }, {
    'code': '8411400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '商丘市'
  }, {
    'code': '8411500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '信阳市'
  }, {
    'code': '8411600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '周口市'
  }, {
    'code': '8411700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '驻马店市'
  }, {
    'code': '8419001',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '济源市'
  }],
  'text': '河南省'
}, {
  'code': '609905',
  'children': [{
    'code': '8210100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '沈阳市'
  }, {
    'code': '8210200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '大连市'
  }, {
    'code': '8210300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '鞍山市'
  }, {
    'code': '8210400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '抚顺市'
  }, {
    'code': '8210500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '本溪市'
  }, {
    'code': '8210600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '丹东市'
  }, {
    'code': '8210700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '锦州市'
  }, {
    'code': '8210800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '营口市'
  }, {
    'code': '8210900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '阜新市'
  }, {
    'code': '8211000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '辽阳市'
  }, {
    'code': '8211100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '盘锦市'
  }, {
    'code': '8211200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '铁岭市'
  }, {
    'code': '8211300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '朝阳市'
  }, {
    'code': '8211400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '葫芦岛市'
  }],
  'text': '辽宁省'
}, {
  'code': '609906',
  'children': [{
    'code': '8130100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '石家庄市'
  }, {
    'code': '8130200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '唐山市'
  }, {
    'code': '8130300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '秦皇岛市'
  }, {
    'code': '8130400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '邯郸市'
  }, {
    'code': '8130500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '邢台市'
  }, {
    'code': '8130600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '保定市'
  }, {
    'code': '8130700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '张家口市'
  }, {
    'code': '8130800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '承德市'
  }, {
    'code': '8130900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '沧州市'
  }, {
    'code': '8131000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '廊坊市'
  }, {
    'code': '8131100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '衡水市'
  }],
  'text': '河北省'
}, {
  'code': '609907',
  'children': [{
    'code': '8140100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '太原市'
  }, {
    'code': '8140200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '大同市'
  }, {
    'code': '8140300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '阳泉市'
  }, {
    'code': '8140400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '长治市'
  }, {
    'code': '8140500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '晋城市'
  }, {
    'code': '8140600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '朔州市'
  }, {
    'code': '8140700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '晋中市'
  }, {
    'code': '8140800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '运城市'
  }, {
    'code': '8140900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '忻州市'
  }, {
    'code': '8141000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '临汾市'
  }, {
    'code': '8141100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '吕梁市'
  }],
  'text': '山西省'
}, {
  'code': '609908',
  'children': [{
    'code': '8150100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '呼和浩特市'
  }, {
    'code': '8150200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '包头市'
  }, {
    'code': '8150300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '乌海市'
  }, {
    'code': '8150400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '赤峰市'
  }, {
    'code': '8150500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '通辽市'
  }, {
    'code': '8150600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '鄂尔多斯市'
  }, {
    'code': '8150700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '呼伦贝尔市'
  }, {
    'code': '8150800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '巴彦淖尔市'
  }, {
    'code': '8150900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '乌兰察布市'
  }, {
    'code': '8152200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '兴安盟'
  }, {
    'code': '8152500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '锡林郭勒盟'
  }, {
    'code': '8152900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '阿拉善盟'
  }],
  'text': '内蒙古自治区'
}, {
  'code': '609909',
  'children': [{
    'code': '8220100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '长春市'
  }, {
    'code': '8220200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '吉林市'
  }, {
    'code': '8220300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '四平市'
  }, {
    'code': '8220400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '辽源市'
  }, {
    'code': '8220500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '通化市'
  }, {
    'code': '8220600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '白山市'
  }, {
    'code': '8220700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '松原市'
  }, {
    'code': '8220800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '白城市'
  }, {
    'code': '8222400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '延边朝鲜族自治州'
  }],
  'text': '吉林省'
}, {
  'code': '609910',
  'children': [{
    'code': '8230100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '哈尔滨市'
  }, {
    'code': '8230200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '齐齐哈尔市'
  }, {
    'code': '8230300',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '鸡西市'
  }, {
    'code': '8230400',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '鹤岗市'
  }, {
    'code': '8230500',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '双鸭山市'
  }, {
    'code': '8230600',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '大庆市'
  }, {
    'code': '8230700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '伊春市'
  }, {
    'code': '8230800',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '佳木斯市'
  }, {
    'code': '8230900',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '七台河市'
  }, {
    'code': '8231000',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '牡丹江市'
  }, {
    'code': '8231100',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '黑河市'
  }, {
    'code': '8231200',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '绥化市'
  }, {
    'code': '8231281',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '安达市'
  }, {
    'code': '8231282',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '肇东市'
  }, {
    'code': '8232700',
    'children': [{
      'code': '',
      'text': '无'
    }],
    'text': '大兴安岭地区'
  }],
  'text': '黑龙江省'
}];