export const areaList = [{
  'code': '002001',
  'children': [{
    'code': '002001001',
    'children': [{
      'code': '002001001001',
      'text': '东城区'
    }, {
      'code': '002001001002',
      'text': '西城区'
    }, {
      'code': '002001001003',
      'text': '崇文区'
    }, {
      'code': '002001001004',
      'text': '宣武区'
    }, {
      'code': '002001001005',
      'text': '朝阳区'
    }, {
      'code': '002001001006',
      'text': '丰台区'
    }, {
      'code': '002001001007',
      'text': '石景山区'
    }, {
      'code': '002001001008',
      'text': '海淀区'
    }, {
      'code': '002001001009',
      'text': '门头沟区'
    }, {
      'code': '002001001011',
      'text': '房山区'
    }, {
      'code': '002001001012',
      'text': '通州区'
    }, {
      'code': '002001001013',
      'text': '顺义区'
    }, {
      'code': '002001001014',
      'text': '昌平区'
    }, {
      'code': '002001001015',
      'text': '大兴区'
    }, {
      'code': '002001001016',
      'text': '怀柔区'
    }, {
      'code': '002001001017',
      'text': '平谷区'
    }, {
      'code': '002001001028',
      'text': '密云县'
    }, {
      'code': '002001001029',
      'text': '延庆县'
    }],
    'text': '北京市'
  }],
  'text': '北京'
}, {
  'code': '002002',
  'children': [{
    'code': '002002001',
    'children': [{
      'code': '002002001001',
      'text': '和平区'
    }, {
      'code': '002002001002',
      'text': '河东区'
    }, {
      'code': '002002001003',
      'text': '河西区'
    }, {
      'code': '002002001004',
      'text': '南开区'
    }, {
      'code': '002002001005',
      'text': '河北区'
    }, {
      'code': '002002001006',
      'text': '红桥区'
    }, {
      'code': '002002001010',
      'text': '东丽区'
    }, {
      'code': '002002001011',
      'text': '西青区'
    }, {
      'code': '002002001012',
      'text': '津南区'
    }, {
      'code': '002002001013',
      'text': '北辰区'
    }, {
      'code': '002002001014',
      'text': '武清区'
    }, {
      'code': '002002001015',
      'text': '宝坻区'
    }, {
      'code': '002002001021',
      'text': '宁河县'
    }, {
      'code': '002002001023',
      'text': '静海县'
    }, {
      'code': '002002001025',
      'text': '蓟县'
    }, {
      'code': '002002001026',
      'text': '滨海新区'
    }, {
      'code': '002002001030',
      'text': '塘沽区'
    }, {
      'code': '002002001031',
      'text': '大港区'
    }, {
      'code': '002002001032',
      'text': '汉沽区'
    }, {
      'code': '002002001033',
      'text': '保税区'
    }, {
      'code': '002002001034',
      'text': '经济技术开发区'
    }, {
      'code': '002002001035',
      'text': '高新区'
    }],
    'text': '天津市'
  }],
  'text': '天津'
}, {
  'code': '002003',
  'children': [{
    'code': '002003001',
    'children': [{
      'code': '002003001002',
      'text': '长安区'
    }, {
      'code': '002003001003',
      'text': '桥东区'
    }, {
      'code': '002003001004',
      'text': '桥西区'
    }, {
      'code': '002003001005',
      'text': '新华区'
    }, {
      'code': '002003001007',
      'text': '井陉矿区'
    }, {
      'code': '002003001021',
      'text': '井陉县'
    }, {
      'code': '002003001023',
      'text': '正定县'
    }, {
      'code': '002003001024',
      'text': '栾城县'
    }, {
      'code': '002003001025',
      'text': '行唐县'
    }, {
      'code': '002003001026',
      'text': '灵寿县'
    }, {
      'code': '002003001027',
      'text': '高邑县'
    }, {
      'code': '002003001028',
      'text': '深泽县'
    }, {
      'code': '002003001029',
      'text': '赞皇县'
    }, {
      'code': '002003001030',
      'text': '无极县'
    }, {
      'code': '002003001031',
      'text': '平山县'
    }, {
      'code': '002003001032',
      'text': '元氏县'
    }, {
      'code': '002003001033',
      'text': '赵县'
    }, {
      'code': '002003001081',
      'text': '辛集市'
    }, {
      'code': '002003001082',
      'text': '藁城市'
    }, {
      'code': '002003001083',
      'text': '晋州市'
    }, {
      'code': '002003001084',
      'text': '新乐市'
    }, {
      'code': '002003001085',
      'text': '鹿泉市'
    }, {
      'code': '002003001086',
      'text': '裕华区'
    }, {
      'code': '002003001087',
      'text': '高新技术开发区'
    }],
    'text': '石家庄市'
  }, {
    'code': '002003002',
    'children': [{
      'code': '002003002002',
      'text': '路南区'
    }, {
      'code': '002003002003',
      'text': '路北区'
    }, {
      'code': '002003002004',
      'text': '古冶区'
    }, {
      'code': '002003002005',
      'text': '开平区'
    }, {
      'code': '002003002010',
      'text': '曹妃甸区'
    }, {
      'code': '002003002021',
      'text': '丰润区'
    }, {
      'code': '002003002023',
      'text': '滦县'
    }, {
      'code': '002003002024',
      'text': '滦南县'
    }, {
      'code': '002003002025',
      'text': '乐亭县'
    }, {
      'code': '002003002027',
      'text': '迁西县'
    }, {
      'code': '002003002029',
      'text': '玉田县'
    }, {
      'code': '002003002030',
      'text': '唐海县'
    }, {
      'code': '002003002081',
      'text': '遵化市'
    }, {
      'code': '002003002082',
      'text': '丰南区'
    }, {
      'code': '002003002083',
      'text': '迁安市'
    }, {
      'code': '002003002084',
      'text': '南堡开发区'
    }, {
      'code': '002003002085',
      'text': '海港开发区'
    }, {
      'code': '002003002086',
      'text': '汉沽管理区'
    }, {
      'code': '002003002087',
      'text': '高新区'
    }, {
      'code': '002003002088',
      'text': '芦台开发区'
    }],
    'text': '唐山市'
  }, {
    'code': '002003003',
    'children': [{
      'code': '002003003002',
      'text': '海港区'
    }, {
      'code': '002003003003',
      'text': '山海关区'
    }, {
      'code': '002003003004',
      'text': '北戴河区'
    }, {
      'code': '002003003021',
      'text': '青龙满族自治县'
    }, {
      'code': '002003003022',
      'text': '昌黎县'
    }, {
      'code': '002003003023',
      'text': '抚宁县'
    }, {
      'code': '002003003024',
      'text': '卢龙县'
    }, {
      'code': '002003003025',
      'text': '经济技术开发区'
    }],
    'text': '秦皇岛市'
  }, {
    'code': '002003004',
    'children': [{
      'code': '002003004002',
      'text': '邯山区'
    }, {
      'code': '002003004003',
      'text': '丛台区'
    }, {
      'code': '002003004004',
      'text': '复兴区'
    }, {
      'code': '002003004006',
      'text': '峰峰矿区'
    }, {
      'code': '002003004021',
      'text': '邯郸县'
    }, {
      'code': '002003004023',
      'text': '临漳县'
    }, {
      'code': '002003004024',
      'text': '成安县'
    }, {
      'code': '002003004025',
      'text': '大名县'
    }, {
      'code': '002003004026',
      'text': '涉县'
    }, {
      'code': '002003004027',
      'text': '磁县'
    }, {
      'code': '002003004028',
      'text': '肥乡县'
    }, {
      'code': '002003004029',
      'text': '永年县'
    }, {
      'code': '002003004030',
      'text': '邱县'
    }, {
      'code': '002003004031',
      'text': '鸡泽县'
    }, {
      'code': '002003004032',
      'text': '广平县'
    }, {
      'code': '002003004033',
      'text': '馆陶县'
    }, {
      'code': '002003004034',
      'text': '魏县'
    }, {
      'code': '002003004035',
      'text': '曲周县'
    }, {
      'code': '002003004081',
      'text': '武安市'
    }, {
      'code': '002003004082',
      'text': '经济开发区'
    }, {
      'code': '002003004083',
      'text': '邯郸冀南新区'
    }],
    'text': '邯郸市'
  }, {
    'code': '002003005',
    'children': [{
      'code': '002003005002',
      'text': '桥东区'
    }, {
      'code': '002003005003',
      'text': '桥西区'
    }, {
      'code': '002003005021',
      'text': '邢台县'
    }, {
      'code': '002003005022',
      'text': '临城县'
    }, {
      'code': '002003005023',
      'text': '内丘县'
    }, {
      'code': '002003005024',
      'text': '柏乡县'
    }, {
      'code': '002003005025',
      'text': '隆尧县'
    }, {
      'code': '002003005026',
      'text': '任县'
    }, {
      'code': '002003005027',
      'text': '南和县'
    }, {
      'code': '002003005028',
      'text': '宁晋县'
    }, {
      'code': '002003005029',
      'text': '巨鹿县'
    }, {
      'code': '002003005030',
      'text': '新河县'
    }, {
      'code': '002003005031',
      'text': '广宗县'
    }, {
      'code': '002003005032',
      'text': '平乡县'
    }, {
      'code': '002003005033',
      'text': '威县'
    }, {
      'code': '002003005034',
      'text': '清河县'
    }, {
      'code': '002003005035',
      'text': '临西县'
    }, {
      'code': '002003005081',
      'text': '南宫市'
    }, {
      'code': '002003005082',
      'text': '沙河市'
    }, {
      'code': '002003005083',
      'text': '河北邢台经济开发区'
    }],
    'text': '邢台市'
  }, {
    'code': '002003006',
    'children': [{
      'code': '002003006002',
      'text': '新市区'
    }, {
      'code': '002003006003',
      'text': '北市区'
    }, {
      'code': '002003006004',
      'text': '南市区'
    }, {
      'code': '002003006021',
      'text': '满城县'
    }, {
      'code': '002003006022',
      'text': '清苑县'
    }, {
      'code': '002003006023',
      'text': '涞水县'
    }, {
      'code': '002003006024',
      'text': '阜平县'
    }, {
      'code': '002003006025',
      'text': '徐水县'
    }, {
      'code': '002003006026',
      'text': '定兴县'
    }, {
      'code': '002003006027',
      'text': '唐县'
    }, {
      'code': '002003006028',
      'text': '高阳县'
    }, {
      'code': '002003006029',
      'text': '容城县'
    }, {
      'code': '002003006030',
      'text': '涞源县'
    }, {
      'code': '002003006031',
      'text': '望都县'
    }, {
      'code': '002003006032',
      'text': '安新县'
    }, {
      'code': '002003006033',
      'text': '易县'
    }, {
      'code': '002003006034',
      'text': '曲阳县'
    }, {
      'code': '002003006035',
      'text': '蠡县'
    }, {
      'code': '002003006036',
      'text': '顺平县'
    }, {
      'code': '002003006037',
      'text': '博野县'
    }, {
      'code': '002003006038',
      'text': '雄县'
    }, {
      'code': '002003006081',
      'text': '涿州市'
    }, {
      'code': '002003006082',
      'text': '定州市'
    }, {
      'code': '002003006083',
      'text': '安国市'
    }, {
      'code': '002003006084',
      'text': '高碑店市'
    }, {
      'code': '002003006085',
      'text': '莲池区'
    }, {
      'code': '002003006086',
      'text': '竞秀区'
    }],
    'text': '保定市'
  }, {
    'code': '002003007',
    'children': [{
      'code': '002003007002',
      'text': '桥东区'
    }, {
      'code': '002003007003',
      'text': '桥西区'
    }, {
      'code': '002003007005',
      'text': '宣化区'
    }, {
      'code': '002003007006',
      'text': '下花园区'
    }, {
      'code': '002003007021',
      'text': '宣化县'
    }, {
      'code': '002003007022',
      'text': '张北县'
    }, {
      'code': '002003007023',
      'text': '康保县'
    }, {
      'code': '002003007024',
      'text': '沽源县'
    }, {
      'code': '002003007025',
      'text': '尚义县'
    }, {
      'code': '002003007026',
      'text': '蔚县'
    }, {
      'code': '002003007027',
      'text': '阳原县'
    }, {
      'code': '002003007028',
      'text': '怀安县'
    }, {
      'code': '002003007029',
      'text': '万全县'
    }, {
      'code': '002003007030',
      'text': '怀来县'
    }, {
      'code': '002003007031',
      'text': '涿鹿县'
    }, {
      'code': '002003007032',
      'text': '赤城县'
    }, {
      'code': '002003007033',
      'text': '崇礼县'
    }],
    'text': '张家口市'
  }, {
    'code': '002003008',
    'children': [{
      'code': '002003008002',
      'text': '双桥区'
    }, {
      'code': '002003008003',
      'text': '双滦区'
    }, {
      'code': '002003008004',
      'text': '鹰手营子矿区'
    }, {
      'code': '002003008021',
      'text': '承德县'
    }, {
      'code': '002003008022',
      'text': '兴隆县'
    }, {
      'code': '002003008023',
      'text': '平泉县'
    }, {
      'code': '002003008024',
      'text': '滦平县'
    }, {
      'code': '002003008025',
      'text': '隆化县'
    }, {
      'code': '002003008026',
      'text': '丰宁满族自治县'
    }, {
      'code': '002003008027',
      'text': '宽城满族自治县'
    }, {
      'code': '002003008028',
      'text': '围场满族蒙古族自治县'
    }, {
      'code': '002003008029',
      'text': '承德高新技术产业开发区'
    }],
    'text': '承德市'
  }, {
    'code': '002003009',
    'children': [{
      'code': '002003009002',
      'text': '新华区'
    }, {
      'code': '002003009003',
      'text': '运河区'
    }, {
      'code': '002003009021',
      'text': '沧县'
    }, {
      'code': '002003009022',
      'text': '青县'
    }, {
      'code': '002003009023',
      'text': '东光县'
    }, {
      'code': '002003009024',
      'text': '海兴县'
    }, {
      'code': '002003009025',
      'text': '盐山县'
    }, {
      'code': '002003009026',
      'text': '肃宁县'
    }, {
      'code': '002003009027',
      'text': '南皮县'
    }, {
      'code': '002003009028',
      'text': '吴桥县'
    }, {
      'code': '002003009029',
      'text': '献县'
    }, {
      'code': '002003009030',
      'text': '孟村回族自治县'
    }, {
      'code': '002003009081',
      'text': '泊头市'
    }, {
      'code': '002003009082',
      'text': '任丘市'
    }, {
      'code': '002003009083',
      'text': '黄骅市'
    }, {
      'code': '002003009084',
      'text': '河间市'
    }],
    'text': '沧州市'
  }, {
    'code': '002003010',
    'children': [{
      'code': '002003010002',
      'text': '安次区'
    }, {
      'code': '002003010010',
      'text': '开发区'
    }, {
      'code': '002003010022',
      'text': '固安县'
    }, {
      'code': '002003010023',
      'text': '永清县'
    }, {
      'code': '002003010024',
      'text': '香河县'
    }, {
      'code': '002003010025',
      'text': '大城县'
    }, {
      'code': '002003010026',
      'text': '文安县'
    }, {
      'code': '002003010028',
      'text': '大厂回族自治县'
    }, {
      'code': '002003010081',
      'text': '霸州市'
    }, {
      'code': '002003010082',
      'text': '三河市'
    }, {
      'code': '002003010083',
      'text': '广阳区'
    }],
    'text': '廊坊市'
  }, {
    'code': '002003011',
    'children': [{
      'code': '002003011002',
      'text': '桃城区'
    }, {
      'code': '002003011021',
      'text': '枣强县'
    }, {
      'code': '002003011022',
      'text': '武邑县'
    }, {
      'code': '002003011023',
      'text': '武强县'
    }, {
      'code': '002003011024',
      'text': '饶阳县'
    }, {
      'code': '002003011025',
      'text': '安平县'
    }, {
      'code': '002003011026',
      'text': '故城县'
    }, {
      'code': '002003011027',
      'text': '景县'
    }, {
      'code': '002003011028',
      'text': '阜城县'
    }, {
      'code': '002003011081',
      'text': '冀州市'
    }, {
      'code': '002003011082',
      'text': '深州市'
    }],
    'text': '衡水市'
  }],
  'text': '河北'
}, {
  'code': '002004',
  'children': [{
    'code': '002004001',
    'children': [{
      'code': '002004001005',
      'text': '小店区'
    }, {
      'code': '002004001006',
      'text': '迎泽区'
    }, {
      'code': '002004001007',
      'text': '杏花岭区'
    }, {
      'code': '002004001008',
      'text': '尖草坪区'
    }, {
      'code': '002004001009',
      'text': '万柏林区'
    }, {
      'code': '002004001010',
      'text': '晋源区'
    }, {
      'code': '002004001021',
      'text': '清徐县'
    }, {
      'code': '002004001022',
      'text': '阳曲县'
    }, {
      'code': '002004001023',
      'text': '娄烦县'
    }, {
      'code': '002004001081',
      'text': '古交市'
    }],
    'text': '太原市'
  }, {
    'code': '002004002',
    'children': [{
      'code': '002004002002',
      'text': '城区'
    }, {
      'code': '002004002003',
      'text': '矿区'
    }, {
      'code': '002004002011',
      'text': '南郊区'
    }, {
      'code': '002004002012',
      'text': '新荣区'
    }, {
      'code': '002004002021',
      'text': '阳高县'
    }, {
      'code': '002004002022',
      'text': '天镇县'
    }, {
      'code': '002004002023',
      'text': '广灵县'
    }, {
      'code': '002004002024',
      'text': '灵丘县'
    }, {
      'code': '002004002025',
      'text': '浑源县'
    }, {
      'code': '002004002026',
      'text': '左云县'
    }, {
      'code': '002004002027',
      'text': '大同县'
    }, {
      'code': '002004002028',
      'text': '云州区'
    }, {
      'code': '002004002029',
      'text': '云冈区'
    }, {
      'code': '002004002030',
      'text': '平城区'
    }],
    'text': '大同市'
  }, {
    'code': '002004003',
    'children': [{
      'code': '002004003002',
      'text': '城区'
    }, {
      'code': '002004003003',
      'text': '矿区'
    }, {
      'code': '002004003011',
      'text': '郊区'
    }, {
      'code': '002004003021',
      'text': '平定县'
    }, {
      'code': '002004003022',
      'text': '盂县'
    }],
    'text': '阳泉市'
  }, {
    'code': '002004004',
    'children': [{
      'code': '002004004002',
      'text': '城区'
    }, {
      'code': '002004004011',
      'text': '郊区'
    }, {
      'code': '002004004021',
      'text': '长治县'
    }, {
      'code': '002004004023',
      'text': '襄垣县'
    }, {
      'code': '002004004024',
      'text': '屯留县'
    }, {
      'code': '002004004025',
      'text': '平顺县'
    }, {
      'code': '002004004026',
      'text': '黎城县'
    }, {
      'code': '002004004027',
      'text': '壶关县'
    }, {
      'code': '002004004028',
      'text': '长子县'
    }, {
      'code': '002004004029',
      'text': '武乡县'
    }, {
      'code': '002004004030',
      'text': '沁县'
    }, {
      'code': '002004004031',
      'text': '沁源县'
    }, {
      'code': '002004004081',
      'text': '潞城市'
    }],
    'text': '长治市'
  }, {
    'code': '002004005',
    'children': [{
      'code': '002004005002',
      'text': '城区'
    }, {
      'code': '002004005021',
      'text': '沁水县'
    }, {
      'code': '002004005022',
      'text': '阳城县'
    }, {
      'code': '002004005024',
      'text': '陵川县'
    }, {
      'code': '002004005025',
      'text': '泽州县'
    }, {
      'code': '002004005081',
      'text': '高平市'
    }],
    'text': '晋城市'
  }, {
    'code': '002004006',
    'children': [{
      'code': '002004006002',
      'text': '朔城区'
    }, {
      'code': '002004006003',
      'text': '平鲁区'
    }, {
      'code': '002004006021',
      'text': '山阴县'
    }, {
      'code': '002004006022',
      'text': '应县'
    }, {
      'code': '002004006023',
      'text': '右玉县'
    }, {
      'code': '002004006024',
      'text': '怀仁县'
    }, {
      'code': '002004006025',
      'text': '山西朔州经济开发区'
    }],
    'text': '朔州市'
  }, {
    'code': '002004022',
    'children': [{
      'code': '002004022002',
      'text': '原平市'
    }, {
      'code': '002004022022',
      'text': '定襄县'
    }, {
      'code': '002004022023',
      'text': '五台县'
    }, {
      'code': '002004022025',
      'text': '代县'
    }, {
      'code': '002004022026',
      'text': '繁峙县'
    }, {
      'code': '002004022027',
      'text': '宁武县'
    }, {
      'code': '002004022028',
      'text': '静乐县'
    }, {
      'code': '002004022029',
      'text': '神池县'
    }, {
      'code': '002004022030',
      'text': '五寨县'
    }, {
      'code': '002004022031',
      'text': '岢岚县'
    }, {
      'code': '002004022032',
      'text': '河曲县'
    }, {
      'code': '002004022033',
      'text': '保德县'
    }, {
      'code': '002004022034',
      'text': '偏关县'
    }, {
      'code': '002004022099',
      'text': '忻州市忻府区'
    }],
    'text': '忻州市'
  }, {
    'code': '002004023',
    'children': [{
      'code': '002004023001',
      'text': '孝义市'
    }, {
      'code': '002004023002',
      'text': '离石市'
    }, {
      'code': '002004023003',
      'text': '汾阳市'
    }, {
      'code': '002004023022',
      'text': '文水市'
    }, {
      'code': '002004023023',
      'text': '交城县'
    }, {
      'code': '002004023025',
      'text': '兴县'
    }, {
      'code': '002004023026',
      'text': '临县'
    }, {
      'code': '002004023027',
      'text': '柳林县'
    }, {
      'code': '002004023028',
      'text': '石楼县'
    }, {
      'code': '002004023029',
      'text': '岚县'
    }, {
      'code': '002004023030',
      'text': '方山县'
    }, {
      'code': '002004023032',
      'text': '中阳县'
    }, {
      'code': '002004023033',
      'text': '交口县'
    }],
    'text': '吕梁市'
  }, {
    'code': '002004024',
    'children': [{
      'code': '002004024001',
      'text': '榆次市'
    }, {
      'code': '002004024002',
      'text': '介休市'
    }, {
      'code': '002004024021',
      'text': '榆社县'
    }, {
      'code': '002004024022',
      'text': '左权县'
    }, {
      'code': '002004024023',
      'text': '和顺县'
    }, {
      'code': '002004024024',
      'text': '昔阳县'
    }, {
      'code': '002004024027',
      'text': '寿阳县'
    }, {
      'code': '002004024029',
      'text': '太谷县'
    }, {
      'code': '002004024030',
      'text': '祁县'
    }, {
      'code': '002004024031',
      'text': '平遥县'
    }, {
      'code': '002004024033',
      'text': '灵石县'
    }, {
      'code': '002004024099',
      'text': '晋中市开发区'
    }],
    'text': '晋中市'
  }, {
    'code': '002004026',
    'children': [{
      'code': '002004026002',
      'text': '侯马市'
    }, {
      'code': '002004026003',
      'text': '霍州市'
    }, {
      'code': '002004026021',
      'text': '曲沃县'
    }, {
      'code': '002004026022',
      'text': '翼城县'
    }, {
      'code': '002004026023',
      'text': '襄汾县'
    }, {
      'code': '002004026025',
      'text': '洪洞县'
    }, {
      'code': '002004026027',
      'text': '古县'
    }, {
      'code': '002004026028',
      'text': '安泽县'
    }, {
      'code': '002004026029',
      'text': '浮山县'
    }, {
      'code': '002004026030',
      'text': '吉县'
    }, {
      'code': '002004026031',
      'text': '乡宁县'
    }, {
      'code': '002004026032',
      'text': '蒲县'
    }, {
      'code': '002004026033',
      'text': '大宁县'
    }, {
      'code': '002004026034',
      'text': '永和县'
    }, {
      'code': '002004026035',
      'text': '隰县'
    }, {
      'code': '002004026036',
      'text': '汾西县'
    }, {
      'code': '002004026099',
      'text': '尧都区'
    }],
    'text': '临汾市'
  }, {
    'code': '002004027',
    'children': [{
      'code': '002004027002',
      'text': '永济市'
    }, {
      'code': '002004027003',
      'text': '河津市'
    }, {
      'code': '002004027023',
      'text': '芮城县'
    }, {
      'code': '002004027024',
      'text': '临猗县'
    }, {
      'code': '002004027025',
      'text': '万荣县'
    }, {
      'code': '002004027026',
      'text': '新绛县'
    }, {
      'code': '002004027027',
      'text': '稷山县'
    }, {
      'code': '002004027029',
      'text': '闻喜县'
    }, {
      'code': '002004027030',
      'text': '夏县'
    }, {
      'code': '002004027031',
      'text': '绛县'
    }, {
      'code': '002004027032',
      'text': '平陆县'
    }, {
      'code': '002004027033',
      'text': '垣曲县'
    }, {
      'code': '002004027099',
      'text': '运城市盐湖区'
    }],
    'text': '运城市'
  }],
  'text': '山西'
}, {
  'code': '002005',
  'children': [{
    'code': '002005001',
    'children': [{
      'code': '002005001002',
      'text': '新城区'
    }, {
      'code': '002005001003',
      'text': '回民区'
    }, {
      'code': '002005001004',
      'text': '玉泉区'
    }, {
      'code': '002005001021',
      'text': '土默特左旗'
    }, {
      'code': '002005001022',
      'text': '托克托县'
    }, {
      'code': '002005001023',
      'text': '和林格尔县'
    }, {
      'code': '002005001024',
      'text': '清水河县'
    }, {
      'code': '002005001025',
      'text': '武川县'
    }, {
      'code': '002005001099',
      'text': '赛罕区'
    }],
    'text': '呼和浩特市'
  }, {
    'code': '002005002',
    'children': [{
      'code': '002005002002',
      'text': '东河区'
    }, {
      'code': '002005002003',
      'text': '昆都伦区'
    }, {
      'code': '002005002004',
      'text': '青山区'
    }, {
      'code': '002005002005',
      'text': '石拐区'
    }, {
      'code': '002005002006',
      'text': '白云矿区'
    }, {
      'code': '002005002021',
      'text': '土默特右旗'
    }, {
      'code': '002005002022',
      'text': '固阳县'
    }, {
      'code': '002005002023',
      'text': '达尔罕茂明安联合旗'
    }, {
      'code': '002005002099',
      'text': '包头市九原区'
    }],
    'text': '包头市'
  }, {
    'code': '002005003',
    'children': [{
      'code': '002005003002',
      'text': '海勃湾区'
    }, {
      'code': '002005003003',
      'text': '海南区'
    }, {
      'code': '002005003004',
      'text': '乌达区'
    }],
    'text': '乌海市'
  }, {
    'code': '002005004',
    'children': [{
      'code': '002005004002',
      'text': '红山区'
    }, {
      'code': '002005004003',
      'text': '元宝山区'
    }, {
      'code': '002005004004',
      'text': '松山区'
    }, {
      'code': '002005004021',
      'text': '阿鲁科尔沁旗'
    }, {
      'code': '002005004022',
      'text': '巴林左旗'
    }, {
      'code': '002005004023',
      'text': '巴林右旗'
    }, {
      'code': '002005004024',
      'text': '林西县'
    }, {
      'code': '002005004025',
      'text': '克什克腾旗'
    }, {
      'code': '002005004026',
      'text': '翁牛特旗'
    }, {
      'code': '002005004028',
      'text': '喀喇沁旗'
    }, {
      'code': '002005004029',
      'text': '宁城县'
    }, {
      'code': '002005004030',
      'text': '敖汉旗'
    }],
    'text': '赤峰市'
  }, {
    'code': '002005021',
    'children': [{
      'code': '002005021001',
      'text': '海拉尔区'
    }, {
      'code': '002005021002',
      'text': '满洲里市'
    }, {
      'code': '002005021003',
      'text': '扎兰屯市'
    }, {
      'code': '002005021004',
      'text': '牙克石市'
    }, {
      'code': '002005021005',
      'text': '根河市'
    }, {
      'code': '002005021006',
      'text': '额尔古纳市'
    }, {
      'code': '002005021022',
      'text': '阿荣旗'
    }, {
      'code': '002005021023',
      'text': '莫力达瓦达斡尔族自治旗'
    }, {
      'code': '002005021027',
      'text': '鄂伦春自治旗'
    }, {
      'code': '002005021028',
      'text': '鄂温克族自治旗'
    }, {
      'code': '002005021029',
      'text': '新巴尔虎右旗'
    }, {
      'code': '002005021030',
      'text': '新巴尔虎左旗'
    }, {
      'code': '002005021031',
      'text': '陈巴尔虎旗'
    }, {
      'code': '002005021032',
      'text': '扎赉诺尔区'
    }],
    'text': '呼伦贝尔市'
  }, {
    'code': '002005022',
    'children': [{
      'code': '002005022001',
      'text': '乌兰浩特市'
    }, {
      'code': '002005022002',
      'text': '阿尔山市'
    }, {
      'code': '002005022021',
      'text': '科尔沁右翼前旗'
    }, {
      'code': '002005022022',
      'text': '科尔沁右翼中旗'
    }, {
      'code': '002005022023',
      'text': '扎赉特旗'
    }, {
      'code': '002005022024',
      'text': '突泉县'
    }],
    'text': '兴安盟'
  }, {
    'code': '002005023',
    'children': [{
      'code': '002005023002',
      'text': '霍林郭勒市'
    }, {
      'code': '002005023022',
      'text': '科尔沁左翼中旗'
    }, {
      'code': '002005023023',
      'text': '科尔沁左翼后旗'
    }, {
      'code': '002005023024',
      'text': '开鲁县'
    }, {
      'code': '002005023025',
      'text': '库伦旗'
    }, {
      'code': '002005023026',
      'text': '奈曼旗'
    }, {
      'code': '002005023027',
      'text': '扎鲁特旗'
    }, {
      'code': '002005023098',
      'text': '科尔沁区'
    }],
    'text': '通辽市'
  }, {
    'code': '002005025',
    'children': [{
      'code': '002005025001',
      'text': '二连浩特市'
    }, {
      'code': '002005025002',
      'text': '锡林浩特市'
    }, {
      'code': '002005025022',
      'text': '阿巴嘎旗'
    }, {
      'code': '002005025023',
      'text': '苏尼特左旗'
    }, {
      'code': '002005025024',
      'text': '苏尼特右旗'
    }, {
      'code': '002005025025',
      'text': '东乌珠穆沁旗'
    }, {
      'code': '002005025026',
      'text': '西乌珠穆沁旗'
    }, {
      'code': '002005025027',
      'text': '太仆寺旗'
    }, {
      'code': '002005025028',
      'text': '镶黄旗'
    }, {
      'code': '002005025029',
      'text': '正镶白旗'
    }, {
      'code': '002005025030',
      'text': '正蓝旗'
    }, {
      'code': '002005025031',
      'text': '多伦县'
    }],
    'text': '锡林郭勒盟'
  }, {
    'code': '002005026',
    'children': [{
      'code': '002005026001',
      'text': '集宁区'
    }, {
      'code': '002005026002',
      'text': '丰镇市'
    }, {
      'code': '002005026024',
      'text': '卓资县'
    }, {
      'code': '002005026025',
      'text': '化德县'
    }, {
      'code': '002005026026',
      'text': '商都县'
    }, {
      'code': '002005026027',
      'text': '兴和县'
    }, {
      'code': '002005026029',
      'text': '凉城县'
    }, {
      'code': '002005026030',
      'text': '察哈尔右翼前旗'
    }, {
      'code': '002005026031',
      'text': '察哈尔右翼中旗'
    }, {
      'code': '002005026032',
      'text': '察哈尔右翼后旗'
    }, {
      'code': '002005026034',
      'text': '四子王旗'
    }],
    'text': '乌兰察布市'
  }, {
    'code': '002005027',
    'children': [{
      'code': '002005027001',
      'text': '东胜区'
    }, {
      'code': '002005027010',
      'text': '康巴什新区'
    }, {
      'code': '002005027022',
      'text': '达拉特旗'
    }, {
      'code': '002005027023',
      'text': '准格尔旗'
    }, {
      'code': '002005027024',
      'text': '鄂托克前旗'
    }, {
      'code': '002005027025',
      'text': '鄂托克旗'
    }, {
      'code': '002005027026',
      'text': '杭锦旗'
    }, {
      'code': '002005027027',
      'text': '乌审旗'
    }, {
      'code': '002005027028',
      'text': '伊金霍洛旗'
    }],
    'text': '鄂尔多斯市'
  }, {
    'code': '002005028',
    'children': [{
      'code': '002005028001',
      'text': '临河区'
    }, {
      'code': '002005028022',
      'text': '五原县'
    }, {
      'code': '002005028023',
      'text': '磴口县'
    }, {
      'code': '002005028024',
      'text': '乌拉特前旗'
    }, {
      'code': '002005028025',
      'text': '乌拉特中旗'
    }, {
      'code': '002005028026',
      'text': '乌拉特后旗'
    }, {
      'code': '002005028027',
      'text': '杭锦后旗'
    }],
    'text': '巴彦淖尔市'
  }, {
    'code': '002005029',
    'children': [{
      'code': '002005029021',
      'text': '阿拉善左旗'
    }, {
      'code': '002005029022',
      'text': '阿拉善右旗'
    }, {
      'code': '002005029023',
      'text': '额济纳旗'
    }],
    'text': '阿拉善盟'
  }],
  'text': '内蒙古'
}, {
  'code': '002011',
  'children': [{
    'code': '002011001',
    'children': [{
      'code': '002011001002',
      'text': '和平区'
    }, {
      'code': '002011001003',
      'text': '沈河区'
    }, {
      'code': '002011001004',
      'text': '大东区'
    }, {
      'code': '002011001005',
      'text': '皇姑区'
    }, {
      'code': '002011001006',
      'text': '铁西区'
    }, {
      'code': '002011001010',
      'text': '东陵区'
    }, {
      'code': '002011001011',
      'text': '苏家屯区'
    }, {
      'code': '002011001012',
      'text': '浑南区'
    }, {
      'code': '002011001013',
      'text': '沈北新区'
    }, {
      'code': '002011001014',
      'text': '于洪区'
    }, {
      'code': '002011001022',
      'text': '辽中区'
    }, {
      'code': '002011001023',
      'text': '康平县'
    }, {
      'code': '002011001024',
      'text': '法库县'
    }, {
      'code': '002011001081',
      'text': '新民市'
    }, {
      'code': '002011001082',
      'text': '新城子经济技术开发区'
    }, {
      'code': '002011001083',
      'text': '经济技术开发区'
    }],
    'text': '沈阳市'
  }, {
    'code': '002011002',
    'children': [{
      'code': '002011002002',
      'text': '中山区'
    }, {
      'code': '002011002003',
      'text': '西岗区'
    }, {
      'code': '002011002004',
      'text': '沙河口区'
    }, {
      'code': '002011002010',
      'text': '高新园区'
    }, {
      'code': '002011002011',
      'text': '甘井子区'
    }, {
      'code': '002011002012',
      'text': '旅顺口区'
    }, {
      'code': '002011002013',
      'text': '金州区'
    }, {
      'code': '002011002024',
      'text': '长海县'
    }, {
      'code': '002011002081',
      'text': '瓦房店市'
    }, {
      'code': '002011002082',
      'text': '普兰店市'
    }, {
      'code': '002011002083',
      'text': '庄河市'
    }, {
      'code': '002011002099',
      'text': '开发区'
    }, {
      'code': '002011002100',
      'text': '保税区'
    }],
    'text': '大连市'
  }, {
    'code': '002011003',
    'children': [{
      'code': '002011003002',
      'text': '铁东区'
    }, {
      'code': '002011003003',
      'text': '铁西区'
    }, {
      'code': '002011003004',
      'text': '立山区'
    }, {
      'code': '002011003011',
      'text': '千山区'
    }, {
      'code': '002011003021',
      'text': '台安县'
    }, {
      'code': '002011003023',
      'text': '岫岩满族自治县'
    }, {
      'code': '002011003081',
      'text': '海城市'
    }],
    'text': '鞍山市'
  }, {
    'code': '002011004',
    'children': [{
      'code': '002011004002',
      'text': '新抚区'
    }, {
      'code': '002011004003',
      'text': '露天区'
    }, {
      'code': '002011004004',
      'text': '望花区'
    }, {
      'code': '002011004010',
      'text': '东洲区'
    }, {
      'code': '002011004011',
      'text': '顺城区'
    }, {
      'code': '002011004021',
      'text': '抚顺县'
    }, {
      'code': '002011004022',
      'text': '新宾满族自治区'
    }, {
      'code': '002011004023',
      'text': '清原满族自治区'
    }],
    'text': '抚顺市'
  }, {
    'code': '002011005',
    'children': [{
      'code': '002011005002',
      'text': '平山区'
    }, {
      'code': '002011005003',
      'text': '溪湖区'
    }, {
      'code': '002011005004',
      'text': '明山区'
    }, {
      'code': '002011005005',
      'text': '南芬区'
    }, {
      'code': '002011005021',
      'text': '本溪县'
    }, {
      'code': '002011005022',
      'text': '桓仁县'
    }],
    'text': '本溪市'
  }, {
    'code': '002011006',
    'children': [{
      'code': '002011006002',
      'text': '元宝区'
    }, {
      'code': '002011006003',
      'text': '振兴区'
    }, {
      'code': '002011006004',
      'text': '振安区'
    }, {
      'code': '002011006024',
      'text': '宽甸满族自治县'
    }, {
      'code': '002011006081',
      'text': '东港市'
    }, {
      'code': '002011006082',
      'text': '凤城市'
    }],
    'text': '丹东市'
  }, {
    'code': '002011007',
    'children': [{
      'code': '002011007002',
      'text': '古塔区'
    }, {
      'code': '002011007003',
      'text': '凌河区'
    }, {
      'code': '002011007011',
      'text': '太河区'
    }, {
      'code': '002011007012',
      'text': '北镇市'
    }, {
      'code': '002011007013',
      'text': '经济技术开发区'
    }, {
      'code': '002011007014',
      'text': '太和区'
    }, {
      'code': '002011007026',
      'text': '黑山县'
    }, {
      'code': '002011007027',
      'text': '义县'
    }, {
      'code': '002011007081',
      'text': '凌海市'
    }, {
      'code': '002011007082',
      'text': '北宁市'
    }, {
      'code': '002011007083',
      'text': '松山新区'
    }],
    'text': '锦州市'
  }, {
    'code': '002011008',
    'children': [{
      'code': '002011008002',
      'text': '站前区'
    }, {
      'code': '002011008003',
      'text': '西市区'
    }, {
      'code': '002011008004',
      'text': '鲅鱼圈区'
    }, {
      'code': '002011008011',
      'text': '老边区'
    }, {
      'code': '002011008081',
      'text': '盖州市'
    }, {
      'code': '002011008082',
      'text': '大石桥市'
    }],
    'text': '营口市'
  }, {
    'code': '002011009',
    'children': [{
      'code': '002011009002',
      'text': '海州区'
    }, {
      'code': '002011009003',
      'text': '新邱区'
    }, {
      'code': '002011009004',
      'text': '太平区'
    }, {
      'code': '002011009005',
      'text': '清河门区'
    }, {
      'code': '002011009011',
      'text': '细河区'
    }, {
      'code': '002011009021',
      'text': '阜新蒙古族自治县'
    }, {
      'code': '002011009022',
      'text': '彰武县'
    }],
    'text': '阜新市'
  }, {
    'code': '002011010',
    'children': [{
      'code': '002011010002',
      'text': '白塔区'
    }, {
      'code': '002011010003',
      'text': '文圣区'
    }, {
      'code': '002011010004',
      'text': '宏伟区'
    }, {
      'code': '002011010005',
      'text': '弓长岭区'
    }, {
      'code': '002011010011',
      'text': '太子河区'
    }, {
      'code': '002011010021',
      'text': '辽阳县'
    }, {
      'code': '002011010081',
      'text': '灯塔市'
    }],
    'text': '辽阳市'
  }, {
    'code': '002011011',
    'children': [{
      'code': '002011011002',
      'text': '双台子区'
    }, {
      'code': '002011011003',
      'text': '兴隆台区'
    }, {
      'code': '002011011021',
      'text': '大洼县'
    }, {
      'code': '002011011022',
      'text': '盘山县'
    }],
    'text': '盘锦市'
  }, {
    'code': '002011012',
    'children': [{
      'code': '002011012002',
      'text': '银州区'
    }, {
      'code': '002011012004',
      'text': '清河区'
    }, {
      'code': '002011012021',
      'text': '铁岭县'
    }, {
      'code': '002011012023',
      'text': '西丰县'
    }, {
      'code': '002011012024',
      'text': '昌图县'
    }, {
      'code': '002011012082',
      'text': '开原市'
    }, {
      'code': '002011012099',
      'text': '调兵山市'
    }],
    'text': '铁岭市'
  }, {
    'code': '002011013',
    'children': [{
      'code': '002011013002',
      'text': '双塔区'
    }, {
      'code': '002011013003',
      'text': '龙城区'
    }, {
      'code': '002011013021',
      'text': '朝阳县'
    }, {
      'code': '002011013022',
      'text': '建平县'
    }, {
      'code': '002011013024',
      'text': '喀喇沁左翼蒙古族自治县'
    }, {
      'code': '002011013081',
      'text': '北票市'
    }, {
      'code': '002011013082',
      'text': '凌源市'
    }],
    'text': '朝阳市'
  }, {
    'code': '002011014',
    'children': [{
      'code': '002011014002',
      'text': '连山区'
    }, {
      'code': '002011014003',
      'text': '龙港区'
    }, {
      'code': '002011014004',
      'text': '南票区'
    }, {
      'code': '002011014021',
      'text': '绥中县'
    }, {
      'code': '002011014022',
      'text': '建昌县'
    }, {
      'code': '002011014081',
      'text': '兴城市'
    }],
    'text': '葫芦岛市'
  }],
  'text': '辽宁'
}, {
  'code': '002012',
  'children': [{
    'code': '002012001',
    'children': [{
      'code': '002012001002',
      'text': '南关区'
    }, {
      'code': '002012001003',
      'text': '宽城区'
    }, {
      'code': '002012001004',
      'text': '朝阳区'
    }, {
      'code': '002012001005',
      'text': '二道区'
    }, {
      'code': '002012001006',
      'text': '绿园区'
    }, {
      'code': '002012001012',
      'text': '双阳区'
    }, {
      'code': '002012001022',
      'text': '农安县'
    }, {
      'code': '002012001030',
      'text': '净月区'
    }, {
      'code': '002012001031',
      'text': '汽车产业开发区'
    }, {
      'code': '002012001080',
      'text': '九台市'
    }, {
      'code': '002012001082',
      'text': '榆树市'
    }, {
      'code': '002012001083',
      'text': '德惠市'
    }, {
      'code': '002012001084',
      'text': '经济开发区区'
    }, {
      'code': '002012001085',
      'text': '高新技术开发区区'
    }],
    'text': '长春市'
  }, {
    'code': '002012002',
    'children': [{
      'code': '002012002002',
      'text': '昌邑区'
    }, {
      'code': '002012002003',
      'text': '龙潭区'
    }, {
      'code': '002012002004',
      'text': '船营区'
    }, {
      'code': '002012002011',
      'text': '丰满区'
    }, {
      'code': '002012002021',
      'text': '永吉县'
    }, {
      'code': '002012002081',
      'text': '蛟河市'
    }, {
      'code': '002012002082',
      'text': '桦甸市'
    }, {
      'code': '002012002083',
      'text': '舒兰市'
    }, {
      'code': '002012002084',
      'text': '磐石市'
    }],
    'text': '吉林市'
  }, {
    'code': '002012003',
    'children': [{
      'code': '002012003002',
      'text': '铁西区'
    }, {
      'code': '002012003003',
      'text': '铁东区'
    }, {
      'code': '002012003022',
      'text': '梨树县'
    }, {
      'code': '002012003023',
      'text': '伊通满族自治县'
    }, {
      'code': '002012003081',
      'text': '公主岭市'
    }, {
      'code': '002012003082',
      'text': '双辽市'
    }],
    'text': '四平市'
  }, {
    'code': '002012004',
    'children': [{
      'code': '002012004002',
      'text': '龙山区'
    }, {
      'code': '002012004003',
      'text': '西安区'
    }, {
      'code': '002012004021',
      'text': '东丰县'
    }, {
      'code': '002012004022',
      'text': '东辽县'
    }],
    'text': '辽源市'
  }, {
    'code': '002012005',
    'children': [{
      'code': '002012005002',
      'text': '东昌区'
    }, {
      'code': '002012005003',
      'text': '二道江区'
    }, {
      'code': '002012005021',
      'text': '通化县'
    }, {
      'code': '002012005023',
      'text': '辉南县'
    }, {
      'code': '002012005024',
      'text': '柳河县'
    }, {
      'code': '002012005081',
      'text': '梅河口市'
    }, {
      'code': '002012005082',
      'text': '集安市'
    }],
    'text': '通化市'
  }, {
    'code': '002012006',
    'children': [{
      'code': '002012006002',
      'text': '八道江区'
    }, {
      'code': '002012006010',
      'text': '浑江区'
    }, {
      'code': '002012006021',
      'text': '抚松县'
    }, {
      'code': '002012006022',
      'text': '靖宇县'
    }, {
      'code': '002012006023',
      'text': '长白朝县族自治县'
    }, {
      'code': '002012006025',
      'text': '江源县'
    }, {
      'code': '002012006081',
      'text': '临江市'
    }],
    'text': '白山市'
  }, {
    'code': '002012007',
    'children': [{
      'code': '002012007002',
      'text': '宁江区'
    }, {
      'code': '002012007021',
      'text': '前郭尔罗斯蒙古族自治县'
    }, {
      'code': '002012007022',
      'text': '长岭县'
    }, {
      'code': '002012007023',
      'text': '乾安县'
    }, {
      'code': '002012007024',
      'text': '扶余县'
    }],
    'text': '松原市'
  }, {
    'code': '002012008',
    'children': [{
      'code': '002012008002',
      'text': '洮北区'
    }, {
      'code': '002012008021',
      'text': '镇赉县'
    }, {
      'code': '002012008022',
      'text': '通榆县'
    }, {
      'code': '002012008081',
      'text': '洮南市'
    }, {
      'code': '002012008082',
      'text': '大安市'
    }],
    'text': '白城市'
  }, {
    'code': '002012024',
    'children': [{
      'code': '002012024001',
      'text': '延吉市'
    }, {
      'code': '002012024002',
      'text': '图们市'
    }, {
      'code': '002012024003',
      'text': '敦化市'
    }, {
      'code': '002012024004',
      'text': '珲春市'
    }, {
      'code': '002012024005',
      'text': '龙井市'
    }, {
      'code': '002012024006',
      'text': '和龙市'
    }, {
      'code': '002012024024',
      'text': '汪清县'
    }, {
      'code': '002012024026',
      'text': '安图县'
    }],
    'text': '延边朝鲜族自治州'
  }],
  'text': '吉林'
}, {
  'code': '002013',
  'children': [{
    'code': '002013001',
    'children': [{
      'code': '002013001002',
      'text': '道里区'
    }, {
      'code': '002013001003',
      'text': '南岗区'
    }, {
      'code': '002013001004',
      'text': '道外区'
    }, {
      'code': '002013001005',
      'text': '太平区'
    }, {
      'code': '002013001006',
      'text': '香坊区'
    }, {
      'code': '002013001008',
      'text': '平房区'
    }, {
      'code': '002013001010',
      'text': '松北区'
    }, {
      'code': '002013001021',
      'text': '呼兰县'
    }, {
      'code': '002013001023',
      'text': '依兰县'
    }, {
      'code': '002013001024',
      'text': '方正县'
    }, {
      'code': '002013001025',
      'text': '宾县'
    }, {
      'code': '002013001026',
      'text': '巴彦县'
    }, {
      'code': '002013001027',
      'text': '木兰县'
    }, {
      'code': '002013001028',
      'text': '通河县'
    }, {
      'code': '002013001029',
      'text': '延寿县'
    }, {
      'code': '002013001081',
      'text': '阿城市'
    }, {
      'code': '002013001082',
      'text': '双城市'
    }, {
      'code': '002013001083',
      'text': '尚志市'
    }, {
      'code': '002013001084',
      'text': '五常市'
    }, {
      'code': '002013001086',
      'text': '动力区'
    }],
    'text': '哈尔滨市'
  }, {
    'code': '002013002',
    'children': [{
      'code': '002013002002',
      'text': '龙沙区'
    }, {
      'code': '002013002003',
      'text': '建华区'
    }, {
      'code': '002013002004',
      'text': '铁锋区'
    }, {
      'code': '002013002005',
      'text': '昂昂溪区'
    }, {
      'code': '002013002006',
      'text': '富拉尔基区'
    }, {
      'code': '002013002007',
      'text': '碾子山区'
    }, {
      'code': '002013002008',
      'text': '梅里斯达斡尔族'
    }, {
      'code': '002013002021',
      'text': '龙江县'
    }, {
      'code': '002013002023',
      'text': '依安县'
    }, {
      'code': '002013002024',
      'text': '泰来县'
    }, {
      'code': '002013002025',
      'text': '甘南县'
    }, {
      'code': '002013002027',
      'text': '富裕县'
    }, {
      'code': '002013002029',
      'text': '克山县'
    }, {
      'code': '002013002030',
      'text': '克东县'
    }, {
      'code': '002013002031',
      'text': '拜泉县'
    }, {
      'code': '002013002081',
      'text': '讷河市'
    }],
    'text': '齐齐哈尔市'
  }, {
    'code': '002013003',
    'children': [{
      'code': '002013003002',
      'text': '鸡冠区'
    }, {
      'code': '002013003003',
      'text': '恒山区'
    }, {
      'code': '002013003004',
      'text': '滴道区'
    }, {
      'code': '002013003005',
      'text': '梨树区'
    }, {
      'code': '002013003006',
      'text': '城子河区'
    }, {
      'code': '002013003007',
      'text': '麻山区'
    }, {
      'code': '002013003021',
      'text': '鸡东县'
    }, {
      'code': '002013003081',
      'text': '虎林市'
    }, {
      'code': '002013003082',
      'text': '密山市'
    }],
    'text': '鸡西市'
  }, {
    'code': '002013004',
    'children': [{
      'code': '002013004002',
      'text': '向阳区'
    }, {
      'code': '002013004003',
      'text': '工农区'
    }, {
      'code': '002013004004',
      'text': '南山区'
    }, {
      'code': '002013004005',
      'text': '兴安区'
    }, {
      'code': '002013004006',
      'text': '东山区'
    }, {
      'code': '002013004007',
      'text': '兴山区'
    }, {
      'code': '002013004021',
      'text': '萝北县'
    }, {
      'code': '002013004022',
      'text': '绥滨县'
    }],
    'text': '鹤岗市'
  }, {
    'code': '002013005',
    'children': [{
      'code': '002013005002',
      'text': '尖山区'
    }, {
      'code': '002013005003',
      'text': '岭东区'
    }, {
      'code': '002013005005',
      'text': '四方台区'
    }, {
      'code': '002013005006',
      'text': '宝山区'
    }, {
      'code': '002013005021',
      'text': '集贤县'
    }, {
      'code': '002013005022',
      'text': '友谊县'
    }, {
      'code': '002013005023',
      'text': '宝清县'
    }, {
      'code': '002013005024',
      'text': '饶河县'
    }],
    'text': '双鸭山市'
  }, {
    'code': '002013006',
    'children': [{
      'code': '002013006002',
      'text': '萨尔图区'
    }, {
      'code': '002013006003',
      'text': '龙凤区'
    }, {
      'code': '002013006004',
      'text': '让胡路区'
    }, {
      'code': '002013006005',
      'text': '红岗区'
    }, {
      'code': '002013006006',
      'text': '大同区'
    }, {
      'code': '002013006021',
      'text': '肇州县'
    }, {
      'code': '002013006022',
      'text': '肇源县'
    }, {
      'code': '002013006023',
      'text': '林甸县'
    }, {
      'code': '002013006024',
      'text': '杜尔伯特蒙古族自治县'
    }],
    'text': '大庆市'
  }, {
    'code': '002013007',
    'children': [{
      'code': '002013007002',
      'text': '伊春区'
    }, {
      'code': '002013007003',
      'text': '南岔区'
    }, {
      'code': '002013007004',
      'text': '友好区'
    }, {
      'code': '002013007005',
      'text': '西林区'
    }, {
      'code': '002013007006',
      'text': '翠峦区'
    }, {
      'code': '002013007007',
      'text': '新青区'
    }, {
      'code': '002013007008',
      'text': '美溪区'
    }, {
      'code': '002013007009',
      'text': '金山屯区'
    }, {
      'code': '002013007010',
      'text': '五营区'
    }, {
      'code': '002013007011',
      'text': '乌马河区'
    }, {
      'code': '002013007012',
      'text': '汤旺河区'
    }, {
      'code': '002013007013',
      'text': '带岭区'
    }, {
      'code': '002013007014',
      'text': '乌伊岭区'
    }, {
      'code': '002013007015',
      'text': '红星区'
    }, {
      'code': '002013007016',
      'text': '上甘岭区'
    }, {
      'code': '002013007022',
      'text': '嘉荫县'
    }, {
      'code': '002013007081',
      'text': '铁力市'
    }],
    'text': '伊春市'
  }, {
    'code': '002013008',
    'children': [{
      'code': '002013008002',
      'text': '永红区'
    }, {
      'code': '002013008003',
      'text': '向阳区'
    }, {
      'code': '002013008004',
      'text': '前进区'
    }, {
      'code': '002013008005',
      'text': '东风区'
    }, {
      'code': '002013008011',
      'text': '郊区'
    }, {
      'code': '002013008022',
      'text': '桦南县'
    }, {
      'code': '002013008026',
      'text': '桦川县'
    }, {
      'code': '002013008028',
      'text': '汤原县'
    }, {
      'code': '002013008033',
      'text': '抚远县'
    }, {
      'code': '002013008081',
      'text': '同江市'
    }, {
      'code': '002013008082',
      'text': '富锦市'
    }, {
      'code': '002013008083',
      'text': '建三江'
    }],
    'text': '佳木斯市'
  }, {
    'code': '002013009',
    'children': [{
      'code': '002013009002',
      'text': '新兴区'
    }, {
      'code': '002013009003',
      'text': '桃山区'
    }, {
      'code': '002013009004',
      'text': '茄子河区'
    }, {
      'code': '002013009010',
      'text': '金沙新区'
    }, {
      'code': '002013009021',
      'text': '勃利县'
    }],
    'text': '七台河市'
  }, {
    'code': '002013010',
    'children': [{
      'code': '002013010002',
      'text': '东安区'
    }, {
      'code': '002013010003',
      'text': '阳明区'
    }, {
      'code': '002013010004',
      'text': '爱民区'
    }, {
      'code': '002013010005',
      'text': '西安区'
    }, {
      'code': '002013010024',
      'text': '东宁县'
    }, {
      'code': '002013010025',
      'text': '林口县'
    }, {
      'code': '002013010081',
      'text': '绥芬河市'
    }, {
      'code': '002013010083',
      'text': '海林市'
    }, {
      'code': '002013010084',
      'text': '宁安市'
    }, {
      'code': '002013010085',
      'text': '穆棱市'
    }],
    'text': '牡丹江市'
  }, {
    'code': '002013011',
    'children': [{
      'code': '002013011002',
      'text': '爱辉区'
    }, {
      'code': '002013011021',
      'text': '嫩江县'
    }, {
      'code': '002013011023',
      'text': '逊克县'
    }, {
      'code': '002013011024',
      'text': '孙吴县'
    }, {
      'code': '002013011081',
      'text': '北安市'
    }, {
      'code': '002013011082',
      'text': '五大连池市'
    }],
    'text': '黑河市'
  }, {
    'code': '002013023',
    'children': [{
      'code': '002013023001',
      'text': '绥化市'
    }, {
      'code': '002013023002',
      'text': '安达市'
    }, {
      'code': '002013023003',
      'text': '肇东市'
    }, {
      'code': '002013023004',
      'text': '海伦市'
    }, {
      'code': '002013023010',
      'text': '北林区'
    }, {
      'code': '002013023024',
      'text': '望奎县'
    }, {
      'code': '002013023025',
      'text': '兰西县'
    }, {
      'code': '002013023026',
      'text': '青冈县'
    }, {
      'code': '002013023030',
      'text': '庆安县'
    }, {
      'code': '002013023031',
      'text': '明水县'
    }, {
      'code': '002013023032',
      'text': '绥棱县'
    }],
    'text': '绥化市'
  }, {
    'code': '002013027',
    'children': [{
      'code': '002013027021',
      'text': '呼玛县'
    }, {
      'code': '002013027022',
      'text': '塔河县'
    }, {
      'code': '002013027023',
      'text': '漠河县'
    }, {
      'code': '002013027024',
      'text': '加格达奇区'
    }, {
      'code': '002013027025',
      'text': '松岭区'
    }, {
      'code': '002013027026',
      'text': '新林区'
    }, {
      'code': '002013027027',
      'text': '呼中区'
    }],
    'text': '大兴安岭地区'
  }],
  'text': '黑龙江'
}, {
  'code': '002021',
  'children': [{
    'code': '002021001',
    'children': [{
      'code': '002021001001',
      'text': '黄浦区'
    }, {
      'code': '002021001003',
      'text': '卢湾区'
    }, {
      'code': '002021001004',
      'text': '徐汇区'
    }, {
      'code': '002021001005',
      'text': '长宁区'
    }, {
      'code': '002021001006',
      'text': '静安区'
    }, {
      'code': '002021001007',
      'text': '普陀区'
    }, {
      'code': '002021001008',
      'text': '闸北区'
    }, {
      'code': '002021001009',
      'text': '虹口区'
    }, {
      'code': '002021001010',
      'text': '杨浦区'
    }, {
      'code': '002021001012',
      'text': '闵行区'
    }, {
      'code': '002021001013',
      'text': '宝山区'
    }, {
      'code': '002021001014',
      'text': '嘉定区'
    }, {
      'code': '002021001015',
      'text': '浦东新区'
    }, {
      'code': '002021001016',
      'text': '金山区'
    }, {
      'code': '002021001017',
      'text': '松江区'
    }, {
      'code': '002021001018',
      'text': '青浦区'
    }, {
      'code': '002021001020',
      'text': '奉贤区'
    }, {
      'code': '002021001030',
      'text': '崇明县'
    }],
    'text': '上海市'
  }],
  'text': '上海'
}, {
  'code': '002022',
  'children': [{
    'code': '002022001',
    'children': [{
      'code': '002022001002',
      'text': '玄武区'
    }, {
      'code': '002022001003',
      'text': '白下区'
    }, {
      'code': '002022001004',
      'text': '秦淮区'
    }, {
      'code': '002022001005',
      'text': '建邺区'
    }, {
      'code': '002022001006',
      'text': '鼓楼区'
    }, {
      'code': '002022001007',
      'text': '下关区'
    }, {
      'code': '002022001011',
      'text': '浦口区'
    }, {
      'code': '002022001013',
      'text': '栖霞区'
    }, {
      'code': '002022001014',
      'text': '雨花台区'
    }, {
      'code': '002022001021',
      'text': '江宁区'
    }, {
      'code': '002022001023',
      'text': '六合县'
    }, {
      'code': '002022001024',
      'text': '溧水县'
    }, {
      'code': '002022001025',
      'text': '高淳县'
    }],
    'text': '南京市'
  }, {
    'code': '002022002',
    'children': [{
      'code': '002022002002',
      'text': '崇安区'
    }, {
      'code': '002022002003',
      'text': '南长区'
    }, {
      'code': '002022002004',
      'text': '北塘区'
    }, {
      'code': '002022002010',
      'text': '新吴区'
    }, {
      'code': '002022002011',
      'text': '梁溪区'
    }, {
      'code': '002022002012',
      'text': '新区'
    }, {
      'code': '002022002081',
      'text': '江阴市'
    }, {
      'code': '002022002082',
      'text': '宜兴市'
    }, {
      'code': '002022002083',
      'text': '锡山市'
    }, {
      'code': '002022002084',
      'text': '滨湖区'
    }, {
      'code': '002022002085',
      'text': '惠山区'
    }],
    'text': '无锡市'
  }, {
    'code': '002022003',
    'children': [{
      'code': '002022003002',
      'text': '市区'
    }, {
      'code': '002022003003',
      'text': '云龙区'
    }, {
      'code': '002022003004',
      'text': '九里区'
    }, {
      'code': '002022003005',
      'text': '贾汪区'
    }, {
      'code': '002022003011',
      'text': '泉山区'
    }, {
      'code': '002022003015',
      'text': '八段工业园区'
    }, {
      'code': '002022003016',
      'text': '金山桥开发区'
    }, {
      'code': '002022003017',
      'text': '鼓楼区'
    }, {
      'code': '002022003021',
      'text': '丰县'
    }, {
      'code': '002022003022',
      'text': '沛县'
    }, {
      'code': '002022003023',
      'text': '铜山县'
    }, {
      'code': '002022003024',
      'text': '睢宁县'
    }, {
      'code': '002022003081',
      'text': '新沂市'
    }, {
      'code': '002022003082',
      'text': '邳州市'
    }, {
      'code': '002022003083',
      'text': '铜山经济技术开发区'
    }],
    'text': '徐州市'
  }, {
    'code': '002022004',
    'children': [{
      'code': '002022004002',
      'text': '天宁区'
    }, {
      'code': '002022004004',
      'text': '钟楼区'
    }, {
      'code': '002022004005',
      'text': '戚墅堰区'
    }, {
      'code': '002022004081',
      'text': '溧阳市'
    }, {
      'code': '002022004082',
      'text': '金坛市'
    }, {
      'code': '002022004083',
      'text': '武进市'
    }, {
      'code': '002022004084',
      'text': '新北区'
    }],
    'text': '常州市'
  }, {
    'code': '002022005',
    'children': [{
      'code': '002022005002',
      'text': '沧浪区'
    }, {
      'code': '002022005003',
      'text': '平江区'
    }, {
      'code': '002022005004',
      'text': '金阊区'
    }, {
      'code': '002022005011',
      'text': '新区'
    }, {
      'code': '002022005013',
      'text': '姑苏区'
    }, {
      'code': '002022005014',
      'text': '虎丘区'
    }, {
      'code': '002022005015',
      'text': '高新区'
    }, {
      'code': '002022005081',
      'text': '常熟市'
    }, {
      'code': '002022005082',
      'text': '张家港市'
    }, {
      'code': '002022005083',
      'text': '昆山市'
    }, {
      'code': '002022005084',
      'text': '吴江区'
    }, {
      'code': '002022005085',
      'text': '太仓市'
    }, {
      'code': '002022005086',
      'text': '吴中区'
    }, {
      'code': '002022005087',
      'text': '相城区'
    }, {
      'code': '002022005088',
      'text': '工业园区'
    }],
    'text': '苏州市'
  }, {
    'code': '002022006',
    'children': [{
      'code': '002022006002',
      'text': '崇川区'
    }, {
      'code': '002022006011',
      'text': '港闸区'
    }, {
      'code': '002022006012',
      'text': '南通经济技术开发区'
    }, {
      'code': '002022006021',
      'text': '海安县'
    }, {
      'code': '002022006023',
      'text': '如东县'
    }, {
      'code': '002022006081',
      'text': '启东市'
    }, {
      'code': '002022006082',
      'text': '如皋市'
    }, {
      'code': '002022006083',
      'text': '通州市'
    }, {
      'code': '002022006084',
      'text': '海门市'
    }],
    'text': '南通市'
  }, {
    'code': '002022007',
    'children': [{
      'code': '002022007003',
      'text': '连云区'
    }, {
      'code': '002022007005',
      'text': '新浦区'
    }, {
      'code': '002022007010',
      'text': '海州区'
    }, {
      'code': '002022007021',
      'text': '赣榆县'
    }, {
      'code': '002022007022',
      'text': '东海县'
    }, {
      'code': '002022007023',
      'text': '灌云县'
    }, {
      'code': '002022007024',
      'text': '灌南县'
    }, {
      'code': '002022007025',
      'text': '开发区'
    }],
    'text': '连云港市'
  }, {
    'code': '002022008',
    'children': [{
      'code': '002022008002',
      'text': '清河区'
    }, {
      'code': '002022008011',
      'text': '清浦区'
    }, {
      'code': '002022008021',
      'text': '淮阴县'
    }, {
      'code': '002022008026',
      'text': '涟水县'
    }, {
      'code': '002022008029',
      'text': '洪泽县'
    }, {
      'code': '002022008030',
      'text': '盱眙县'
    }, {
      'code': '002022008031',
      'text': '金湖县'
    }, {
      'code': '002022008082',
      'text': '开发区'
    }, {
      'code': '002022008083',
      'text': '楚州区'
    }, {
      'code': '002022008084',
      'text': '淮安区'
    }, {
      'code': '002022008085',
      'text': '清江浦区'
    }],
    'text': '淮安市'
  }, {
    'code': '002022009',
    'children': [{
      'code': '002022009021',
      'text': '响水县'
    }, {
      'code': '002022009022',
      'text': '滨海县'
    }, {
      'code': '002022009023',
      'text': '阜宁县'
    }, {
      'code': '002022009024',
      'text': '射阳县'
    }, {
      'code': '002022009025',
      'text': '建湖县'
    }, {
      'code': '002022009028',
      'text': '盐都县'
    }, {
      'code': '002022009081',
      'text': '东台市'
    }, {
      'code': '002022009082',
      'text': '大丰市'
    }, {
      'code': '002022009099',
      'text': '亭湖区'
    }],
    'text': '盐城市'
  }, {
    'code': '002022010',
    'children': [{
      'code': '002022010002',
      'text': '广陵区'
    }, {
      'code': '002022010023',
      'text': '宝应县'
    }, {
      'code': '002022010027',
      'text': '邗江县'
    }, {
      'code': '002022010081',
      'text': '仪征市'
    }, {
      'code': '002022010084',
      'text': '高邮市'
    }, {
      'code': '002022010088',
      'text': '江都市'
    }, {
      'code': '002022010089',
      'text': '经济开发区'
    }, {
      'code': '002022010090',
      'text': '维扬区'
    }],
    'text': '扬州市'
  }, {
    'code': '002022011',
    'children': [{
      'code': '002022011002',
      'text': '京口区'
    }, {
      'code': '002022011011',
      'text': '润州区'
    }, {
      'code': '002022011012',
      'text': '丹徒新区'
    }, {
      'code': '002022011013',
      'text': '镇江新区'
    }, {
      'code': '002022011021',
      'text': '丹徒区'
    }, {
      'code': '002022011081',
      'text': '丹阳市'
    }, {
      'code': '002022011082',
      'text': '扬中市'
    }, {
      'code': '002022011083',
      'text': '句容市'
    }, {
      'code': '002022011085',
      'text': '新区'
    }],
    'text': '镇江市'
  }, {
    'code': '002022012',
    'children': [{
      'code': '002022012002',
      'text': '海陵区'
    }, {
      'code': '002022012003',
      'text': '高港区'
    }, {
      'code': '002022012081',
      'text': '兴化市'
    }, {
      'code': '002022012082',
      'text': '靖江市'
    }, {
      'code': '002022012083',
      'text': '泰兴市'
    }, {
      'code': '002022012084',
      'text': '姜堰市'
    }, {
      'code': '002022012085',
      'text': '泰州医药高新区'
    }],
    'text': '泰州市'
  }, {
    'code': '002022013',
    'children': [{
      'code': '002022013002',
      'text': '宿城区'
    }, {
      'code': '002022013010',
      'text': '宿迁经济开发区'
    }, {
      'code': '002022013021',
      'text': '宿豫区'
    }, {
      'code': '002022013022',
      'text': '沭阳县'
    }, {
      'code': '002022013023',
      'text': '泗阳县'
    }, {
      'code': '002022013024',
      'text': '泗洪县'
    }],
    'text': '宿迁市'
  }],
  'text': '江苏'
}, {
  'code': '002023',
  'children': [{
    'code': '002023001',
    'children': [{
      'code': '002023001002',
      'text': '上城区'
    }, {
      'code': '002023001003',
      'text': '下城区'
    }, {
      'code': '002023001004',
      'text': '江干区'
    }, {
      'code': '002023001005',
      'text': '拱墅区'
    }, {
      'code': '002023001006',
      'text': '西湖区'
    }, {
      'code': '002023001008',
      'text': '滨江区'
    }, {
      'code': '002023001022',
      'text': '桐庐县'
    }, {
      'code': '002023001027',
      'text': '淳安县'
    }, {
      'code': '002023001081',
      'text': '萧山市'
    }, {
      'code': '002023001082',
      'text': '建德市'
    }, {
      'code': '002023001083',
      'text': '富阳市'
    }, {
      'code': '002023001084',
      'text': '余杭市'
    }, {
      'code': '002023001085',
      'text': '临安市'
    }, {
      'code': '002023001086',
      'text': '下沙区'
    }],
    'text': '杭州市'
  }, {
    'code': '002023002',
    'children': [{
      'code': '002023002003',
      'text': '海曙区'
    }, {
      'code': '002023002004',
      'text': '江东区'
    }, {
      'code': '002023002005',
      'text': '江北区'
    }, {
      'code': '002023002006',
      'text': '北仑区'
    }, {
      'code': '002023002010',
      'text': '高新科技开发区'
    }, {
      'code': '002023002011',
      'text': '镇海区'
    }, {
      'code': '002023002025',
      'text': '象山县'
    }, {
      'code': '002023002026',
      'text': '宁海县'
    }, {
      'code': '002023002027',
      'text': '鄞州区'
    }, {
      'code': '002023002081',
      'text': '余姚市'
    }, {
      'code': '002023002082',
      'text': '慈溪市'
    }, {
      'code': '002023002083',
      'text': '奉化市'
    }],
    'text': '宁波市'
  }, {
    'code': '002023003',
    'children': [{
      'code': '002023003002',
      'text': '鹿城区'
    }, {
      'code': '002023003003',
      'text': '龙湾区'
    }, {
      'code': '002023003004',
      'text': '瓯海区'
    }, {
      'code': '002023003010',
      'text': '茶山高教园区'
    }, {
      'code': '002023003022',
      'text': '洞头县'
    }, {
      'code': '002023003024',
      'text': '永嘉县'
    }, {
      'code': '002023003026',
      'text': '平阳县'
    }, {
      'code': '002023003027',
      'text': '苍南县'
    }, {
      'code': '002023003028',
      'text': '文成县'
    }, {
      'code': '002023003029',
      'text': '泰顺县'
    }, {
      'code': '002023003081',
      'text': '瑞安市'
    }, {
      'code': '002023003082',
      'text': '乐清市'
    }, {
      'code': '002023003083',
      'text': '龙港市'
    }],
    'text': '温州市'
  }, {
    'code': '002023004',
    'children': [{
      'code': '002023004002',
      'text': '南湖区'
    }, {
      'code': '002023004011',
      'text': '秀洲区'
    }, {
      'code': '002023004021',
      'text': '嘉善县'
    }, {
      'code': '002023004024',
      'text': '海盐县'
    }, {
      'code': '002023004081',
      'text': '海宁市'
    }, {
      'code': '002023004082',
      'text': '平湖市'
    }, {
      'code': '002023004083',
      'text': '桐乡市'
    }],
    'text': '嘉兴市'
  }, {
    'code': '002023005',
    'children': [{
      'code': '002023005021',
      'text': '德清县'
    }, {
      'code': '002023005022',
      'text': '长兴县'
    }, {
      'code': '002023005023',
      'text': '安吉县'
    }, {
      'code': '002023005024',
      'text': '南浔区'
    }, {
      'code': '002023005025',
      'text': '吴兴区'
    }],
    'text': '湖州市'
  }, {
    'code': '002023006',
    'children': [{
      'code': '002023006002',
      'text': '越城区'
    }, {
      'code': '002023006010',
      'text': '柯桥区'
    }, {
      'code': '002023006021',
      'text': '绍兴县'
    }, {
      'code': '002023006024',
      'text': '新昌县'
    }, {
      'code': '002023006081',
      'text': '诸暨市'
    }, {
      'code': '002023006082',
      'text': '上虞市'
    }, {
      'code': '002023006083',
      'text': '嵊州市'
    }],
    'text': '绍兴市'
  }, {
    'code': '002023007',
    'children': [{
      'code': '002023007002',
      'text': '婺城区'
    }, {
      'code': '002023007021',
      'text': '金东区'
    }, {
      'code': '002023007023',
      'text': '武义县'
    }, {
      'code': '002023007026',
      'text': '浦江县'
    }, {
      'code': '002023007027',
      'text': '磐安县'
    }, {
      'code': '002023007081',
      'text': '兰溪市'
    }, {
      'code': '002023007082',
      'text': '义乌市'
    }, {
      'code': '002023007083',
      'text': '东阳市'
    }, {
      'code': '002023007084',
      'text': '永康市'
    }],
    'text': '金华市'
  }, {
    'code': '002023008',
    'children': [{
      'code': '002023008002',
      'text': '柯城区'
    }, {
      'code': '002023008021',
      'text': '衢江区'
    }, {
      'code': '002023008022',
      'text': '常山县'
    }, {
      'code': '002023008024',
      'text': '开化县'
    }, {
      'code': '002023008025',
      'text': '龙游县'
    }, {
      'code': '002023008081',
      'text': '江山市'
    }],
    'text': '衢州市'
  }, {
    'code': '002023009',
    'children': [{
      'code': '002023009002',
      'text': '定海区'
    }, {
      'code': '002023009003',
      'text': '普陀区'
    }, {
      'code': '002023009021',
      'text': '岱山县'
    }, {
      'code': '002023009022',
      'text': '嵊泗县'
    }],
    'text': '舟山市'
  }, {
    'code': '002023010',
    'children': [{
      'code': '002023010002',
      'text': '椒江区'
    }, {
      'code': '002023010003',
      'text': '黄岩区'
    }, {
      'code': '002023010004',
      'text': '路桥区'
    }, {
      'code': '002023010021',
      'text': '玉环县'
    }, {
      'code': '002023010022',
      'text': '三门县'
    }, {
      'code': '002023010023',
      'text': '天台县'
    }, {
      'code': '002023010024',
      'text': '仙居县'
    }, {
      'code': '002023010081',
      'text': '温岭市'
    }, {
      'code': '002023010082',
      'text': '临海市'
    }],
    'text': '台州市'
  }, {
    'code': '002023025',
    'children': [{
      'code': '002023025001',
      'text': '莲都区'
    }, {
      'code': '002023025002',
      'text': '龙泉市'
    }, {
      'code': '002023025022',
      'text': '青田县'
    }, {
      'code': '002023025023',
      'text': '云和县'
    }, {
      'code': '002023025025',
      'text': '庆元县'
    }, {
      'code': '002023025026',
      'text': '缙云县'
    }, {
      'code': '002023025027',
      'text': '遂昌县'
    }, {
      'code': '002023025028',
      'text': '松阳县'
    }, {
      'code': '002023025029',
      'text': '景宁畲族自治县'
    }],
    'text': '丽水市'
  }],
  'text': '浙江'
}, {
  'code': '002024',
  'children': [{
    'code': '002024001',
    'children': [{
      'code': '002024001002',
      'text': '东市区'
    }, {
      'code': '002024001003',
      'text': '中市区'
    }, {
      'code': '002024001004',
      'text': '西市区'
    }, {
      'code': '002024001011',
      'text': '郊区'
    }, {
      'code': '002024001021',
      'text': '长丰县'
    }, {
      'code': '002024001022',
      'text': '肥东县'
    }, {
      'code': '002024001023',
      'text': '肥西县'
    }, {
      'code': '002024001031',
      'text': '经济技术开发区'
    }, {
      'code': '002024001032',
      'text': '新站综合开发试验区'
    }, {
      'code': '002024001033',
      'text': '政务文化新区'
    }, {
      'code': '002024001034',
      'text': '巢湖市'
    }, {
      'code': '002024001035',
      'text': '高新技术开发区'
    }, {
      'code': '002024001036',
      'text': '北城新区'
    }, {
      'code': '002024001037',
      'text': '滨湖新区'
    }, {
      'code': '002024001038',
      'text': '庐江县'
    }, {
      'code': '002024001096',
      'text': '包河区'
    }, {
      'code': '002024001097',
      'text': '蜀山区'
    }, {
      'code': '002024001098',
      'text': '瑶海区'
    }, {
      'code': '002024001099',
      'text': '庐阳区'
    }],
    'text': '合肥市'
  }, {
    'code': '002024002',
    'children': [{
      'code': '002024002002',
      'text': '镜湖区'
    }, {
      'code': '002024002003',
      'text': '三山区'
    }, {
      'code': '002024002004',
      'text': '弋江区'
    }, {
      'code': '002024002007',
      'text': '鸠江区'
    }, {
      'code': '002024002010',
      'text': '无为县'
    }, {
      'code': '002024002021',
      'text': '芜湖县'
    }, {
      'code': '002024002022',
      'text': '繁昌县'
    }, {
      'code': '002024002023',
      'text': '南陵县'
    }],
    'text': '芜湖市'
  }, {
    'code': '002024003',
    'children': [{
      'code': '002024003002',
      'text': '东市区'
    }, {
      'code': '002024003003',
      'text': '中市区'
    }, {
      'code': '002024003004',
      'text': '西市区'
    }, {
      'code': '002024003011',
      'text': '郊区'
    }, {
      'code': '002024003021',
      'text': '怀远县'
    }, {
      'code': '002024003022',
      'text': '五河县'
    }, {
      'code': '002024003023',
      'text': '固镇县'
    }, {
      'code': '002024003096',
      'text': '龙子湖区'
    }, {
      'code': '002024003097',
      'text': '蚌山区'
    }, {
      'code': '002024003098',
      'text': '禹会区'
    }, {
      'code': '002024003099',
      'text': '淮上区'
    }],
    'text': '蚌埠市'
  }, {
    'code': '002024004',
    'children': [{
      'code': '002024004002',
      'text': '大通区'
    }, {
      'code': '002024004003',
      'text': '田家庵区'
    }, {
      'code': '002024004004',
      'text': '谢家集区'
    }, {
      'code': '002024004005',
      'text': '八公山区'
    }, {
      'code': '002024004006',
      'text': '潘集区'
    }, {
      'code': '002024004010',
      'text': '寿县'
    }, {
      'code': '002024004011',
      'text': '淮南高新技术开发区'
    }, {
      'code': '002024004021',
      'text': '凤台区'
    }, {
      'code': '002024004099',
      'text': '毛集'
    }],
    'text': '淮南市'
  }, {
    'code': '002024005',
    'children': [{
      'code': '002024005002',
      'text': '金家庄区'
    }, {
      'code': '002024005003',
      'text': '花山区'
    }, {
      'code': '002024005004',
      'text': '雨山区'
    }, {
      'code': '002024005005',
      'text': '向山区'
    }, {
      'code': '002024005010',
      'text': '博望区'
    }, {
      'code': '002024005011',
      'text': '含山县'
    }, {
      'code': '002024005012',
      'text': '和县'
    }, {
      'code': '002024005021',
      'text': '当涂县'
    }],
    'text': '马鞍山市'
  }, {
    'code': '002024006',
    'children': [{
      'code': '002024006002',
      'text': '杜集区'
    }, {
      'code': '002024006003',
      'text': '相山区'
    }, {
      'code': '002024006004',
      'text': '烈山区'
    }, {
      'code': '002024006021',
      'text': '濉溪县'
    }],
    'text': '淮北市'
  }, {
    'code': '002024007',
    'children': [{
      'code': '002024007002',
      'text': '铜官山区'
    }, {
      'code': '002024007003',
      'text': '狮子山区'
    }, {
      'code': '002024007010',
      'text': '枞阳县'
    }, {
      'code': '002024007011',
      'text': '郊区'
    }, {
      'code': '002024007012',
      'text': '义安区'
    }, {
      'code': '002024007021',
      'text': '铜陵县'
    }],
    'text': '铜陵市'
  }, {
    'code': '002024008',
    'children': [{
      'code': '002024008002',
      'text': '迎江区'
    }, {
      'code': '002024008003',
      'text': '大观区'
    }, {
      'code': '002024008011',
      'text': '郊区'
    }, {
      'code': '002024008022',
      'text': '怀宁县'
    }, {
      'code': '002024008023',
      'text': '枞阳县'
    }, {
      'code': '002024008024',
      'text': '潜山县'
    }, {
      'code': '002024008025',
      'text': '太湖县'
    }, {
      'code': '002024008026',
      'text': '宿松县'
    }, {
      'code': '002024008027',
      'text': '望江县'
    }, {
      'code': '002024008028',
      'text': '岳西县'
    }, {
      'code': '002024008081',
      'text': '桐城市'
    }, {
      'code': '002024008099',
      'text': '宜秀区'
    }],
    'text': '安庆市'
  }, {
    'code': '002024010',
    'children': [{
      'code': '002024010002',
      'text': '屯溪区'
    }, {
      'code': '002024010003',
      'text': '黄山区'
    }, {
      'code': '002024010004',
      'text': '徽州区'
    }, {
      'code': '002024010021',
      'text': '歙县'
    }, {
      'code': '002024010022',
      'text': '休宁县'
    }, {
      'code': '002024010023',
      'text': '黟县'
    }, {
      'code': '002024010024',
      'text': '祁门县'
    }],
    'text': '黄山市'
  }, {
    'code': '002024011',
    'children': [{
      'code': '002024011002',
      'text': '琅琊区'
    }, {
      'code': '002024011003',
      'text': '南谯区'
    }, {
      'code': '002024011022',
      'text': '来安县'
    }, {
      'code': '002024011024',
      'text': '全椒县'
    }, {
      'code': '002024011025',
      'text': '定远县'
    }, {
      'code': '002024011026',
      'text': '凤阳县'
    }, {
      'code': '002024011081',
      'text': '天长市'
    }, {
      'code': '002024011082',
      'text': '明光市'
    }],
    'text': '滁州市'
  }, {
    'code': '002024012',
    'children': [{
      'code': '002024012002',
      'text': '颍州区'
    }, {
      'code': '002024012003',
      'text': '颍东区'
    }, {
      'code': '002024012004',
      'text': '颍泉区'
    }, {
      'code': '002024012010',
      'text': '经济开发区'
    }, {
      'code': '002024012021',
      'text': '临泉县'
    }, {
      'code': '002024012022',
      'text': '太和县'
    }, {
      'code': '002024012025',
      'text': '阜南县'
    }, {
      'code': '002024012026',
      'text': '颍上县'
    }, {
      'code': '002024012082',
      'text': '界首市'
    }, {
      'code': '002024012096',
      'text': '蒙城县'
    }, {
      'code': '002024012097',
      'text': '涡阳县'
    }, {
      'code': '002024012098',
      'text': '利辛县'
    }],
    'text': '阜阳市'
  }, {
    'code': '002024013',
    'children': [{
      'code': '002024013002',
      'text': '埇桥区'
    }, {
      'code': '002024013010',
      'text': '经济开发区'
    }, {
      'code': '002024013021',
      'text': '砀山县'
    }, {
      'code': '002024013022',
      'text': '萧县'
    }, {
      'code': '002024013023',
      'text': '灵璧县'
    }, {
      'code': '002024013024',
      'text': '泗县'
    }],
    'text': '宿州市'
  }, {
    'code': '002024024',
    'children': [{
      'code': '002024024001',
      'text': '六安市'
    }, {
      'code': '002024024022',
      'text': '寿县'
    }, {
      'code': '002024024023',
      'text': '霍邱县'
    }, {
      'code': '002024024025',
      'text': '舒城县'
    }, {
      'code': '002024024026',
      'text': '金寨县'
    }, {
      'code': '002024024027',
      'text': '霍山县'
    }, {
      'code': '002024024098',
      'text': '裕安县'
    }, {
      'code': '002024024099',
      'text': '金安区'
    }, {
      'code': '002024024100',
      'text': '叶集区'
    }],
    'text': '六安市'
  }, {
    'code': '002024025',
    'children': [{
      'code': '002024025001',
      'text': '宣州市'
    }, {
      'code': '002024025002',
      'text': '宁国市'
    }, {
      'code': '002024025022',
      'text': '郎溪县'
    }, {
      'code': '002024025023',
      'text': '广德县'
    }, {
      'code': '002024025029',
      'text': '泾县'
    }, {
      'code': '002024025030',
      'text': '旌德县'
    }, {
      'code': '002024025031',
      'text': '绩溪县'
    }, {
      'code': '002024025098',
      'text': '宁国县'
    }, {
      'code': '002024025099',
      'text': '宣州区'
    }],
    'text': '宣城市'
  }, {
    'code': '002024026',
    'children': [{
      'code': '002024026001',
      'text': '巢湖市'
    }, {
      'code': '002024026022',
      'text': '庐江县'
    }, {
      'code': '002024026023',
      'text': '无为县'
    }, {
      'code': '002024026025',
      'text': '含山县'
    }, {
      'code': '002024026026',
      'text': '和县'
    }, {
      'code': '002024026099',
      'text': '居巢区'
    }],
    'text': '巢湖市'
  }, {
    'code': '002024029',
    'children': [{
      'code': '002024029001',
      'text': '贵池市'
    }, {
      'code': '002024029021',
      'text': '东至县'
    }, {
      'code': '002024029022',
      'text': '石台县'
    }, {
      'code': '002024029023',
      'text': '青阳县'
    }, {
      'code': '002024029098',
      'text': '贵池区'
    }, {
      'code': '002024029099',
      'text': '九华山'
    }],
    'text': '池州市'
  }, {
    'code': '002024030',
    'children': [{
      'code': '002024030001',
      'text': '谯城区'
    }, {
      'code': '002024030002',
      'text': '涡阳县'
    }, {
      'code': '002024030003',
      'text': '蒙城县'
    }, {
      'code': '002024030004',
      'text': '利辛县'
    }],
    'text': '亳州市'
  }],
  'text': '安徽'
}, {
  'code': '002025',
  'children': [{
    'code': '002025001',
    'children': [{
      'code': '002025001002',
      'text': '鼓楼区'
    }, {
      'code': '002025001003',
      'text': '台江区'
    }, {
      'code': '002025001004',
      'text': '仓山区'
    }, {
      'code': '002025001005',
      'text': '马尾区'
    }, {
      'code': '002025001011',
      'text': '晋安区'
    }, {
      'code': '002025001021',
      'text': '闽侯县'
    }, {
      'code': '002025001022',
      'text': '连江县'
    }, {
      'code': '002025001023',
      'text': '罗源县'
    }, {
      'code': '002025001024',
      'text': '闽清县'
    }, {
      'code': '002025001025',
      'text': '永泰县'
    }, {
      'code': '002025001028',
      'text': '平潭县'
    }, {
      'code': '002025001081',
      'text': '福清市'
    }, {
      'code': '002025001082',
      'text': '长乐市'
    }, {
      'code': '002025001099',
      'text': '琅岐'
    }],
    'text': '福州市'
  }, {
    'code': '002025002',
    'children': [{
      'code': '002025002003',
      'text': '思明区'
    }, {
      'code': '002025002006',
      'text': '湖里区'
    }, {
      'code': '002025002011',
      'text': '集美区'
    }, {
      'code': '002025002012',
      'text': '同安区'
    }, {
      'code': '002025002013',
      'text': '海沧区'
    }, {
      'code': '002025002014',
      'text': '翔安区'
    }, {
      'code': '002025002015',
      'text': '鼓浪屿区'
    }],
    'text': '厦门市'
  }, {
    'code': '002025003',
    'children': [{
      'code': '002025003002',
      'text': '城厢区'
    }, {
      'code': '002025003003',
      'text': '涵江区'
    }, {
      'code': '002025003021',
      'text': '莆田县'
    }, {
      'code': '002025003022',
      'text': '仙游县'
    }, {
      'code': '002025003097',
      'text': '秀屿区'
    }, {
      'code': '002025003098',
      'text': '荔城区'
    }, {
      'code': '002025003099',
      'text': '湄洲岛'
    }],
    'text': '莆田市'
  }, {
    'code': '002025004',
    'children': [{
      'code': '002025004002',
      'text': '梅列区'
    }, {
      'code': '002025004003',
      'text': '三元区'
    }, {
      'code': '002025004021',
      'text': '明溪县'
    }, {
      'code': '002025004023',
      'text': '清流县'
    }, {
      'code': '002025004024',
      'text': '宁化县'
    }, {
      'code': '002025004025',
      'text': '大田县'
    }, {
      'code': '002025004026',
      'text': '尤溪县'
    }, {
      'code': '002025004027',
      'text': '沙县'
    }, {
      'code': '002025004028',
      'text': '将乐县'
    }, {
      'code': '002025004029',
      'text': '泰宁县'
    }, {
      'code': '002025004030',
      'text': '建宁县'
    }, {
      'code': '002025004081',
      'text': '永安市'
    }],
    'text': '三明市'
  }, {
    'code': '002025005',
    'children': [{
      'code': '002025005002',
      'text': '鲤城区'
    }, {
      'code': '002025005003',
      'text': '丰泽区'
    }, {
      'code': '002025005004',
      'text': '洛江区'
    }, {
      'code': '002025005021',
      'text': '惠安县'
    }, {
      'code': '002025005024',
      'text': '安溪县'
    }, {
      'code': '002025005025',
      'text': '永春县'
    }, {
      'code': '002025005026',
      'text': '德化县'
    }, {
      'code': '002025005027',
      'text': '金门县'
    }, {
      'code': '002025005081',
      'text': '石狮市'
    }, {
      'code': '002025005082',
      'text': '晋江市'
    }, {
      'code': '002025005083',
      'text': '南安市'
    }, {
      'code': '002025005099',
      'text': '泉港区'
    }, {
      'code': '002025005100',
      'text': '经济技术开发区'
    }],
    'text': '泉州市'
  }, {
    'code': '002025006',
    'children': [{
      'code': '002025006002',
      'text': '芗城区'
    }, {
      'code': '002025006003',
      'text': '龙文区'
    }, {
      'code': '002025006022',
      'text': '云霄县'
    }, {
      'code': '002025006023',
      'text': '漳浦县'
    }, {
      'code': '002025006024',
      'text': '诏安县'
    }, {
      'code': '002025006025',
      'text': '长泰县'
    }, {
      'code': '002025006026',
      'text': '东山县'
    }, {
      'code': '002025006027',
      'text': '南靖县'
    }, {
      'code': '002025006028',
      'text': '平和县'
    }, {
      'code': '002025006029',
      'text': '华安县'
    }, {
      'code': '002025006081',
      'text': '龙海市'
    }, {
      'code': '002025006099',
      'text': '常山区'
    }],
    'text': '漳州市'
  }, {
    'code': '002025007',
    'children': [{
      'code': '002025007002',
      'text': '延平区'
    }, {
      'code': '002025007021',
      'text': '顺昌县'
    }, {
      'code': '002025007022',
      'text': '浦城县'
    }, {
      'code': '002025007023',
      'text': '光泽县'
    }, {
      'code': '002025007024',
      'text': '松溪县'
    }, {
      'code': '002025007025',
      'text': '政和县'
    }, {
      'code': '002025007081',
      'text': '邵武市'
    }, {
      'code': '002025007082',
      'text': '武夷山市'
    }, {
      'code': '002025007083',
      'text': '建瓯市'
    }, {
      'code': '002025007084',
      'text': '建阳市'
    }],
    'text': '南平市'
  }, {
    'code': '002025008',
    'children': [{
      'code': '002025008002',
      'text': '新罗区'
    }, {
      'code': '002025008021',
      'text': '长汀县'
    }, {
      'code': '002025008022',
      'text': '永定县'
    }, {
      'code': '002025008023',
      'text': '上杭县'
    }, {
      'code': '002025008024',
      'text': '武平县'
    }, {
      'code': '002025008025',
      'text': '连城县'
    }, {
      'code': '002025008081',
      'text': '漳平市'
    }],
    'text': '龙岩市'
  }, {
    'code': '002025022',
    'children': [{
      'code': '002025022002',
      'text': '福安市'
    }, {
      'code': '002025022003',
      'text': '福鼎市'
    }, {
      'code': '002025022010',
      'text': '东侨开发区'
    }, {
      'code': '002025022025',
      'text': '霞浦县'
    }, {
      'code': '002025022027',
      'text': '古田县'
    }, {
      'code': '002025022028',
      'text': '屏南县'
    }, {
      'code': '002025022029',
      'text': '寿宁县'
    }, {
      'code': '002025022030',
      'text': '周宁县'
    }, {
      'code': '002025022031',
      'text': '柘荣县'
    }, {
      'code': '002025022099',
      'text': '蕉城区'
    }],
    'text': '宁德市'
  }],
  'text': '福建'
}, {
  'code': '002026',
  'children': [{
    'code': '002026001',
    'children': [{
      'code': '002026001002',
      'text': '东湖区'
    }, {
      'code': '002026001003',
      'text': '西湖区'
    }, {
      'code': '002026001004',
      'text': '青云谱区'
    }, {
      'code': '002026001005',
      'text': '湾里区'
    }, {
      'code': '002026001010',
      'text': '昌北区'
    }, {
      'code': '002026001011',
      'text': '红谷滩新区'
    }, {
      'code': '002026001012',
      'text': '高新区'
    }, {
      'code': '002026001021',
      'text': '南昌县'
    }, {
      'code': '002026001022',
      'text': '新建县'
    }, {
      'code': '002026001023',
      'text': '安义县'
    }, {
      'code': '002026001024',
      'text': '进贤县'
    }, {
      'code': '002026001098',
      'text': '青山湖区'
    }, {
      'code': '002026001099',
      'text': '英雄经济开发区'
    }, {
      'code': '002026001100',
      'text': '桑海经济技术开发区'
    }, {
      'code': '002026001101',
      'text': '经济技术开发区'
    }],
    'text': '南昌市'
  }, {
    'code': '002026002',
    'children': [{
      'code': '002026002002',
      'text': '昌江区'
    }, {
      'code': '002026002003',
      'text': '珠山区'
    }, {
      'code': '002026002022',
      'text': '浮梁县'
    }, {
      'code': '002026002081',
      'text': '乐平市'
    }],
    'text': '景德镇市'
  }, {
    'code': '002026003',
    'children': [{
      'code': '002026003002',
      'text': '安源区'
    }, {
      'code': '002026003013',
      'text': '湘东区'
    }, {
      'code': '002026003021',
      'text': '莲花县'
    }, {
      'code': '002026003022',
      'text': '上栗县'
    }, {
      'code': '002026003023',
      'text': '芦溪县'
    }],
    'text': '萍乡市'
  }, {
    'code': '002026004',
    'children': [{
      'code': '002026004002',
      'text': '庐山区'
    }, {
      'code': '002026004003',
      'text': '浔阳区'
    }, {
      'code': '002026004010',
      'text': '经济技术开发区'
    }, {
      'code': '002026004011',
      'text': '共青城市'
    }, {
      'code': '002026004012',
      'text': '濂溪区'
    }, {
      'code': '002026004013',
      'text': '庐山风景名胜区'
    }, {
      'code': '002026004014',
      'text': '八里湖新区'
    }, {
      'code': '002026004021',
      'text': '九江县'
    }, {
      'code': '002026004023',
      'text': '武宁县'
    }, {
      'code': '002026004024',
      'text': '修水县'
    }, {
      'code': '002026004025',
      'text': '永修县'
    }, {
      'code': '002026004026',
      'text': '德安县'
    }, {
      'code': '002026004027',
      'text': '星子县'
    }, {
      'code': '002026004028',
      'text': '都昌县'
    }, {
      'code': '002026004029',
      'text': '湖口县'
    }, {
      'code': '002026004030',
      'text': '彭泽县'
    }, {
      'code': '002026004081',
      'text': '瑞昌市'
    }, {
      'code': '002026004082',
      'text': '柴桑区'
    }],
    'text': '九江市'
  }, {
    'code': '002026005',
    'children': [{
      'code': '002026005002',
      'text': '渝水区'
    }, {
      'code': '002026005021',
      'text': '分宜县'
    }],
    'text': '新余市'
  }, {
    'code': '002026006',
    'children': [{
      'code': '002026006002',
      'text': '月湖区'
    }, {
      'code': '002026006010',
      'text': '龙虎山风景旅游区'
    }, {
      'code': '002026006022',
      'text': '余江县'
    }, {
      'code': '002026006081',
      'text': '贵溪市'
    }],
    'text': '鹰潭市'
  }, {
    'code': '002026007',
    'children': [{
      'code': '002026007002',
      'text': '章贡区'
    }, {
      'code': '002026007021',
      'text': '赣县'
    }, {
      'code': '002026007022',
      'text': '信丰县'
    }, {
      'code': '002026007023',
      'text': '大余县'
    }, {
      'code': '002026007024',
      'text': '上犹县'
    }, {
      'code': '002026007025',
      'text': '崇义县'
    }, {
      'code': '002026007026',
      'text': '安远县'
    }, {
      'code': '002026007027',
      'text': '龙南县'
    }, {
      'code': '002026007028',
      'text': '定南县'
    }, {
      'code': '002026007029',
      'text': '全南县'
    }, {
      'code': '002026007030',
      'text': '宁都县'
    }, {
      'code': '002026007031',
      'text': '于都县'
    }, {
      'code': '002026007032',
      'text': '兴国县'
    }, {
      'code': '002026007033',
      'text': '会昌县'
    }, {
      'code': '002026007034',
      'text': '寻乌县'
    }, {
      'code': '002026007035',
      'text': '石城县'
    }, {
      'code': '002026007081',
      'text': '瑞金市'
    }, {
      'code': '002026007082',
      'text': '南康市'
    }],
    'text': '赣州市'
  }, {
    'code': '002026022',
    'children': [{
      'code': '002026022002',
      'text': '丰城市'
    }, {
      'code': '002026022003',
      'text': '樟树市'
    }, {
      'code': '002026022004',
      'text': '高安市'
    }, {
      'code': '002026022026',
      'text': '奉新县'
    }, {
      'code': '002026022027',
      'text': '万载县'
    }, {
      'code': '002026022028',
      'text': '上高县'
    }, {
      'code': '002026022029',
      'text': '宜丰县'
    }, {
      'code': '002026022032',
      'text': '靖安县'
    }, {
      'code': '002026022033',
      'text': '铜鼓县'
    }, {
      'code': '002026022099',
      'text': '袁州区'
    }],
    'text': '宜春市'
  }, {
    'code': '002026023',
    'children': [{
      'code': '002026023002',
      'text': '德兴市'
    }, {
      'code': '002026023021',
      'text': '上饶县'
    }, {
      'code': '002026023022',
      'text': '广丰县'
    }, {
      'code': '002026023023',
      'text': '玉山县'
    }, {
      'code': '002026023024',
      'text': '铅山县'
    }, {
      'code': '002026023025',
      'text': '横峰县'
    }, {
      'code': '002026023026',
      'text': '弋阳县'
    }, {
      'code': '002026023029',
      'text': '余干县'
    }, {
      'code': '002026023031',
      'text': '万年县'
    }, {
      'code': '002026023034',
      'text': '婺源县'
    }, {
      'code': '002026023098',
      'text': '鄱阳县'
    }, {
      'code': '002026023099',
      'text': '信州区'
    }],
    'text': '上饶市'
  }, {
    'code': '002026024',
    'children': [{
      'code': '002026024002',
      'text': '井冈山市'
    }, {
      'code': '002026024021',
      'text': '吉安县'
    }, {
      'code': '002026024022',
      'text': '吉水县'
    }, {
      'code': '002026024023',
      'text': '峡江县'
    }, {
      'code': '002026024024',
      'text': '新干县'
    }, {
      'code': '002026024025',
      'text': '永丰县'
    }, {
      'code': '002026024026',
      'text': '泰和县'
    }, {
      'code': '002026024027',
      'text': '遂川县'
    }, {
      'code': '002026024028',
      'text': '万安县'
    }, {
      'code': '002026024029',
      'text': '安福县'
    }, {
      'code': '002026024030',
      'text': '永新县'
    }, {
      'code': '002026024098',
      'text': '吉州区'
    }, {
      'code': '002026024099',
      'text': '青原区'
    }],
    'text': '吉安市'
  }, {
    'code': '002026025',
    'children': [{
      'code': '002026025002',
      'text': '临川市'
    }, {
      'code': '002026025022',
      'text': '南城县'
    }, {
      'code': '002026025023',
      'text': '黎川县'
    }, {
      'code': '002026025024',
      'text': '南丰县'
    }, {
      'code': '002026025025',
      'text': '崇仁县'
    }, {
      'code': '002026025026',
      'text': '乐安县'
    }, {
      'code': '002026025027',
      'text': '宜黄县'
    }, {
      'code': '002026025028',
      'text': '金溪县'
    }, {
      'code': '002026025029',
      'text': '资溪县'
    }, {
      'code': '002026025031',
      'text': '东乡县'
    }, {
      'code': '002026025032',
      'text': '广昌县'
    }],
    'text': '抚州市'
  }],
  'text': '江西'
}, {
  'code': '002027',
  'children': [{
    'code': '002027001',
    'children': [{
      'code': '002027001002',
      'text': '历下区'
    }, {
      'code': '002027001003',
      'text': '市中区'
    }, {
      'code': '002027001004',
      'text': '槐荫区'
    }, {
      'code': '002027001005',
      'text': '天桥区'
    }, {
      'code': '002027001010',
      'text': '高新区'
    }, {
      'code': '002027001012',
      'text': '历城区'
    }, {
      'code': '002027001023',
      'text': '长清区'
    }, {
      'code': '002027001024',
      'text': '平阴县'
    }, {
      'code': '002027001025',
      'text': '济阳县'
    }, {
      'code': '002027001026',
      'text': '商河县'
    }, {
      'code': '002027001081',
      'text': '章丘市'
    }, {
      'code': '002027001082',
      'text': '经济开发区'
    }, {
      'code': '002027001083',
      'text': '莱芜区'
    }, {
      'code': '002027001084',
      'text': '钢城区'
    }],
    'text': '济南市'
  }, {
    'code': '002027002',
    'children': [{
      'code': '002027002002',
      'text': '市南区'
    }, {
      'code': '002027002003',
      'text': '市北区'
    }, {
      'code': '002027002005',
      'text': '四方区'
    }, {
      'code': '002027002011',
      'text': '黄岛区'
    }, {
      'code': '002027002012',
      'text': '崂山区'
    }, {
      'code': '002027002013',
      'text': '李沧区'
    }, {
      'code': '002027002014',
      'text': '城阳区'
    }, {
      'code': '002027002081',
      'text': '胶州市'
    }, {
      'code': '002027002082',
      'text': '即墨市'
    }, {
      'code': '002027002083',
      'text': '平度市'
    }, {
      'code': '002027002084',
      'text': '胶南市'
    }, {
      'code': '002027002085',
      'text': '莱西市'
    }, {
      'code': '002027002086',
      'text': '高新区（城阳区）'
    }],
    'text': '青岛市'
  }, {
    'code': '002027003',
    'children': [{
      'code': '002027003002',
      'text': '淄川区'
    }, {
      'code': '002027003003',
      'text': '张店区'
    }, {
      'code': '002027003004',
      'text': '博山区'
    }, {
      'code': '002027003005',
      'text': '临淄区'
    }, {
      'code': '002027003006',
      'text': '周村区'
    }, {
      'code': '002027003021',
      'text': '桓台县'
    }, {
      'code': '002027003022',
      'text': '高青县'
    }, {
      'code': '002027003023',
      'text': '沂源县'
    }, {
      'code': '002027003024',
      'text': '高新区'
    }],
    'text': '淄博市'
  }, {
    'code': '002027004',
    'children': [{
      'code': '002027004002',
      'text': '市中区'
    }, {
      'code': '002027004003',
      'text': '薛城区'
    }, {
      'code': '002027004004',
      'text': '峄城区'
    }, {
      'code': '002027004005',
      'text': '台儿庄区'
    }, {
      'code': '002027004006',
      'text': '山亭区'
    }, {
      'code': '002027004081',
      'text': '滕州市'
    }],
    'text': '枣庄市'
  }, {
    'code': '002027005',
    'children': [{
      'code': '002027005001',
      'text': '东城区'
    }, {
      'code': '002027005002',
      'text': '东营区'
    }, {
      'code': '002027005003',
      'text': '河口区'
    }, {
      'code': '002027005021',
      'text': '垦利县'
    }, {
      'code': '002027005022',
      'text': '利津县'
    }, {
      'code': '002027005023',
      'text': '广饶县'
    }],
    'text': '东营市'
  }, {
    'code': '002027006',
    'children': [{
      'code': '002027006002',
      'text': '芝罘区'
    }, {
      'code': '002027006011',
      'text': '福山区'
    }, {
      'code': '002027006012',
      'text': '牟平区'
    }, {
      'code': '002027006013',
      'text': '莱山区'
    }, {
      'code': '002027006034',
      'text': '长岛县'
    }, {
      'code': '002027006081',
      'text': '龙口市'
    }, {
      'code': '002027006082',
      'text': '莱阳市'
    }, {
      'code': '002027006083',
      'text': '莱州市'
    }, {
      'code': '002027006084',
      'text': '蓬莱市'
    }, {
      'code': '002027006085',
      'text': '招远市'
    }, {
      'code': '002027006086',
      'text': '栖霞市'
    }, {
      'code': '002027006087',
      'text': '海阳市'
    }, {
      'code': '002027006088',
      'text': '开发区'
    }, {
      'code': '002027006089',
      'text': '高新区'
    }],
    'text': '烟台市'
  }, {
    'code': '002027007',
    'children': [{
      'code': '002027007002',
      'text': '潍城区'
    }, {
      'code': '002027007003',
      'text': '寒亭区'
    }, {
      'code': '002027007004',
      'text': '坊子区'
    }, {
      'code': '002027007005',
      'text': '奎文区'
    }, {
      'code': '002027007010',
      'text': '高新区'
    }, {
      'code': '002027007024',
      'text': '临朐县'
    }, {
      'code': '002027007025',
      'text': '昌乐县'
    }, {
      'code': '002027007081',
      'text': '青州市'
    }, {
      'code': '002027007082',
      'text': '诸城市'
    }, {
      'code': '002027007083',
      'text': '寿光市'
    }, {
      'code': '002027007084',
      'text': '安丘市'
    }, {
      'code': '002027007085',
      'text': '高密市'
    }, {
      'code': '002027007086',
      'text': '昌邑市'
    }, {
      'code': '002027007087',
      'text': '滨海经济开发区'
    }, {
      'code': '002027007088',
      'text': '经济开发区'
    }, {
      'code': '002027007089',
      'text': '出口加工区'
    }],
    'text': '潍坊市'
  }, {
    'code': '002027008',
    'children': [{
      'code': '002027008002',
      'text': '市中区'
    }, {
      'code': '002027008010',
      'text': '高新区'
    }, {
      'code': '002027008011',
      'text': '任城区'
    }, {
      'code': '002027008026',
      'text': '微山县'
    }, {
      'code': '002027008027',
      'text': '鱼台县'
    }, {
      'code': '002027008028',
      'text': '金乡县'
    }, {
      'code': '002027008029',
      'text': '嘉祥县'
    }, {
      'code': '002027008030',
      'text': '汶上县'
    }, {
      'code': '002027008031',
      'text': '泗水县'
    }, {
      'code': '002027008032',
      'text': '梁山县'
    }, {
      'code': '002027008081',
      'text': '曲阜市'
    }, {
      'code': '002027008082',
      'text': '兖州市'
    }, {
      'code': '002027008083',
      'text': '邹城市'
    }],
    'text': '济宁市'
  }, {
    'code': '002027009',
    'children': [{
      'code': '002027009002',
      'text': '泰山区'
    }, {
      'code': '002027009010',
      'text': '岱岳区'
    }, {
      'code': '002027009011',
      'text': '郊区'
    }, {
      'code': '002027009021',
      'text': '宁阳县'
    }, {
      'code': '002027009023',
      'text': '东平县'
    }, {
      'code': '002027009082',
      'text': '新泰市'
    }, {
      'code': '002027009083',
      'text': '肥城市'
    }],
    'text': '泰安市'
  }, {
    'code': '002027010',
    'children': [{
      'code': '002027010002',
      'text': '环翠区'
    }, {
      'code': '002027010081',
      'text': '文登市'
    }, {
      'code': '002027010082',
      'text': '荣成市'
    }, {
      'code': '002027010083',
      'text': '乳山市'
    }, {
      'code': '002027010084',
      'text': '高区'
    }, {
      'code': '002027010085',
      'text': '经区'
    }, {
      'code': '002027010086',
      'text': '高新技术开发区'
    }, {
      'code': '002027010087',
      'text': '经济技术开发区'
    }],
    'text': '威海市'
  }, {
    'code': '002027011',
    'children': [{
      'code': '002027011002',
      'text': '东港区'
    }, {
      'code': '002027011010',
      'text': '新市区'
    }, {
      'code': '002027011021',
      'text': '五莲县'
    }, {
      'code': '002027011022',
      'text': '莒县'
    }, {
      'code': '002027011023',
      'text': '岚山区'
    }],
    'text': '日照市'
  }, {
    'code': '002027012',
    'children': [{
      'code': '002027012002',
      'text': '莱城区'
    }, {
      'code': '002027012003',
      'text': '钢城区'
    }],
    'text': '莱芜市'
  }, {
    'code': '002027013',
    'children': [{
      'code': '002027013002',
      'text': '兰山区'
    }, {
      'code': '002027013010',
      'text': '兰陵县'
    }, {
      'code': '002027013011',
      'text': '罗庄区'
    }, {
      'code': '002027013012',
      'text': '河东区'
    }, {
      'code': '002027013021',
      'text': '沂南县'
    }, {
      'code': '002027013022',
      'text': '郯城县'
    }, {
      'code': '002027013023',
      'text': '沂水县'
    }, {
      'code': '002027013024',
      'text': '苍山县'
    }, {
      'code': '002027013025',
      'text': '费县'
    }, {
      'code': '002027013026',
      'text': '平邑县'
    }, {
      'code': '002027013027',
      'text': '莒南县'
    }, {
      'code': '002027013028',
      'text': '蒙阴县'
    }, {
      'code': '002027013029',
      'text': '临沭县'
    }],
    'text': '临沂市'
  }, {
    'code': '002027014',
    'children': [{
      'code': '002027014002',
      'text': '德城区'
    }, {
      'code': '002027014021',
      'text': '陵县'
    }, {
      'code': '002027014022',
      'text': '宁津县'
    }, {
      'code': '002027014023',
      'text': '庆云县'
    }, {
      'code': '002027014024',
      'text': '临邑县'
    }, {
      'code': '002027014025',
      'text': '齐河县'
    }, {
      'code': '002027014026',
      'text': '平原县'
    }, {
      'code': '002027014027',
      'text': '夏津县'
    }, {
      'code': '002027014028',
      'text': '武城县'
    }, {
      'code': '002027014081',
      'text': '乐陵市'
    }, {
      'code': '002027014082',
      'text': '禹城市'
    }],
    'text': '德州市'
  }, {
    'code': '002027015',
    'children': [{
      'code': '002027015002',
      'text': '东昌府区'
    }, {
      'code': '002027015021',
      'text': '阳谷县'
    }, {
      'code': '002027015022',
      'text': '莘县'
    }, {
      'code': '002027015023',
      'text': '茌平县'
    }, {
      'code': '002027015024',
      'text': '东阿县'
    }, {
      'code': '002027015025',
      'text': '冠县'
    }, {
      'code': '002027015026',
      'text': '高唐县'
    }, {
      'code': '002027015081',
      'text': '临清市'
    }],
    'text': '聊城市'
  }, {
    'code': '002027023',
    'children': [{
      'code': '002027023001',
      'text': '滨城区'
    }, {
      'code': '002027023010',
      'text': '北海新区'
    }, {
      'code': '002027023021',
      'text': '惠民县'
    }, {
      'code': '002027023023',
      'text': '阳信县'
    }, {
      'code': '002027023024',
      'text': '无棣县'
    }, {
      'code': '002027023025',
      'text': '沾化县'
    }, {
      'code': '002027023028',
      'text': '博兴县'
    }, {
      'code': '002027023030',
      'text': '邹平县'
    }, {
      'code': '002027023031',
      'text': '开发区'
    }],
    'text': '滨州市'
  }, {
    'code': '002027029',
    'children': [{
      'code': '002027029022',
      'text': '曹县'
    }, {
      'code': '002027029023',
      'text': '定陶县'
    }, {
      'code': '002027029024',
      'text': '成武县'
    }, {
      'code': '002027029025',
      'text': '单县'
    }, {
      'code': '002027029026',
      'text': '巨野县'
    }, {
      'code': '002027029028',
      'text': '郓城县'
    }, {
      'code': '002027029029',
      'text': '鄄城县'
    }, {
      'code': '002027029030',
      'text': '东明县'
    }, {
      'code': '002027029031',
      'text': '牡丹区'
    }, {
      'code': '002027029032',
      'text': '开发区'
    }],
    'text': '菏泽市'
  }],
  'text': '山东'
}, {
  'code': '002031',
  'children': [{
    'code': '002031001',
    'children': [{
      'code': '002031001002',
      'text': '中原区'
    }, {
      'code': '002031001003',
      'text': '二七区'
    }, {
      'code': '002031001004',
      'text': '管城回族区'
    }, {
      'code': '002031001005',
      'text': '金水区'
    }, {
      'code': '002031001006',
      'text': '上街区'
    }, {
      'code': '002031001008',
      'text': '惠济区'
    }, {
      'code': '002031001010',
      'text': '郑东新区'
    }, {
      'code': '002031001011',
      'text': '高新技术开发区'
    }, {
      'code': '002031001012',
      'text': '经济开发区'
    }, {
      'code': '002031001022',
      'text': '中牟县'
    }, {
      'code': '002031001081',
      'text': '巩义市'
    }, {
      'code': '002031001082',
      'text': '荥阳市'
    }, {
      'code': '002031001083',
      'text': '新密市'
    }, {
      'code': '002031001084',
      'text': '新郑市'
    }, {
      'code': '002031001085',
      'text': '登封市'
    }, {
      'code': '002031001088',
      'text': '出口加工区'
    }],
    'text': '郑州市'
  }, {
    'code': '002031002',
    'children': [{
      'code': '002031002002',
      'text': '龙亭区'
    }, {
      'code': '002031002003',
      'text': '顺河回族区'
    }, {
      'code': '002031002004',
      'text': '鼓楼区'
    }, {
      'code': '002031002005',
      'text': '南关区'
    }, {
      'code': '002031002011',
      'text': '郊区'
    }, {
      'code': '002031002012',
      'text': '禹王台区'
    }, {
      'code': '002031002013',
      'text': '金明区'
    }, {
      'code': '002031002021',
      'text': '杞县'
    }, {
      'code': '002031002022',
      'text': '通许县'
    }, {
      'code': '002031002023',
      'text': '尉氏县'
    }, {
      'code': '002031002024',
      'text': '开封县'
    }, {
      'code': '002031002025',
      'text': '兰考县'
    }, {
      'code': '002031002026',
      'text': '祥符区'
    }],
    'text': '开封市'
  }, {
    'code': '002031003',
    'children': [{
      'code': '002031003002',
      'text': '老城区'
    }, {
      'code': '002031003003',
      'text': '西工区'
    }, {
      'code': '002031003004',
      'text': '壥河回族区'
    }, {
      'code': '002031003005',
      'text': '涧西区'
    }, {
      'code': '002031003006',
      'text': '吉利区'
    }, {
      'code': '002031003011',
      'text': '郊区'
    }, {
      'code': '002031003014',
      'text': '伊滨区'
    }, {
      'code': '002031003015',
      'text': '洛龙区'
    }, {
      'code': '002031003022',
      'text': '孟津县'
    }, {
      'code': '002031003023',
      'text': '新安县'
    }, {
      'code': '002031003024',
      'text': '栾川县'
    }, {
      'code': '002031003025',
      'text': '嵩县'
    }, {
      'code': '002031003026',
      'text': '汝阳县'
    }, {
      'code': '002031003027',
      'text': '宜阳县'
    }, {
      'code': '002031003028',
      'text': '洛宁县'
    }, {
      'code': '002031003029',
      'text': '伊川县'
    }, {
      'code': '002031003081',
      'text': '偃师市'
    }, {
      'code': '002031003082',
      'text': '高新技术开发区'
    }, {
      'code': '002031003083',
      'text': '经济技术开发区'
    }, {
      'code': '002031003084',
      'text': '瀍河回族区'
    }],
    'text': '洛阳市'
  }, {
    'code': '002031004',
    'children': [{
      'code': '002031004002',
      'text': '新华区'
    }, {
      'code': '002031004003',
      'text': '卫东区'
    }, {
      'code': '002031004004',
      'text': '石龙区'
    }, {
      'code': '002031004011',
      'text': '湛河区'
    }, {
      'code': '002031004021',
      'text': '宝丰县'
    }, {
      'code': '002031004022',
      'text': '叶县'
    }, {
      'code': '002031004023',
      'text': '鲁山县'
    }, {
      'code': '002031004025',
      'text': '郏县'
    }, {
      'code': '002031004081',
      'text': '舞钢市'
    }, {
      'code': '002031004082',
      'text': '汝州市'
    }],
    'text': '平顶山市'
  }, {
    'code': '002031005',
    'children': [{
      'code': '002031005002',
      'text': '文峰区'
    }, {
      'code': '002031005003',
      'text': '北关区'
    }, {
      'code': '002031005004',
      'text': '铁西区'
    }, {
      'code': '002031005011',
      'text': '郊区'
    }, {
      'code': '002031005012',
      'text': '开发区'
    }, {
      'code': '002031005013',
      'text': '殷都区'
    }, {
      'code': '002031005014',
      'text': '龙安区'
    }, {
      'code': '002031005022',
      'text': '安阳县'
    }, {
      'code': '002031005023',
      'text': '汤阴县'
    }, {
      'code': '002031005026',
      'text': '滑县'
    }, {
      'code': '002031005027',
      'text': '内黄县'
    }, {
      'code': '002031005081',
      'text': '林州市'
    }],
    'text': '安阳市'
  }, {
    'code': '002031006',
    'children': [{
      'code': '002031006002',
      'text': '鹤山区'
    }, {
      'code': '002031006003',
      'text': '山城区'
    }, {
      'code': '002031006010',
      'text': '淇滨区'
    }, {
      'code': '002031006011',
      'text': '郊区'
    }, {
      'code': '002031006021',
      'text': '浚县'
    }, {
      'code': '002031006022',
      'text': '淇县'
    }],
    'text': '鹤壁市'
  }, {
    'code': '002031007',
    'children': [{
      'code': '002031007002',
      'text': '红旗区'
    }, {
      'code': '002031007003',
      'text': '新华区'
    }, {
      'code': '002031007004',
      'text': '北站区'
    }, {
      'code': '002031007011',
      'text': '郊区'
    }, {
      'code': '002031007012',
      'text': '凤泉区'
    }, {
      'code': '002031007013',
      'text': '牧野区'
    }, {
      'code': '002031007014',
      'text': '卫滨区'
    }, {
      'code': '002031007021',
      'text': '新乡县'
    }, {
      'code': '002031007024',
      'text': '获嘉县'
    }, {
      'code': '002031007025',
      'text': '原阳县'
    }, {
      'code': '002031007026',
      'text': '延津县'
    }, {
      'code': '002031007027',
      'text': '封丘县'
    }, {
      'code': '002031007028',
      'text': '长垣县'
    }, {
      'code': '002031007081',
      'text': '卫辉市'
    }, {
      'code': '002031007082',
      'text': '辉县市'
    }],
    'text': '新乡市'
  }, {
    'code': '002031008',
    'children': [{
      'code': '002031008002',
      'text': '解放区'
    }, {
      'code': '002031008003',
      'text': '中站区'
    }, {
      'code': '002031008004',
      'text': '马村区'
    }, {
      'code': '002031008011',
      'text': '山阳区'
    }, {
      'code': '002031008021',
      'text': '修武县'
    }, {
      'code': '002031008022',
      'text': '博爱县'
    }, {
      'code': '002031008023',
      'text': '武陟县'
    }, {
      'code': '002031008025',
      'text': '温县'
    }, {
      'code': '002031008081',
      'text': '济源市'
    }, {
      'code': '002031008082',
      'text': '沁阳市'
    }, {
      'code': '002031008083',
      'text': '孟州市'
    }],
    'text': '焦作市'
  }, {
    'code': '002031009',
    'children': [{
      'code': '002031009002',
      'text': '市区'
    }, {
      'code': '002031009010',
      'text': '华龙区'
    }, {
      'code': '002031009022',
      'text': '清丰县'
    }, {
      'code': '002031009023',
      'text': '南乐县'
    }, {
      'code': '002031009026',
      'text': '范县'
    }, {
      'code': '002031009027',
      'text': '台前县'
    }, {
      'code': '002031009028',
      'text': '濮阳县'
    }],
    'text': '濮阳市'
  }, {
    'code': '002031010',
    'children': [{
      'code': '002031010002',
      'text': '魏都区'
    }, {
      'code': '002031010010',
      'text': '建安区'
    }, {
      'code': '002031010023',
      'text': '许昌县'
    }, {
      'code': '002031010024',
      'text': '鄢陵县'
    }, {
      'code': '002031010025',
      'text': '襄城县'
    }, {
      'code': '002031010081',
      'text': '禹州市'
    }, {
      'code': '002031010082',
      'text': '长葛市'
    }],
    'text': '许昌市'
  }, {
    'code': '002031011',
    'children': [{
      'code': '002031011002',
      'text': '源汇区'
    }, {
      'code': '002031011010',
      'text': '召陵区'
    }, {
      'code': '002031011021',
      'text': '舞阳县'
    }, {
      'code': '002031011022',
      'text': '临颍县'
    }, {
      'code': '002031011023',
      'text': '郾城县'
    }],
    'text': '漯河市'
  }, {
    'code': '002031012',
    'children': [{
      'code': '002031012002',
      'text': '湖滨区'
    }, {
      'code': '002031012021',
      'text': '渑池县'
    }, {
      'code': '002031012022',
      'text': '陕县'
    }, {
      'code': '002031012024',
      'text': '卢氏县'
    }, {
      'code': '002031012081',
      'text': '义马市'
    }, {
      'code': '002031012082',
      'text': '灵宝市'
    }, {
      'code': '002031012083',
      'text': '陕州区'
    }],
    'text': '三门峡市'
  }, {
    'code': '002031013',
    'children': [{
      'code': '002031013002',
      'text': '宛城区'
    }, {
      'code': '002031013003',
      'text': '卧龙区'
    }, {
      'code': '002031013021',
      'text': '南召县'
    }, {
      'code': '002031013022',
      'text': '方城县'
    }, {
      'code': '002031013023',
      'text': '西峡县'
    }, {
      'code': '002031013024',
      'text': '镇平县'
    }, {
      'code': '002031013025',
      'text': '内乡县'
    }, {
      'code': '002031013026',
      'text': '淅川县'
    }, {
      'code': '002031013027',
      'text': '社旗县'
    }, {
      'code': '002031013028',
      'text': '唐河县'
    }, {
      'code': '002031013029',
      'text': '新野县'
    }, {
      'code': '002031013030',
      'text': '桐柏县'
    }, {
      'code': '002031013081',
      'text': '邓州市'
    }],
    'text': '南阳市'
  }, {
    'code': '002031014',
    'children': [{
      'code': '002031014002',
      'text': '梁园区'
    }, {
      'code': '002031014003',
      'text': '睢阳区'
    }, {
      'code': '002031014021',
      'text': '民权县'
    }, {
      'code': '002031014022',
      'text': '睢县'
    }, {
      'code': '002031014023',
      'text': '宁陵县'
    }, {
      'code': '002031014024',
      'text': '柘城县'
    }, {
      'code': '002031014025',
      'text': '虞城县'
    }, {
      'code': '002031014026',
      'text': '夏邑县'
    }, {
      'code': '002031014081',
      'text': '永城市'
    }],
    'text': '商丘市'
  }, {
    'code': '002031015',
    'children': [{
      'code': '002031015002',
      'text': '浉河区'
    }, {
      'code': '002031015003',
      'text': '平桥区'
    }, {
      'code': '002031015021',
      'text': '罗山县'
    }, {
      'code': '002031015022',
      'text': '光山县'
    }, {
      'code': '002031015023',
      'text': '新县'
    }, {
      'code': '002031015024',
      'text': '商城县'
    }, {
      'code': '002031015025',
      'text': '固始县'
    }, {
      'code': '002031015026',
      'text': '潢川县'
    }, {
      'code': '002031015027',
      'text': '淮滨县'
    }, {
      'code': '002031015028',
      'text': '息县'
    }, {
      'code': '002031015029',
      'text': '羊山新区'
    }],
    'text': '信阳市'
  }, {
    'code': '002031027',
    'children': [{
      'code': '002031027001',
      'text': '周口市'
    }, {
      'code': '002031027002',
      'text': '项城市'
    }, {
      'code': '002031027010',
      'text': '川汇区'
    }, {
      'code': '002031027011',
      'text': '经济开发区'
    }, {
      'code': '002031027012',
      'text': '东新区'
    }, {
      'code': '002031027021',
      'text': '扶沟县'
    }, {
      'code': '002031027022',
      'text': '西华县'
    }, {
      'code': '002031027023',
      'text': '商水县'
    }, {
      'code': '002031027024',
      'text': '太康县'
    }, {
      'code': '002031027025',
      'text': '鹿邑县'
    }, {
      'code': '002031027026',
      'text': '郸城县'
    }, {
      'code': '002031027027',
      'text': '淮阳县'
    }, {
      'code': '002031027028',
      'text': '沈丘县'
    }],
    'text': '周口市'
  }, {
    'code': '002031028',
    'children': [{
      'code': '002031028001',
      'text': '驻马店市'
    }, {
      'code': '002031028010',
      'text': '驿城区'
    }, {
      'code': '002031028021',
      'text': '确山县'
    }, {
      'code': '002031028022',
      'text': '泌阳县'
    }, {
      'code': '002031028023',
      'text': '遂平县'
    }, {
      'code': '002031028024',
      'text': '西平县'
    }, {
      'code': '002031028025',
      'text': '上蔡县'
    }, {
      'code': '002031028026',
      'text': '汝南县'
    }, {
      'code': '002031028027',
      'text': '平舆县'
    }, {
      'code': '002031028028',
      'text': '新蔡县'
    }, {
      'code': '002031028029',
      'text': '正阳县'
    }],
    'text': '驻马店市'
  }, {
    'code': '002031029',
    'children': [{
      'code': '002031029001',
      'text': '济源市区'
    }, {
      'code': '002031029010',
      'text': '轵城镇'
    }, {
      'code': '002031029011',
      'text': '王屋镇'
    }, {
      'code': '002031029012',
      'text': '坡头镇'
    }, {
      'code': '002031029013',
      'text': '克井镇'
    }, {
      'code': '002031029014',
      'text': '承留镇'
    }, {
      'code': '002031029015',
      'text': '五龙口镇'
    }, {
      'code': '002031029016',
      'text': '梨林镇'
    }, {
      'code': '002031029017',
      'text': '下冶镇'
    }, {
      'code': '002031029018',
      'text': '邵原镇'
    }, {
      'code': '002031029019',
      'text': '大峪镇'
    }, {
      'code': '002031029020',
      'text': '思礼镇'
    }],
    'text': '济源市'
  }],
  'text': '河南'
}, {
  'code': '002032',
  'children': [{
    'code': '002032001',
    'children': [{
      'code': '002032001002',
      'text': '江岸区'
    }, {
      'code': '002032001003',
      'text': '江汉区'
    }, {
      'code': '002032001004',
      'text': '硚口区'
    }, {
      'code': '002032001005',
      'text': '汉阳区'
    }, {
      'code': '002032001006',
      'text': '武昌区'
    }, {
      'code': '002032001007',
      'text': '青山区'
    }, {
      'code': '002032001010',
      'text': '武汉经济技术开发区'
    }, {
      'code': '002032001011',
      'text': '洪山区'
    }, {
      'code': '002032001012',
      'text': '东西湖区'
    }, {
      'code': '002032001013',
      'text': '汉南区'
    }, {
      'code': '002032001014',
      'text': '蔡甸区'
    }, {
      'code': '002032001015',
      'text': '江夏区'
    }, {
      'code': '002032001016',
      'text': '黄陂区'
    }, {
      'code': '002032001017',
      'text': '新洲区'
    }, {
      'code': '002032001018',
      'text': '东湖高新技术开发区'
    }],
    'text': '武汉市'
  }, {
    'code': '002032002',
    'children': [{
      'code': '002032002002',
      'text': '黄石港区'
    }, {
      'code': '002032002003',
      'text': '石灰窑区'
    }, {
      'code': '002032002004',
      'text': '下陆区'
    }, {
      'code': '002032002005',
      'text': '铁山区'
    }, {
      'code': '002032002010',
      'text': '经济技术开发区'
    }, {
      'code': '002032002011',
      'text': '西塞山区'
    }, {
      'code': '002032002022',
      'text': '阳新县'
    }, {
      'code': '002032002081',
      'text': '大冶市'
    }],
    'text': '黄石市'
  }, {
    'code': '002032003',
    'children': [{
      'code': '002032003002',
      'text': '茅箭区'
    }, {
      'code': '002032003003',
      'text': '张湾区'
    }, {
      'code': '002032003021',
      'text': '郧县'
    }, {
      'code': '002032003022',
      'text': '郧西县'
    }, {
      'code': '002032003023',
      'text': '竹山县'
    }, {
      'code': '002032003024',
      'text': '竹溪县'
    }, {
      'code': '002032003025',
      'text': '房县'
    }, {
      'code': '002032003081',
      'text': '丹江口市'
    }, {
      'code': '002032003099',
      'text': '武当山特区'
    }, {
      'code': '002032003100',
      'text': '郧阳区'
    }],
    'text': '十堰市'
  }, {
    'code': '002032005',
    'children': [{
      'code': '002032005002',
      'text': '西陵区'
    }, {
      'code': '002032005003',
      'text': '伍家岗区'
    }, {
      'code': '002032005004',
      'text': '点军区'
    }, {
      'code': '002032005005',
      'text': '猇亭区'
    }, {
      'code': '002032005021',
      'text': '宜昌县'
    }, {
      'code': '002032005025',
      'text': '远安县'
    }, {
      'code': '002032005026',
      'text': '兴山县'
    }, {
      'code': '002032005027',
      'text': '秭归县'
    }, {
      'code': '002032005028',
      'text': '长阳土家族自治县'
    }, {
      'code': '002032005029',
      'text': '五峰土家族自治县'
    }, {
      'code': '002032005081',
      'text': '宜都市'
    }, {
      'code': '002032005082',
      'text': '当阳市'
    }, {
      'code': '002032005083',
      'text': '枝江市'
    }, {
      'code': '002032005099',
      'text': '夷陵区'
    }, {
      'code': '002032005100',
      'text': '经济技术开发区'
    }],
    'text': '宜昌市'
  }, {
    'code': '002032006',
    'children': [{
      'code': '002032006002',
      'text': '襄城区'
    }, {
      'code': '002032006006',
      'text': '樊城区'
    }, {
      'code': '002032006010',
      'text': '襄州区'
    }, {
      'code': '002032006021',
      'text': '襄阳县'
    }, {
      'code': '002032006024',
      'text': '南漳县'
    }, {
      'code': '002032006025',
      'text': '谷城县'
    }, {
      'code': '002032006026',
      'text': '保康县'
    }, {
      'code': '002032006082',
      'text': '老河口市'
    }, {
      'code': '002032006083',
      'text': '枣阳市'
    }, {
      'code': '002032006084',
      'text': '宜城市'
    }],
    'text': '襄阳市'
  }, {
    'code': '002032007',
    'children': [{
      'code': '002032007002',
      'text': '梁子湖区'
    }, {
      'code': '002032007003',
      'text': '华容区'
    }, {
      'code': '002032007004',
      'text': '鄂城区'
    }],
    'text': '鄂州市'
  }, {
    'code': '002032008',
    'children': [{
      'code': '002032008002',
      'text': '东宝区'
    }, {
      'code': '002032008021',
      'text': '京山县'
    }, {
      'code': '002032008022',
      'text': '沙洋县'
    }, {
      'code': '002032008081',
      'text': '钟祥县'
    }, {
      'code': '002032008099',
      'text': '掇刀区'
    }],
    'text': '荆门市'
  }, {
    'code': '002032009',
    'children': [{
      'code': '002032009002',
      'text': '孝南区'
    }, {
      'code': '002032009021',
      'text': '孝昌县'
    }, {
      'code': '002032009022',
      'text': '大悟县'
    }, {
      'code': '002032009023',
      'text': '云梦县'
    }, {
      'code': '002032009081',
      'text': '应城市'
    }, {
      'code': '002032009082',
      'text': '安陆市'
    }, {
      'code': '002032009084',
      'text': '汉川市'
    }],
    'text': '孝感市'
  }, {
    'code': '002032010',
    'children': [{
      'code': '002032010002',
      'text': '沙市区'
    }, {
      'code': '002032010003',
      'text': '荆州区'
    }, {
      'code': '002032010022',
      'text': '公安县'
    }, {
      'code': '002032010023',
      'text': '监利县'
    }, {
      'code': '002032010024',
      'text': '江陵县'
    }, {
      'code': '002032010081',
      'text': '石首市'
    }, {
      'code': '002032010083',
      'text': '洪湖市'
    }, {
      'code': '002032010087',
      'text': '松滋市'
    }, {
      'code': '002032010099',
      'text': '荆州开发区'
    }],
    'text': '荆州市'
  }, {
    'code': '002032011',
    'children': [{
      'code': '002032011002',
      'text': '黄州区'
    }, {
      'code': '002032011021',
      'text': '团风县'
    }, {
      'code': '002032011022',
      'text': '红安县'
    }, {
      'code': '002032011023',
      'text': '罗田县'
    }, {
      'code': '002032011024',
      'text': '英山县'
    }, {
      'code': '002032011025',
      'text': '浠水县'
    }, {
      'code': '002032011026',
      'text': '蕲春县'
    }, {
      'code': '002032011027',
      'text': '黄梅县'
    }, {
      'code': '002032011081',
      'text': '麻城市'
    }, {
      'code': '002032011082',
      'text': '武穴市'
    }],
    'text': '黄冈市'
  }, {
    'code': '002032012',
    'children': [{
      'code': '002032012002',
      'text': '咸安区'
    }, {
      'code': '002032012021',
      'text': '嘉鱼县'
    }, {
      'code': '002032012022',
      'text': '通城县'
    }, {
      'code': '002032012023',
      'text': '崇阳县'
    }, {
      'code': '002032012024',
      'text': '通山县'
    }, {
      'code': '002032012081',
      'text': '赤壁市'
    }],
    'text': '咸宁市'
  }, {
    'code': '002032028',
    'children': [{
      'code': '002032028001',
      'text': '恩施市'
    }, {
      'code': '002032028002',
      'text': '利川市'
    }, {
      'code': '002032028022',
      'text': '建始县'
    }, {
      'code': '002032028023',
      'text': '巴东县'
    }, {
      'code': '002032028025',
      'text': '宣恩县'
    }, {
      'code': '002032028026',
      'text': '咸丰县'
    }, {
      'code': '002032028027',
      'text': '来凤县'
    }, {
      'code': '002032028028',
      'text': '鹤峰县'
    }],
    'text': '恩施土家族苗族自治州'
  }, {
    'code': '002032091',
    'children': [{
      'code': '002032091001',
      'text': '曾都区'
    }, {
      'code': '002032091002',
      'text': '广水市'
    }, {
      'code': '002032091010',
      'text': '随县'
    }],
    'text': '随州市'
  }, {
    'code': '002032093',
    'children': [{
      'code': '002032090021',
      'text': '神农架林区'
    }, {
      'code': '002032093010',
      'text': '松柏镇'
    }, {
      'code': '002032093011',
      'text': '木鱼镇'
    }, {
      'code': '002032093012',
      'text': '红坪镇'
    }, {
      'code': '002032093013',
      'text': '新华镇'
    }, {
      'code': '002032093014',
      'text': '九湖乡'
    }, {
      'code': '002032093015',
      'text': '下谷坪乡'
    }, {
      'code': '002032093016',
      'text': '阳日镇'
    }, {
      'code': '002032093017',
      'text': '宋洛乡'
    }],
    'text': '神农架林区'
  }, {
    'code': '002032094',
    'children': [{
      'code': '002032090004',
      'text': '仙桃市'
    }, {
      'code': '002032094010',
      'text': '郑场镇'
    }, {
      'code': '002032094011',
      'text': '毛嘴镇'
    }, {
      'code': '002032094012',
      'text': '三伏潭镇'
    }, {
      'code': '002032094013',
      'text': '胡场镇'
    }, {
      'code': '002032094014',
      'text': '西流河镇'
    }, {
      'code': '002032094015',
      'text': '沙湖镇'
    }, {
      'code': '002032094016',
      'text': '杨林尾镇'
    }, {
      'code': '002032094017',
      'text': '张沟镇'
    }, {
      'code': '002032094018',
      'text': '郭河镇'
    }, {
      'code': '002032094019',
      'text': '通海口镇'
    }, {
      'code': '002032094020',
      'text': '陈场镇'
    }, {
      'code': '002032094021',
      'text': '工业园区'
    }, {
      'code': '002032094022',
      'text': '沙湖原种场'
    }, {
      'code': '002032094023',
      'text': '排湖渔场'
    }, {
      'code': '002032094024',
      'text': '五湖渔场'
    }, {
      'code': '002032094025',
      'text': '刘家垸林场'
    }, {
      'code': '002032094026',
      'text': '畜禽良种场'
    }, {
      'code': '002032094027',
      'text': '豆河镇'
    }, {
      'code': '002032094028',
      'text': '长埫口镇'
    }, {
      'code': '002032094029',
      'text': '彭场镇'
    }, {
      'code': '002032094030',
      'text': '沔城镇'
    }, {
      'code': '002032094031',
      'text': '九合垸原种场'
    }, {
      'code': '002032094032',
      'text': '赵西垸林场'
    }, {
      'code': '002032094033',
      'text': '干河街道'
    }, {
      'code': '002032094034',
      'text': '龙华山办事处'
    }, {
      'code': '002032094035',
      'text': '沙嘴街道'
    }],
    'text': '仙桃市'
  }, {
    'code': '002032095',
    'children': [{
      'code': '002032090005',
      'text': '潜江市'
    }, {
      'code': '002032095010',
      'text': '园林'
    }, {
      'code': '002032095011',
      'text': '周矶'
    }, {
      'code': '002032095012',
      'text': '广华'
    }, {
      'code': '002032095013',
      'text': '高石碑镇'
    }, {
      'code': '002032095014',
      'text': '积玉口镇'
    }, {
      'code': '002032095015',
      'text': '熊口镇'
    }, {
      'code': '002032095016',
      'text': '浩口镇'
    }, {
      'code': '002032095017',
      'text': '张金镇'
    }, {
      'code': '002032095018',
      'text': '西大垸管理区'
    }, {
      'code': '002032095019',
      'text': '后湖管理区'
    }, {
      'code': '002032095020',
      'text': '熊口管理区'
    }, {
      'code': '002032095021',
      'text': '总口管理区'
    }, {
      'code': '002032095022',
      'text': '杨市'
    }, {
      'code': '002032095023',
      'text': '泰丰'
    }, {
      'code': '002032095024',
      'text': '渔洋镇'
    }, {
      'code': '002032095025',
      'text': '老新镇'
    }, {
      'code': '002032095026',
      'text': '江汉石油管理局'
    }, {
      'code': '002032095027',
      'text': '周矶管理区'
    }, {
      'code': '002032095028',
      'text': '高场原种场'
    }, {
      'code': '002032095029',
      'text': '竹根滩镇'
    }, {
      'code': '002032095030',
      'text': '王场镇'
    }, {
      'code': '002032095031',
      'text': '龙湾镇'
    }, {
      'code': '002032095032',
      'text': '运粮湖管理区'
    }, {
      'code': '002032095033',
      'text': '浩口原种场'
    }, {
      'code': '002032095034',
      'text': '潜江经济开发区'
    }, {
      'code': '002032095035',
      'text': '白鹭湖管理区'
    }, {
      'code': '002032095036',
      'text': '高场办事处'
    }, {
      'code': '002032095037',
      'text': '广华寺农场'
    }],
    'text': '潜江市'
  }, {
    'code': '002032096',
    'children': [{
      'code': '002032090006',
      'text': '天门市'
    }, {
      'code': '002032096010',
      'text': '竟陵街道'
    }, {
      'code': '002032096011',
      'text': '杨林街道'
    }, {
      'code': '002032096012',
      'text': '多宝镇'
    }, {
      'code': '002032096013',
      'text': '拖市镇'
    }, {
      'code': '002032096014',
      'text': '蒋场镇'
    }, {
      'code': '002032096015',
      'text': '汪场镇'
    }, {
      'code': '002032096016',
      'text': '渔薪镇'
    }, {
      'code': '002032096017',
      'text': '岳口镇'
    }, {
      'code': '002032096018',
      'text': '横林镇'
    }, {
      'code': '002032096019',
      'text': '麻洋镇'
    }, {
      'code': '002032096020',
      'text': '多祥镇'
    }, {
      'code': '002032096021',
      'text': '干驿镇'
    }, {
      'code': '002032096022',
      'text': '卢市镇'
    }, {
      'code': '002032096023',
      'text': '小板镇'
    }, {
      'code': '002032096024',
      'text': '皂市镇'
    }, {
      'code': '002032096025',
      'text': '胡市镇'
    }, {
      'code': '002032096026',
      'text': '石河镇'
    }, {
      'code': '002032096027',
      'text': '蒋湖农场'
    }, {
      'code': '002032096028',
      'text': '白茅湖农场'
    }, {
      'code': '002032096029',
      'text': '侨乡街道开发区'
    }, {
      'code': '002032096030',
      'text': '佛子山镇'
    }, {
      'code': '002032096031',
      'text': '张港镇'
    }, {
      'code': '002032096032',
      'text': '黄潭镇'
    }, {
      'code': '002032096033',
      'text': '彭市镇'
    }, {
      'code': '002032096034',
      'text': '马湾镇'
    }, {
      'code': '002032096035',
      'text': '九真镇'
    }, {
      'code': '002032096036',
      'text': '净潭乡'
    }, {
      'code': '002032096037',
      'text': '沉湖管委会'
    }],
    'text': '天门市'
  }],
  'text': '湖北'
}, {
  'code': '002033',
  'children': [{
    'code': '002033001',
    'children': [{
      'code': '002033001002',
      'text': '芙蓉区'
    }, {
      'code': '002033001003',
      'text': '天心区'
    }, {
      'code': '002033001004',
      'text': '岳麓区'
    }, {
      'code': '002033001005',
      'text': '开福区'
    }, {
      'code': '002033001011',
      'text': '雨花区'
    }, {
      'code': '002033001021',
      'text': '长沙县'
    }, {
      'code': '002033001022',
      'text': '望城县'
    }, {
      'code': '002033001024',
      'text': '宁乡县'
    }, {
      'code': '002033001081',
      'text': '浏阳市'
    }],
    'text': '长沙市'
  }, {
    'code': '002033002',
    'children': [{
      'code': '002033002002',
      'text': '荷塘区'
    }, {
      'code': '002033002003',
      'text': '芦淞区'
    }, {
      'code': '002033002004',
      'text': '石峰区'
    }, {
      'code': '002033002011',
      'text': '天元区'
    }, {
      'code': '002033002021',
      'text': '株洲县'
    }, {
      'code': '002033002023',
      'text': '攸县'
    }, {
      'code': '002033002024',
      'text': '茶陵县'
    }, {
      'code': '002033002025',
      'text': '炎陵县'
    }, {
      'code': '002033002081',
      'text': '醴陵市'
    }, {
      'code': '002033002082',
      'text': '云龙示范区'
    }],
    'text': '株洲市'
  }, {
    'code': '002033003',
    'children': [{
      'code': '002033003002',
      'text': '雨湖区'
    }, {
      'code': '002033003004',
      'text': '岳塘区'
    }, {
      'code': '002033003021',
      'text': '湘潭县'
    }, {
      'code': '002033003081',
      'text': '湘乡市'
    }, {
      'code': '002033003082',
      'text': '韶山市'
    }],
    'text': '湘潭市'
  }, {
    'code': '002033004',
    'children': [{
      'code': '002033004012',
      'text': '南岳区'
    }, {
      'code': '002033004021',
      'text': '衡阳县'
    }, {
      'code': '002033004022',
      'text': '衡南县'
    }, {
      'code': '002033004023',
      'text': '衡山县'
    }, {
      'code': '002033004024',
      'text': '衡东县'
    }, {
      'code': '002033004026',
      'text': '祁东县'
    }, {
      'code': '002033004081',
      'text': '耒阳市'
    }, {
      'code': '002033004082',
      'text': '常宁市'
    }, {
      'code': '002033004096',
      'text': '珠晖区'
    }, {
      'code': '002033004097',
      'text': '蒸湘区'
    }, {
      'code': '002033004098',
      'text': '雁峰区'
    }, {
      'code': '002033004099',
      'text': '石鼓区'
    }],
    'text': '衡阳市'
  }, {
    'code': '002033005',
    'children': [{
      'code': '002033005002',
      'text': '双清区'
    }, {
      'code': '002033005003',
      'text': '大祥区'
    }, {
      'code': '002033005011',
      'text': '北塔区'
    }, {
      'code': '002033005021',
      'text': '邵东县'
    }, {
      'code': '002033005022',
      'text': '新邵县'
    }, {
      'code': '002033005023',
      'text': '邵阳县'
    }, {
      'code': '002033005024',
      'text': '隆回县'
    }, {
      'code': '002033005025',
      'text': '洞口县'
    }, {
      'code': '002033005027',
      'text': '绥宁县'
    }, {
      'code': '002033005028',
      'text': '新宁县'
    }, {
      'code': '002033005029',
      'text': '城步苗族自治县'
    }, {
      'code': '002033005081',
      'text': '武冈市'
    }],
    'text': '邵阳市'
  }, {
    'code': '002033006',
    'children': [{
      'code': '002033006002',
      'text': '岳阳楼区'
    }, {
      'code': '002033006003',
      'text': '云溪区'
    }, {
      'code': '002033006011',
      'text': '君山区'
    }, {
      'code': '002033006021',
      'text': '岳阳县'
    }, {
      'code': '002033006023',
      'text': '华容县'
    }, {
      'code': '002033006024',
      'text': '湘阴县'
    }, {
      'code': '002033006026',
      'text': '平江县'
    }, {
      'code': '002033006081',
      'text': '汨罗市'
    }, {
      'code': '002033006082',
      'text': '临湘市'
    }],
    'text': '岳阳市'
  }, {
    'code': '002033007',
    'children': [{
      'code': '002033007002',
      'text': '武陵区'
    }, {
      'code': '002033007003',
      'text': '鼎城区'
    }, {
      'code': '002033007021',
      'text': '安乡县'
    }, {
      'code': '002033007022',
      'text': '汉寿县'
    }, {
      'code': '002033007023',
      'text': '澧县'
    }, {
      'code': '002033007024',
      'text': '临澧县'
    }, {
      'code': '002033007025',
      'text': '桃源县'
    }, {
      'code': '002033007026',
      'text': '石门县'
    }, {
      'code': '002033007081',
      'text': '津市市'
    }],
    'text': '常德市'
  }, {
    'code': '002033008',
    'children': [{
      'code': '002033008002',
      'text': '永定区'
    }, {
      'code': '002033008011',
      'text': '武陵源区'
    }, {
      'code': '002033008021',
      'text': '慈利县'
    }, {
      'code': '002033008022',
      'text': '桑植县'
    }],
    'text': '张家界市'
  }, {
    'code': '002033009',
    'children': [{
      'code': '002033009002',
      'text': '资阳区'
    }, {
      'code': '002033009003',
      'text': '赫山区'
    }, {
      'code': '002033009021',
      'text': '南县'
    }, {
      'code': '002033009022',
      'text': '桃江县'
    }, {
      'code': '002033009023',
      'text': '安化县'
    }, {
      'code': '002033009081',
      'text': '沅江市'
    }],
    'text': '益阳市'
  }, {
    'code': '002033010',
    'children': [{
      'code': '002033010002',
      'text': '北湖区'
    }, {
      'code': '002033010021',
      'text': '桂阳县'
    }, {
      'code': '002033010022',
      'text': '宜章县'
    }, {
      'code': '002033010023',
      'text': '永兴县'
    }, {
      'code': '002033010024',
      'text': '嘉禾县'
    }, {
      'code': '002033010025',
      'text': '临武县'
    }, {
      'code': '002033010026',
      'text': '汝城县'
    }, {
      'code': '002033010027',
      'text': '桂东县'
    }, {
      'code': '002033010028',
      'text': '安仁县'
    }, {
      'code': '002033010081',
      'text': '资兴市'
    }, {
      'code': '002033010082',
      'text': '苏仙区'
    }],
    'text': '郴州市'
  }, {
    'code': '002033011',
    'children': [{
      'code': '002033011003',
      'text': '冷水滩区'
    }, {
      'code': '002033011021',
      'text': '祁阳县'
    }, {
      'code': '002033011022',
      'text': '东安县'
    }, {
      'code': '002033011023',
      'text': '双牌县'
    }, {
      'code': '002033011024',
      'text': '道县'
    }, {
      'code': '002033011025',
      'text': '江永县'
    }, {
      'code': '002033011026',
      'text': '宁远县'
    }, {
      'code': '002033011027',
      'text': '蓝山县'
    }, {
      'code': '002033011028',
      'text': '新田县'
    }, {
      'code': '002033011029',
      'text': '江华瑶族自治县'
    }, {
      'code': '002033011099',
      'text': '零陵区'
    }],
    'text': '永州市'
  }, {
    'code': '002033012',
    'children': [{
      'code': '002033012002',
      'text': '鹤城区'
    }, {
      'code': '002033012021',
      'text': '中方县'
    }, {
      'code': '002033012022',
      'text': '沅陵县'
    }, {
      'code': '002033012023',
      'text': '辰溪县'
    }, {
      'code': '002033012024',
      'text': '溆浦县'
    }, {
      'code': '002033012025',
      'text': '会同县'
    }, {
      'code': '002033012026',
      'text': '麻阳苗族自治县'
    }, {
      'code': '002033012027',
      'text': '新晃侗族自治县'
    }, {
      'code': '002033012028',
      'text': '芷江侗族自治县'
    }, {
      'code': '002033012029',
      'text': '靖州苗族侗族自治县'
    }, {
      'code': '002033012030',
      'text': '通道侗族自治县'
    }, {
      'code': '002033012081',
      'text': '洪江市'
    }],
    'text': '怀化市'
  }, {
    'code': '002033025',
    'children': [{
      'code': '002033025002',
      'text': '冷水江市'
    }, {
      'code': '002033025003',
      'text': '涟源市'
    }, {
      'code': '002033025022',
      'text': '双峰县'
    }, {
      'code': '002033025024',
      'text': '新化县'
    }, {
      'code': '002033025099',
      'text': '娄星区'
    }],
    'text': '娄底市'
  }, {
    'code': '002033031',
    'children': [{
      'code': '002033031001',
      'text': '吉首市'
    }, {
      'code': '002033031022',
      'text': '泸溪县'
    }, {
      'code': '002033031023',
      'text': '凤凰县'
    }, {
      'code': '002033031024',
      'text': '花垣县'
    }, {
      'code': '002033031025',
      'text': '保靖县'
    }, {
      'code': '002033031026',
      'text': '古丈县'
    }, {
      'code': '002033031027',
      'text': '永顺县'
    }],
    'text': '吉首市'
  }, {
    'code': '002033032',
    'children': [{
      'code': '002033032001',
      'text': '龙山县'
    }, {
      'code': '002033032002',
      'text': '保靖县'
    }, {
      'code': '002033032003',
      'text': '古丈县'
    }, {
      'code': '002033032004',
      'text': '泸溪县'
    }, {
      'code': '002033032005',
      'text': '凤凰县'
    }, {
      'code': '002033032006',
      'text': '花垣县'
    }, {
      'code': '002033032007',
      'text': '吉首市'
    }, {
      'code': '002033032008',
      'text': '永顺县'
    }],
    'text': '湘西州'
  }],
  'text': '湖南'
}, {
  'code': '002034',
  'children': [{
    'code': '002034001',
    'children': [{
      'code': '002034001002',
      'text': '南沙区'
    }, {
      'code': '002034001003',
      'text': '荔湾区'
    }, {
      'code': '002034001004',
      'text': '越秀区'
    }, {
      'code': '002034001005',
      'text': '海珠区'
    }, {
      'code': '002034001006',
      'text': '天河区'
    }, {
      'code': '002034001007',
      'text': '萝岗区'
    }, {
      'code': '002034001011',
      'text': '白云区'
    }, {
      'code': '002034001012',
      'text': '黄埔区'
    }, {
      'code': '002034001013',
      'text': '番禺区'
    }, {
      'code': '002034001014',
      'text': '花都区'
    }, {
      'code': '002034001020',
      'text': '广州大学城'
    }, {
      'code': '002034001083',
      'text': '增城市'
    }, {
      'code': '002034001084',
      'text': '从化市'
    }],
    'text': '广州市'
  }, {
    'code': '002034002',
    'children': [{
      'code': '002034002002',
      'text': '北江区'
    }, {
      'code': '002034002003',
      'text': '武江区'
    }, {
      'code': '002034002004',
      'text': '浈江区'
    }, {
      'code': '002034002021',
      'text': '曲江县'
    }, {
      'code': '002034002022',
      'text': '始兴县'
    }, {
      'code': '002034002024',
      'text': '仁化县'
    }, {
      'code': '002034002029',
      'text': '翁源县'
    }, {
      'code': '002034002032',
      'text': '乳源瑶族自治县'
    }, {
      'code': '002034002033',
      'text': '新丰县'
    }, {
      'code': '002034002081',
      'text': '乐昌市'
    }, {
      'code': '002034002082',
      'text': '南雄市'
    }],
    'text': '韶关市'
  }, {
    'code': '002034003',
    'children': [{
      'code': '002034003003',
      'text': '罗湖区'
    }, {
      'code': '002034003004',
      'text': '福田区'
    }, {
      'code': '002034003005',
      'text': '南山区'
    }, {
      'code': '002034003006',
      'text': '宝安区'
    }, {
      'code': '002034003007',
      'text': '龙岗区'
    }, {
      'code': '002034003008',
      'text': '盐田区'
    }, {
      'code': '002034003010',
      'text': '坪山新区'
    }, {
      'code': '002034003011',
      'text': '龙华新区'
    }, {
      'code': '002034003012',
      'text': '大鹏新区'
    }, {
      'code': '002034003013',
      'text': '光明新区'
    }],
    'text': '深圳市'
  }, {
    'code': '002034004',
    'children': [{
      'code': '002034004002',
      'text': '香洲区'
    }, {
      'code': '002034004003',
      'text': '斗门区'
    }, {
      'code': '002034004004',
      'text': '金湾区'
    }],
    'text': '珠海市'
  }, {
    'code': '002034005',
    'children': [{
      'code': '002034005007',
      'text': '龙湖区'
    }, {
      'code': '002034005011',
      'text': '金平区'
    }, {
      'code': '002034005012',
      'text': '濠江区'
    }, {
      'code': '002034005013',
      'text': '潮阳区'
    }, {
      'code': '002034005014',
      'text': '潮南区'
    }, {
      'code': '002034005015',
      'text': '澄海区'
    }, {
      'code': '002034005023',
      'text': '南澳县'
    }],
    'text': '汕头市'
  }, {
    'code': '002034006',
    'children': [{
      'code': '002034006004',
      'text': '禅城区'
    }, {
      'code': '002034006005',
      'text': '南海区'
    }, {
      'code': '002034006006',
      'text': '顺德区'
    }, {
      'code': '002034006007',
      'text': '三水区'
    }, {
      'code': '002034006008',
      'text': '高明区'
    }],
    'text': '佛山市'
  }, {
    'code': '002034007',
    'children': [{
      'code': '002034007003',
      'text': '蓬江区'
    }, {
      'code': '002034007004',
      'text': '江海区'
    }, {
      'code': '002034007005',
      'text': '新会区'
    }, {
      'code': '002034007081',
      'text': '台山市'
    }, {
      'code': '002034007083',
      'text': '开平市'
    }, {
      'code': '002034007084',
      'text': '鹤山市'
    }, {
      'code': '002034007085',
      'text': '恩平市'
    }],
    'text': '江门市'
  }, {
    'code': '002034008',
    'children': [{
      'code': '002034008002',
      'text': '赤坎区'
    }, {
      'code': '002034008003',
      'text': '霞山区'
    }, {
      'code': '002034008004',
      'text': '坡头区'
    }, {
      'code': '002034008011',
      'text': '麻章区'
    }, {
      'code': '002034008012',
      'text': '经济技术开发区'
    }, {
      'code': '002034008023',
      'text': '遂溪县'
    }, {
      'code': '002034008025',
      'text': '徐闻县'
    }, {
      'code': '002034008081',
      'text': '廉江市'
    }, {
      'code': '002034008082',
      'text': '雷州市'
    }, {
      'code': '002034008083',
      'text': '吴川市'
    }],
    'text': '湛江市'
  }, {
    'code': '002034009',
    'children': [{
      'code': '002034009002',
      'text': '茂南区'
    }, {
      'code': '002034009003',
      'text': '茂港区'
    }, {
      'code': '002034009023',
      'text': '电白县'
    }, {
      'code': '002034009081',
      'text': '高州市'
    }, {
      'code': '002034009082',
      'text': '化州市'
    }, {
      'code': '002034009083',
      'text': '信宜市'
    }],
    'text': '茂名市'
  }, {
    'code': '002034012',
    'children': [{
      'code': '002034012002',
      'text': '端州区'
    }, {
      'code': '002034012003',
      'text': '鼎湖区'
    }, {
      'code': '002034012023',
      'text': '广宁县'
    }, {
      'code': '002034012024',
      'text': '怀集县'
    }, {
      'code': '002034012025',
      'text': '封开县'
    }, {
      'code': '002034012026',
      'text': '德庆县'
    }, {
      'code': '002034012083',
      'text': '高要市'
    }, {
      'code': '002034012084',
      'text': '四会市'
    }, {
      'code': '002034012085',
      'text': '高新技术产业开发区'
    }],
    'text': '肇庆市'
  }, {
    'code': '002034013',
    'children': [{
      'code': '002034013002',
      'text': '惠城区'
    }, {
      'code': '002034013003',
      'text': '惠阳区'
    }, {
      'code': '002034013010',
      'text': '大亚湾区'
    }, {
      'code': '002034013022',
      'text': '博罗县'
    }, {
      'code': '002034013023',
      'text': '惠东县'
    }, {
      'code': '002034013024',
      'text': '龙门县'
    }, {
      'code': '002034013025',
      'text': '仲恺高新区'
    }],
    'text': '惠州市'
  }, {
    'code': '002034014',
    'children': [{
      'code': '002034014002',
      'text': '梅江区'
    }, {
      'code': '002034014021',
      'text': '梅县'
    }, {
      'code': '002034014022',
      'text': '大埔县'
    }, {
      'code': '002034014023',
      'text': '丰顺县'
    }, {
      'code': '002034014024',
      'text': '五华县'
    }, {
      'code': '002034014026',
      'text': '平远县'
    }, {
      'code': '002034014027',
      'text': '蕉岭县'
    }, {
      'code': '002034014081',
      'text': '兴宁市'
    }],
    'text': '梅州市'
  }, {
    'code': '002034015',
    'children': [{
      'code': '002034015002',
      'text': '城区'
    }, {
      'code': '002034015021',
      'text': '海丰县'
    }, {
      'code': '002034015023',
      'text': '陆河县'
    }, {
      'code': '002034015081',
      'text': '陆丰市'
    }],
    'text': '汕尾市'
  }, {
    'code': '002034016',
    'children': [{
      'code': '002034016002',
      'text': '源城区'
    }, {
      'code': '002034016021',
      'text': '紫金县'
    }, {
      'code': '002034016022',
      'text': '龙川县'
    }, {
      'code': '002034016023',
      'text': '连平县'
    }, {
      'code': '002034016024',
      'text': '和平县'
    }, {
      'code': '002034016025',
      'text': '东源县'
    }],
    'text': '河源市'
  }, {
    'code': '002034017',
    'children': [{
      'code': '002034017002',
      'text': '江城区'
    }, {
      'code': '002034017021',
      'text': '阳西县'
    }, {
      'code': '002034017023',
      'text': '阳东县'
    }, {
      'code': '002034017081',
      'text': '阳春市'
    }],
    'text': '阳江市'
  }, {
    'code': '002034018',
    'children': [{
      'code': '002034018002',
      'text': '清城区'
    }, {
      'code': '002034018010',
      'text': '连山县'
    }, {
      'code': '002034018011',
      'text': '连南县'
    }, {
      'code': '002034018021',
      'text': '佛冈县'
    }, {
      'code': '002034018023',
      'text': '阳山县'
    }, {
      'code': '002034018025',
      'text': '连山壮族瑶族自治县'
    }, {
      'code': '002034018026',
      'text': '连山瑶族自治县'
    }, {
      'code': '002034018027',
      'text': '清新县'
    }, {
      'code': '002034018081',
      'text': '英德市'
    }, {
      'code': '002034018082',
      'text': '连州市'
    }],
    'text': '清远市'
  }, {
    'code': '002034019',
    'children': [{
      'code': '002034019001',
      'text': '东莞市'
    }, {
      'code': '002034019010',
      'text': '莞城区'
    }, {
      'code': '002034019011',
      'text': '南城区'
    }, {
      'code': '002034019012',
      'text': '横沥镇'
    }, {
      'code': '002034019013',
      'text': '厚街镇'
    }, {
      'code': '002034019014',
      'text': '万江区'
    }, {
      'code': '002034019015',
      'text': '凤岗镇'
    }, {
      'code': '002034019016',
      'text': '大朗镇'
    }, {
      'code': '002034019017',
      'text': '企石镇'
    }, {
      'code': '002034019018',
      'text': '茶山镇'
    }, {
      'code': '002034019019',
      'text': '虎门镇'
    }, {
      'code': '002034019020',
      'text': '道滘镇'
    }, {
      'code': '002034019021',
      'text': '沙田镇'
    }, {
      'code': '002034019022',
      'text': '石碣镇'
    }, {
      'code': '002034019023',
      'text': '洪梅镇'
    }, {
      'code': '002034019024',
      'text': '松山湖'
    }, {
      'code': '002034019025',
      'text': '中堂镇'
    }, {
      'code': '002034019026',
      'text': '谢岗镇'
    }, {
      'code': '002034019027',
      'text': '寮步镇'
    }, {
      'code': '002034019028',
      'text': '常平镇'
    }, {
      'code': '002034019029',
      'text': '塘厦镇'
    }, {
      'code': '002034019030',
      'text': '东城区'
    }, {
      'code': '002034019031',
      'text': '石排镇'
    }, {
      'code': '002034019032',
      'text': '石龙镇'
    }, {
      'code': '002034019033',
      'text': '望牛墩镇'
    }, {
      'code': '002034019034',
      'text': '长安镇'
    }, {
      'code': '002034019035',
      'text': '大岭山镇'
    }, {
      'code': '002034019036',
      'text': '樟木头镇'
    }, {
      'code': '002034019037',
      'text': '清溪镇'
    }, {
      'code': '002034019038',
      'text': '黄江镇'
    }, {
      'code': '002034019039',
      'text': '高埗镇'
    }, {
      'code': '002034019040',
      'text': '桥头镇'
    }, {
      'code': '002034019041',
      'text': '东坑镇'
    }, {
      'code': '002034019042',
      'text': '麻涌镇'
    }],
    'text': '东莞市'
  }, {
    'code': '002034020',
    'children': [{
      'code': '002034020001',
      'text': '中山市'
    }, {
      'code': '002034020010',
      'text': '小榄镇'
    }, {
      'code': '002034020011',
      'text': '古镇'
    }, {
      'code': '002034020012',
      'text': '东凤镇'
    }, {
      'code': '002034020013',
      'text': '板芙镇'
    }, {
      'code': '002034020014',
      'text': '横栏镇'
    }, {
      'code': '002034020015',
      'text': '三角镇'
    }, {
      'code': '002034020016',
      'text': '南头镇'
    }, {
      'code': '002034020017',
      'text': '黄圃镇'
    }, {
      'code': '002034020018',
      'text': '五桂山镇'
    }, {
      'code': '002034020019',
      'text': '南朗镇'
    }, {
      'code': '002034020020',
      'text': '沙朗镇'
    }, {
      'code': '002034020021',
      'text': '东升镇'
    }, {
      'code': '002034020022',
      'text': '火炬开发区'
    }, {
      'code': '002034020023',
      'text': '民众镇'
    }, {
      'code': '002034020024',
      'text': '大涌镇'
    }, {
      'code': '002034020025',
      'text': '坦洲镇'
    }, {
      'code': '002034020026',
      'text': '三乡镇'
    }, {
      'code': '002034020027',
      'text': '港口镇'
    }, {
      'code': '002034020028',
      'text': '沙溪镇'
    }, {
      'code': '002034020029',
      'text': '阜沙镇'
    }, {
      'code': '002034020030',
      'text': '神湾镇'
    }, {
      'code': '002034020031',
      'text': '石岐区街道'
    }, {
      'code': '002034020032',
      'text': '东区街道'
    }, {
      'code': '002034020033',
      'text': '西区街道'
    }, {
      'code': '002034020034',
      'text': '南区街道'
    }],
    'text': '中山市'
  }, {
    'code': '002034051',
    'children': [{
      'code': '002034051002',
      'text': '湘桥区'
    }, {
      'code': '002034051010',
      'text': '枫溪区'
    }, {
      'code': '002034051021',
      'text': '潮安县'
    }, {
      'code': '002034051022',
      'text': '饶平县'
    }],
    'text': '潮州市'
  }, {
    'code': '002034052',
    'children': [{
      'code': '002034052002',
      'text': '榕城区'
    }, {
      'code': '002034052010',
      'text': '东山区'
    }, {
      'code': '002034052021',
      'text': '揭东县'
    }, {
      'code': '002034052022',
      'text': '揭西县'
    }, {
      'code': '002034052024',
      'text': '惠来县'
    }, {
      'code': '002034052081',
      'text': '普宁市'
    }, {
      'code': '002034052082',
      'text': '普侨区'
    }, {
      'code': '002034052083',
      'text': '大南山侨区'
    }],
    'text': '揭阳市'
  }, {
    'code': '002034053',
    'children': [{
      'code': '002034053002',
      'text': '云城区'
    }, {
      'code': '002034053021',
      'text': '新兴县'
    }, {
      'code': '002034053022',
      'text': '郁南县'
    }, {
      'code': '002034053023',
      'text': '云安县'
    }, {
      'code': '002034053081',
      'text': '罗定市'
    }],
    'text': '云浮市'
  }],
  'text': '广东'
}, {
  'code': '002035',
  'children': [{
    'code': '002035001',
    'children': [{
      'code': '002035001002',
      'text': '兴宁区'
    }, {
      'code': '002035001005',
      'text': '江南区'
    }, {
      'code': '002035001021',
      'text': '邕宁区'
    }, {
      'code': '002035001022',
      'text': '武鸣县'
    }, {
      'code': '002035001023',
      'text': '宾阳县'
    }, {
      'code': '002035001024',
      'text': '隆安县'
    }, {
      'code': '002035001025',
      'text': '马山县'
    }, {
      'code': '002035001027',
      'text': '上林县'
    }, {
      'code': '002035001033',
      'text': '横县'
    }, {
      'code': '002035001097',
      'text': '西乡塘区'
    }, {
      'code': '002035001098',
      'text': '青秀区'
    }, {
      'code': '002035001099',
      'text': '良庆区'
    }],
    'text': '南宁市'
  }, {
    'code': '002035002',
    'children': [{
      'code': '002035002002',
      'text': '城中区'
    }, {
      'code': '002035002003',
      'text': '鱼峰区'
    }, {
      'code': '002035002004',
      'text': '柳南区'
    }, {
      'code': '002035002005',
      'text': '柳北区'
    }, {
      'code': '002035002011',
      'text': '市郊区'
    }, {
      'code': '002035002021',
      'text': '柳江县'
    }, {
      'code': '002035002022',
      'text': '柳城县'
    }, {
      'code': '002035002023',
      'text': '鹿寨县'
    }, {
      'code': '002035002024',
      'text': '融安县'
    }, {
      'code': '002035002025',
      'text': '融水苗族自治县'
    }, {
      'code': '002035002026',
      'text': '三江侗族自治县'
    }],
    'text': '柳州市'
  }, {
    'code': '002035003',
    'children': [{
      'code': '002035003002',
      'text': '秀峰区'
    }, {
      'code': '002035003003',
      'text': '叠彩区'
    }, {
      'code': '002035003004',
      'text': '象山区'
    }, {
      'code': '002035003005',
      'text': '七星区'
    }, {
      'code': '002035003011',
      'text': '雁山区'
    }, {
      'code': '002035003021',
      'text': '阳朔县'
    }, {
      'code': '002035003022',
      'text': '临桂县'
    }, {
      'code': '002035003023',
      'text': '灵川县'
    }, {
      'code': '002035003024',
      'text': '全州县'
    }, {
      'code': '002035003025',
      'text': '兴安县'
    }, {
      'code': '002035003026',
      'text': '永福县'
    }, {
      'code': '002035003027',
      'text': '灌阳县'
    }, {
      'code': '002035003028',
      'text': '龙胜各族自治县'
    }, {
      'code': '002035003029',
      'text': '资源县'
    }, {
      'code': '002035003030',
      'text': '平乐县'
    }, {
      'code': '002035003031',
      'text': '荔浦县'
    }, {
      'code': '002035003032',
      'text': '恭城瑶族自治县'
    }],
    'text': '桂林市'
  }, {
    'code': '002035004',
    'children': [{
      'code': '002035004003',
      'text': '万秀区'
    }, {
      'code': '002035004004',
      'text': '蝶山区'
    }, {
      'code': '002035004011',
      'text': '市郊区'
    }, {
      'code': '002035004012',
      'text': '龙圩区'
    }, {
      'code': '002035004013',
      'text': '长洲区'
    }, {
      'code': '002035004021',
      'text': '苍梧县'
    }, {
      'code': '002035004022',
      'text': '藤县'
    }, {
      'code': '002035004023',
      'text': '蒙山县'
    }, {
      'code': '002035004081',
      'text': '岑溪市'
    }],
    'text': '梧州市'
  }, {
    'code': '002035005',
    'children': [{
      'code': '002035005002',
      'text': '海城区'
    }, {
      'code': '002035005003',
      'text': '银海区'
    }, {
      'code': '002035005012',
      'text': '铁山港区'
    }, {
      'code': '002035005021',
      'text': '合浦县'
    }],
    'text': '北海市'
  }, {
    'code': '002035006',
    'children': [{
      'code': '002035006002',
      'text': '港口区'
    }, {
      'code': '002035006003',
      'text': '防城区'
    }, {
      'code': '002035006021',
      'text': '上思县'
    }, {
      'code': '002035006081',
      'text': '东兴市'
    }],
    'text': '防城港市'
  }, {
    'code': '002035007',
    'children': [{
      'code': '002035007002',
      'text': '钦南区'
    }, {
      'code': '002035007003',
      'text': '钦北区'
    }, {
      'code': '002035007021',
      'text': '灵山县'
    }, {
      'code': '002035007022',
      'text': '浦北县'
    }, {
      'code': '002035007023',
      'text': '钦州港经济开发区'
    }],
    'text': '钦州市'
  }, {
    'code': '002035008',
    'children': [{
      'code': '002035008002',
      'text': '港北区'
    }, {
      'code': '002035008003',
      'text': '港南区'
    }, {
      'code': '002035008021',
      'text': '平南县'
    }, {
      'code': '002035008098',
      'text': '覃塘区'
    }, {
      'code': '002035008099',
      'text': '桂平市'
    }],
    'text': '贵港市'
  }, {
    'code': '002035009',
    'children': [{
      'code': '002035009002',
      'text': '玉州区'
    }, {
      'code': '002035009021',
      'text': '容县'
    }, {
      'code': '002035009022',
      'text': '陆川县'
    }, {
      'code': '002035009023',
      'text': '博白县'
    }, {
      'code': '002035009024',
      'text': '兴业县'
    }, {
      'code': '002035009081',
      'text': '北流市'
    }, {
      'code': '002035009099',
      'text': '福绵区'
    }],
    'text': '玉林市'
  }, {
    'code': '002035024',
    'children': [{
      'code': '002035024002',
      'text': '贺州市'
    }, {
      'code': '002035024010',
      'text': '平桂管理区'
    }, {
      'code': '002035024024',
      'text': '昭平县'
    }, {
      'code': '002035024027',
      'text': '钟山县'
    }, {
      'code': '002035024028',
      'text': '富川瑶族自治县'
    }, {
      'code': '002035024099',
      'text': '八步区'
    }],
    'text': '贺州市'
  }, {
    'code': '002035026',
    'children': [{
      'code': '002035026001',
      'text': '百色市'
    }, {
      'code': '002035026022',
      'text': '田阳县'
    }, {
      'code': '002035026023',
      'text': '田东县'
    }, {
      'code': '002035026024',
      'text': '平果县'
    }, {
      'code': '002035026025',
      'text': '德保县'
    }, {
      'code': '002035026026',
      'text': '靖西县'
    }, {
      'code': '002035026027',
      'text': '那坡县'
    }, {
      'code': '002035026028',
      'text': '凌云县'
    }, {
      'code': '002035026029',
      'text': '乐业县'
    }, {
      'code': '002035026030',
      'text': '田林县'
    }, {
      'code': '002035026031',
      'text': '隆林各族自治县'
    }, {
      'code': '002035026032',
      'text': '西林县'
    }, {
      'code': '002035026099',
      'text': '右江区'
    }],
    'text': '百色市'
  }, {
    'code': '002035027',
    'children': [{
      'code': '002035027001',
      'text': '河池市'
    }, {
      'code': '002035027002',
      'text': '宜州市'
    }, {
      'code': '002035027023',
      'text': '罗城仫佬族自治县'
    }, {
      'code': '002035027024',
      'text': '环江毛南族自治县'
    }, {
      'code': '002035027025',
      'text': '南丹县'
    }, {
      'code': '002035027026',
      'text': '天峨县'
    }, {
      'code': '002035027027',
      'text': '凤山县'
    }, {
      'code': '002035027028',
      'text': '东兰县'
    }, {
      'code': '002035027029',
      'text': '巴马瑶族自治县'
    }, {
      'code': '002035027030',
      'text': '都安瑶族自治县'
    }, {
      'code': '002035027031',
      'text': '大化瑶族自治县'
    }, {
      'code': '002035027099',
      'text': '金城江区'
    }],
    'text': '河池市'
  }, {
    'code': '002035028',
    'children': [{
      'code': '002035028001',
      'text': '象州县'
    }, {
      'code': '002035028002',
      'text': '武宣县'
    }, {
      'code': '002035028003',
      'text': '忻城县'
    }, {
      'code': '002035028004',
      'text': '金秀瑶族自治县'
    }, {
      'code': '002035028005',
      'text': '合山市'
    }, {
      'code': '002035028006',
      'text': '来宾市'
    }, {
      'code': '002035028010',
      'text': '兴宾区'
    }],
    'text': '来宾市'
  }, {
    'code': '002035029',
    'children': [{
      'code': '002035029001',
      'text': '扶绥县'
    }, {
      'code': '002035029002',
      'text': '凭祥市'
    }, {
      'code': '002035029003',
      'text': '大新县'
    }, {
      'code': '002035029004',
      'text': '龙州县'
    }, {
      'code': '002035029005',
      'text': '宁明县'
    }, {
      'code': '002035029006',
      'text': '天等县'
    }, {
      'code': '002035029007',
      'text': '江州区'
    }],
    'text': '崇左市'
  }],
  'text': '广西'
}, {
  'code': '002036',
  'children': [{
    'code': '002036001',
    'children': [{
      'code': '002036001005',
      'text': '秀英区'
    }, {
      'code': '002036001006',
      'text': '龙华区'
    }, {
      'code': '002036001007',
      'text': '琼山区'
    }, {
      'code': '002036001008',
      'text': '美兰区'
    }],
    'text': '海口市'
  }, {
    'code': '002036002',
    'children': [{
      'code': '002036002001',
      'text': '三亚市'
    }, {
      'code': '002036002010',
      'text': '崖城镇'
    }, {
      'code': '002036002011',
      'text': '吉阳镇'
    }, {
      'code': '002036002012',
      'text': '凤凰镇'
    }, {
      'code': '002036002013',
      'text': '育才镇'
    }, {
      'code': '002036002014',
      'text': '河西区'
    }, {
      'code': '002036002015',
      'text': '河东区'
    }, {
      'code': '002036002016',
      'text': '立才农场'
    }, {
      'code': '002036002017',
      'text': '南岛农场'
    }, {
      'code': '002036002018',
      'text': '南滨农场'
    }, {
      'code': '002036002019',
      'text': '海棠湾镇'
    }, {
      'code': '002036002020',
      'text': '天涯镇'
    }, {
      'code': '002036002021',
      'text': '南田农场'
    }, {
      'code': '002036002022',
      'text': '南新农场'
    }, {
      'code': '002036002023',
      'text': '田独镇'
    }, {
      'code': '002036002024',
      'text': '崖州区'
    }],
    'text': '三亚市'
  }, {
    'code': '002036003',
    'children': [{
      'code': '002036003001',
      'text': '琼海市'
    }, {
      'code': '002036003010',
      'text': '万泉镇'
    }, {
      'code': '002036003011',
      'text': '东红农场'
    }, {
      'code': '002036003012',
      'text': '阳江镇'
    }, {
      'code': '002036003013',
      'text': '龙江镇'
    }, {
      'code': '002036003014',
      'text': '塔洋镇'
    }, {
      'code': '002036003015',
      'text': '长坡镇'
    }, {
      'code': '002036003016',
      'text': '大路镇'
    }, {
      'code': '002036003017',
      'text': '彬村山华侨农场'
    }, {
      'code': '002036003018',
      'text': '东太农场'
    }, {
      'code': '002036003019',
      'text': '东升农场'
    }, {
      'code': '002036003020',
      'text': '南俸农场'
    }, {
      'code': '002036003021',
      'text': '嘉积镇'
    }, {
      'code': '002036003022',
      'text': '中原镇'
    }, {
      'code': '002036003023',
      'text': '石壁镇'
    }, {
      'code': '002036003024',
      'text': '潭门镇'
    }, {
      'code': '002036003025',
      'text': '会山镇'
    }, {
      'code': '002036003026',
      'text': '博鳌镇'
    }],
    'text': '琼海市'
  }, {
    'code': '002036004',
    'children': [{
      'code': '002036004001',
      'text': '万宁市'
    }, {
      'code': '002036004010',
      'text': '兴隆镇'
    }, {
      'code': '002036004011',
      'text': '兴隆华侨农场'
    }, {
      'code': '002036004012',
      'text': '龙滚镇'
    }, {
      'code': '002036004013',
      'text': '后安镇'
    }, {
      'code': '002036004014',
      'text': '大茂镇'
    }, {
      'code': '002036004015',
      'text': '东澳镇'
    }, {
      'code': '002036004016',
      'text': '长丰镇'
    }, {
      'code': '002036004017',
      'text': '山根镇'
    }, {
      'code': '002036004018',
      'text': '南桥镇'
    }, {
      'code': '002036004019',
      'text': '三更罗镇'
    }, {
      'code': '002036004020',
      'text': '六连林场'
    }, {
      'code': '002036004021',
      'text': '东和农场'
    }, {
      'code': '002036004022',
      'text': '新中农场'
    }, {
      'code': '002036004023',
      'text': '南林农场'
    }, {
      'code': '002036004024',
      'text': '和乐镇'
    }, {
      'code': '002036004025',
      'text': '礼纪镇'
    }, {
      'code': '002036004026',
      'text': '北大镇'
    }, {
      'code': '002036004027',
      'text': '东兴农场'
    }, {
      'code': '002036004028',
      'text': '万城镇'
    }, {
      'code': '002036004029',
      'text': '东岭农场'
    }],
    'text': '万宁市'
  }, {
    'code': '002036005',
    'children': [{
      'code': '002036005001',
      'text': '儋州市'
    }, {
      'code': '002036005010',
      'text': '热作学院'
    }, {
      'code': '002036005011',
      'text': '西流农场'
    }, {
      'code': '002036005012',
      'text': '南丰镇'
    }, {
      'code': '002036005013',
      'text': '大成镇'
    }, {
      'code': '002036005014',
      'text': '光村镇'
    }, {
      'code': '002036005015',
      'text': '木棠镇'
    }, {
      'code': '002036005016',
      'text': '三都镇'
    }, {
      'code': '002036005017',
      'text': '白马井镇'
    }, {
      'code': '002036005018',
      'text': '中和镇'
    }, {
      'code': '002036005019',
      'text': '新州镇'
    }, {
      'code': '002036005020',
      'text': '洋浦经济开发区'
    }, {
      'code': '002036005021',
      'text': '蓝洋农场'
    }, {
      'code': '002036005022',
      'text': '八一农场'
    }, {
      'code': '002036005023',
      'text': '西庆农场'
    }, {
      'code': '002036005024',
      'text': '龙山农场'
    }, {
      'code': '002036005025',
      'text': '红岭农场'
    }, {
      'code': '002036005026',
      'text': '和庆镇'
    }, {
      'code': '002036005027',
      'text': '雅星镇'
    }, {
      'code': '002036005028',
      'text': '海头镇'
    }, {
      'code': '002036005029',
      'text': '王五镇'
    }, {
      'code': '002036005030',
      'text': '东成镇'
    }, {
      'code': '002036005031',
      'text': '西培农场'
    }, {
      'code': '002036005032',
      'text': '西华农场'
    }, {
      'code': '002036005033',
      'text': '新盈农场'
    }, {
      'code': '002036005034',
      'text': '富克镇'
    }, {
      'code': '002036005035',
      'text': '兰洋镇'
    }, {
      'code': '002036005036',
      'text': '峨蔓镇'
    }, {
      'code': '002036005037',
      'text': '排浦镇'
    }, {
      'code': '002036005038',
      'text': '西联农场'
    }, {
      'code': '002036005039',
      'text': '那大镇'
    }],
    'text': '儋州市'
  }, {
    'code': '002036006',
    'children': [{
      'code': '002036006001',
      'text': '东方市'
    }, {
      'code': '002036006010',
      'text': '八所镇'
    }, {
      'code': '002036006011',
      'text': '东河镇'
    }, {
      'code': '002036006012',
      'text': '大田镇'
    }, {
      'code': '002036006013',
      'text': '板桥镇'
    }, {
      'code': '002036006014',
      'text': '三家镇'
    }, {
      'code': '002036006015',
      'text': '江边乡'
    }, {
      'code': '002036006016',
      'text': '天安乡'
    }, {
      'code': '002036006017',
      'text': '广坝农场'
    }, {
      'code': '002036006018',
      'text': '东方华侨农场'
    }, {
      'code': '002036006019',
      'text': '感城镇'
    }, {
      'code': '002036006020',
      'text': '四更镇'
    }, {
      'code': '002036006021',
      'text': '新龙镇'
    }],
    'text': '东方市'
  }, {
    'code': '002036007',
    'children': [{
      'code': '002036007001',
      'text': '文昌市'
    }, {
      'code': '002036007010',
      'text': '文城镇'
    }, {
      'code': '002036007011',
      'text': '蓬莱镇'
    }, {
      'code': '002036007012',
      'text': '潭牛镇'
    }, {
      'code': '002036007013',
      'text': '东阁镇'
    }, {
      'code': '002036007014',
      'text': '龙楼镇'
    }, {
      'code': '002036007015',
      'text': '昌洒镇'
    }, {
      'code': '002036007016',
      'text': '抱罗镇'
    }, {
      'code': '002036007017',
      'text': '锦山镇'
    }, {
      'code': '002036007018',
      'text': '公坡镇'
    }, {
      'code': '002036007019',
      'text': '南阳镇'
    }, {
      'code': '002036007020',
      'text': '新桥镇'
    }, {
      'code': '002036007021',
      'text': '宝芳乡'
    }, {
      'code': '002036007022',
      'text': '湖山乡'
    }, {
      'code': '002036007023',
      'text': '南阳农场'
    }, {
      'code': '002036007024',
      'text': '橡胶研究所'
    }, {
      'code': '002036007025',
      'text': '重兴镇'
    }, {
      'code': '002036007026',
      'text': '东路镇'
    }, {
      'code': '002036007027',
      'text': '东郊镇'
    }, {
      'code': '002036007028',
      'text': '翁田镇'
    }, {
      'code': '002036007029',
      'text': '铺前镇'
    }, {
      'code': '002036007030',
      'text': '清澜镇'
    }, {
      'code': '002036007031',
      'text': '头苑镇'
    }, {
      'code': '002036007032',
      'text': '东路农场'
    }, {
      'code': '002036007033',
      'text': '会文镇'
    }, {
      'code': '002036007034',
      'text': '文教镇'
    }, {
      'code': '002036007035',
      'text': '冯坡镇'
    }, {
      'code': '002036007036',
      'text': '迈号镇'
    }, {
      'code': '002036007037',
      'text': '龙马乡'
    }, {
      'code': '002036007038',
      'text': '罗豆农场'
    }],
    'text': '文昌市'
  }, {
    'code': '002036008',
    'children': [{
      'code': '002036008001',
      'text': '五指山市'
    }, {
      'code': '002036008010',
      'text': '南圣镇'
    }, {
      'code': '002036008011',
      'text': '畅好乡'
    }, {
      'code': '002036008012',
      'text': '番阳镇'
    }, {
      'code': '002036008013',
      'text': '毛阳镇'
    }, {
      'code': '002036008014',
      'text': '畅好农场'
    }, {
      'code': '002036008015',
      'text': '通什镇'
    }, {
      'code': '002036008016',
      'text': '水满乡'
    }, {
      'code': '002036008017',
      'text': '毛道乡'
    }],
    'text': '五指山市'
  }, {
    'code': '002036009',
    'children': [{
      'code': '002036009001',
      'text': '定安县'
    }, {
      'code': '002036009010',
      'text': '龙河镇'
    }, {
      'code': '002036009011',
      'text': '富文镇'
    }, {
      'code': '002036009012',
      'text': '金鸡岭农场'
    }, {
      'code': '002036009013',
      'text': '新竹镇'
    }, {
      'code': '002036009014',
      'text': '岭口镇'
    }, {
      'code': '002036009015',
      'text': '定城镇'
    }, {
      'code': '002036009016',
      'text': '翰林镇'
    }, {
      'code': '002036009017',
      'text': '龙门镇'
    }, {
      'code': '002036009018',
      'text': '龙湖镇'
    }, {
      'code': '002036009019',
      'text': '城区'
    }, {
      'code': '002036009020',
      'text': '黄竹镇'
    }, {
      'code': '002036009021',
      'text': '南海农场'
    }, {
      'code': '002036009022',
      'text': '中瑞农场'
    }, {
      'code': '002036009023',
      'text': '雷鸣镇'
    }],
    'text': '定安县'
  }, {
    'code': '002036010',
    'children': [{
      'code': '002036010001',
      'text': '屯昌县'
    }, {
      'code': '002036010010',
      'text': '乌坡镇'
    }, {
      'code': '002036010011',
      'text': '县城内'
    }, {
      'code': '002036010012',
      'text': '屯城镇'
    }, {
      'code': '002036010013',
      'text': '枫木镇'
    }, {
      'code': '002036010014',
      'text': '南坤镇'
    }, {
      'code': '002036010015',
      'text': '坡心镇'
    }, {
      'code': '002036010016',
      'text': '南吕镇'
    }, {
      'code': '002036010017',
      'text': '中坤农场'
    }, {
      'code': '002036010018',
      'text': '西昌镇'
    }, {
      'code': '002036010019',
      'text': '新兴镇'
    }, {
      'code': '002036010020',
      'text': '中建农场'
    }, {
      'code': '002036010021',
      'text': '黄岭农场'
    }, {
      'code': '002036010022',
      'text': '广青农场'
    }],
    'text': '屯昌县'
  }, {
    'code': '002036011',
    'children': [{
      'code': '002036011001',
      'text': '澄迈县'
    }, {
      'code': '002036011010',
      'text': '城区'
    }, {
      'code': '002036011011',
      'text': '永发镇'
    }, {
      'code': '002036011012',
      'text': '仁兴镇'
    }, {
      'code': '002036011013',
      'text': '西达农场'
    }, {
      'code': '002036011014',
      'text': '文儒镇'
    }, {
      'code': '002036011015',
      'text': '瑞溪镇'
    }, {
      'code': '002036011016',
      'text': '桥头镇'
    }, {
      'code': '002036011017',
      'text': '金江镇'
    }, {
      'code': '002036011018',
      'text': '加乐镇'
    }, {
      'code': '002036011019',
      'text': '中兴镇'
    }, {
      'code': '002036011020',
      'text': '福山镇'
    }, {
      'code': '002036011021',
      'text': '大丰镇'
    }, {
      'code': '002036011022',
      'text': '红光农场'
    }, {
      'code': '002036011023',
      'text': '金安农场'
    }, {
      'code': '002036011024',
      'text': '老城镇'
    }],
    'text': '澄迈县'
  }, {
    'code': '002036012',
    'children': [{
      'code': '002036012001',
      'text': '临高县'
    }, {
      'code': '002036012010',
      'text': '东英镇'
    }, {
      'code': '002036012011',
      'text': '红华农场'
    }, {
      'code': '002036012012',
      'text': '加来镇'
    }, {
      'code': '002036012013',
      'text': '新盈镇'
    }, {
      'code': '002036012014',
      'text': '和舍镇'
    }, {
      'code': '002036012015',
      'text': '皇桐镇'
    }, {
      'code': '002036012016',
      'text': '多文镇'
    }, {
      'code': '002036012017',
      'text': '临城镇'
    }, {
      'code': '002036012018',
      'text': '调楼镇'
    }, {
      'code': '002036012019',
      'text': '南宝镇'
    }, {
      'code': '002036012020',
      'text': '博厚镇'
    }, {
      'code': '002036012021',
      'text': '城区'
    }, {
      'code': '002036012022',
      'text': '加来农场'
    }, {
      'code': '002036012023',
      'text': '波莲镇'
    }],
    'text': '临高县'
  }, {
    'code': '002036013',
    'children': [{
      'code': '002036013001',
      'text': '白沙黎族自治县'
    }, {
      'code': '002036013010',
      'text': '南开乡'
    }, {
      'code': '002036013011',
      'text': '青松乡'
    }, {
      'code': '002036013012',
      'text': '荣邦乡'
    }, {
      'code': '002036013013',
      'text': '白沙农场'
    }, {
      'code': '002036013014',
      'text': '龙江农场'
    }, {
      'code': '002036013015',
      'text': '邦溪农场'
    }, {
      'code': '002036013016',
      'text': '打安镇'
    }, {
      'code': '002036013017',
      'text': '元门乡'
    }, {
      'code': '002036013018',
      'text': '阜龙乡'
    }, {
      'code': '002036013019',
      'text': '金波乡'
    }, {
      'code': '002036013020',
      'text': '城区'
    }, {
      'code': '002036013021',
      'text': '牙叉镇'
    }, {
      'code': '002036013022',
      'text': '七坊镇'
    }, {
      'code': '002036013023',
      'text': '邦溪镇'
    }, {
      'code': '002036013024',
      'text': '细水乡'
    }, {
      'code': '002036013025',
      'text': '卫星农场'
    }],
    'text': '白沙黎族自治县'
  }, {
    'code': '002036014',
    'children': [{
      'code': '002036014001',
      'text': '昌江黎族自治县'
    }, {
      'code': '002036014010',
      'text': '城区'
    }, {
      'code': '002036014011',
      'text': '乌烈镇'
    }, {
      'code': '002036014012',
      'text': '石碌镇'
    }, {
      'code': '002036014013',
      'text': '十月田镇'
    }, {
      'code': '002036014014',
      'text': '昌化镇'
    }, {
      'code': '002036014015',
      'text': '霸王岭林场'
    }, {
      'code': '002036014016',
      'text': '海南矿业公司'
    }, {
      'code': '002036014017',
      'text': '红林农场'
    }, {
      'code': '002036014018',
      'text': '叉河镇'
    }, {
      'code': '002036014019',
      'text': '海尾镇'
    }, {
      'code': '002036014020',
      'text': '王下乡'
    }, {
      'code': '002036014021',
      'text': '七叉镇'
    }],
    'text': '昌江黎族自治县'
  }, {
    'code': '002036015',
    'children': [{
      'code': '002036015001',
      'text': '乐东黎族自治县'
    }, {
      'code': '002036015010',
      'text': '保国农场'
    }, {
      'code': '002036015011',
      'text': '尖峰镇'
    }, {
      'code': '002036015012',
      'text': '莺歌海盐场'
    }, {
      'code': '002036015013',
      'text': '千家镇'
    }, {
      'code': '002036015014',
      'text': '佛罗镇'
    }, {
      'code': '002036015015',
      'text': '山荣农场'
    }, {
      'code': '002036015016',
      'text': '抱由镇'
    }, {
      'code': '002036015017',
      'text': '大安镇'
    }, {
      'code': '002036015018',
      'text': '九所镇'
    }, {
      'code': '002036015019',
      'text': '黄流镇'
    }, {
      'code': '002036015020',
      'text': '莺歌海镇'
    }, {
      'code': '002036015021',
      'text': '尖峰岭林业公司'
    }, {
      'code': '002036015022',
      'text': '乐光农场'
    }, {
      'code': '002036015023',
      'text': '万冲镇'
    }, {
      'code': '002036015024',
      'text': '志仲镇'
    }, {
      'code': '002036015025',
      'text': '利国镇'
    }, {
      'code': '002036015026',
      'text': '城区'
    }],
    'text': '乐东黎族自治县'
  }, {
    'code': '002036016',
    'children': [{
      'code': '002036016001',
      'text': '陵水黎族自治县'
    }, {
      'code': '002036016010',
      'text': '南平镇'
    }, {
      'code': '002036016011',
      'text': '提蒙乡'
    }, {
      'code': '002036016012',
      'text': '椰林镇'
    }, {
      'code': '002036016013',
      'text': '三才镇'
    }, {
      'code': '002036016014',
      'text': '隆广镇'
    }, {
      'code': '002036016015',
      'text': '本号镇'
    }, {
      'code': '002036016016',
      'text': '黎安镇'
    }, {
      'code': '002036016017',
      'text': '群英乡'
    }, {
      'code': '002036016018',
      'text': '城区'
    }, {
      'code': '002036016019',
      'text': '东华镇'
    }, {
      'code': '002036016020',
      'text': '吊罗山林业公司'
    }, {
      'code': '002036016021',
      'text': '南平农场'
    }, {
      'code': '002036016022',
      'text': '光坡镇'
    }, {
      'code': '002036016023',
      'text': '英州镇'
    }, {
      'code': '002036016024',
      'text': '文罗镇'
    }, {
      'code': '002036016025',
      'text': '新村镇'
    }, {
      'code': '002036016026',
      'text': '岭门农场'
    }],
    'text': '陵水黎族自治县'
  }, {
    'code': '002036017',
    'children': [{
      'code': '002036017001',
      'text': '保亭黎族自治县'
    }, {
      'code': '002036017010',
      'text': '保城镇'
    }, {
      'code': '002036017011',
      'text': '南林乡'
    }, {
      'code': '002036017012',
      'text': '新政镇'
    }, {
      'code': '002036017013',
      'text': '新星农场'
    }, {
      'code': '002036017014',
      'text': '三道农场'
    }, {
      'code': '002036017015',
      'text': '加茂镇'
    }, {
      'code': '002036017016',
      'text': '六弓乡'
    }, {
      'code': '002036017017',
      'text': '毛感乡'
    }, {
      'code': '002036017018',
      'text': '保亭研究所'
    }, {
      'code': '002036017019',
      'text': '金江农场'
    }, {
      'code': '002036017020',
      'text': '什玲镇'
    }, {
      'code': '002036017021',
      'text': '响水镇'
    }, {
      'code': '002036017022',
      'text': '三道镇'
    }],
    'text': '保亭黎族自治县'
  }, {
    'code': '002036018',
    'children': [{
      'code': '002036018001',
      'text': '琼中黎族自治县'
    }, {
      'code': '002036018010',
      'text': '黎母山林业公司'
    }, {
      'code': '002036018011',
      'text': '什运乡'
    }, {
      'code': '002036018012',
      'text': '长征农场'
    }, {
      'code': '002036018013',
      'text': '湾岭镇'
    }, {
      'code': '002036018014',
      'text': '和平镇'
    }, {
      'code': '002036018015',
      'text': '红毛镇'
    }, {
      'code': '002036018016',
      'text': '上安乡'
    }, {
      'code': '002036018017',
      'text': '城区'
    }, {
      'code': '002036018018',
      'text': '吊罗山乡'
    }, {
      'code': '002036018019',
      'text': '阳江农场'
    }, {
      'code': '002036018020',
      'text': '加钗农场'
    }, {
      'code': '002036018021',
      'text': '营根镇'
    }, {
      'code': '002036018022',
      'text': '黎母山镇'
    }, {
      'code': '002036018023',
      'text': '长征镇'
    }, {
      'code': '002036018024',
      'text': '中平镇'
    }, {
      'code': '002036018025',
      'text': '乌石农场'
    }, {
      'code': '002036018026',
      'text': '岭头茶场'
    }],
    'text': '琼中黎族自治县'
  }, {
    'code': '002036019',
    'children': [{
      'code': '002036019010',
      'text': '中沙群岛'
    }, {
      'code': '002036019011',
      'text': '南沙群岛'
    }, {
      'code': '002036019012',
      'text': '西沙群岛'
    }],
    'text': '三沙市'
  }],
  'text': '海南'
}, {
  'code': '002040',
  'children': [{
    'code': '002040001',
    'children': [{
      'code': '002040001001',
      'text': '万州区'
    }, {
      'code': '002040001002',
      'text': '涪陵区'
    }, {
      'code': '002040001003',
      'text': '渝中区'
    }, {
      'code': '002040001004',
      'text': '大渡口区'
    }, {
      'code': '002040001005',
      'text': '江北区'
    }, {
      'code': '002040001006',
      'text': '沙坪坝区'
    }, {
      'code': '002040001007',
      'text': '九龙坡区'
    }, {
      'code': '002040001008',
      'text': '南岸区'
    }, {
      'code': '002040001009',
      'text': '北碚区'
    }, {
      'code': '002040001010',
      'text': '万盛区'
    }, {
      'code': '002040001012',
      'text': '渝北区'
    }, {
      'code': '002040001013',
      'text': '巴南区'
    }, {
      'code': '002040001015',
      'text': '高新区'
    }, {
      'code': '002040001016',
      'text': '北部新区'
    }, {
      'code': '002040001021',
      'text': '长寿区'
    }, {
      'code': '002040001022',
      'text': '綦江区'
    }, {
      'code': '002040001023',
      'text': '潼南县'
    }, {
      'code': '002040001024',
      'text': '铜梁县'
    }, {
      'code': '002040001025',
      'text': '大足县'
    }, {
      'code': '002040001026',
      'text': '荣昌县'
    }, {
      'code': '002040001027',
      'text': '璧山县'
    }, {
      'code': '002040001028',
      'text': '梁平县'
    }, {
      'code': '002040001029',
      'text': '城口县'
    }, {
      'code': '002040001030',
      'text': '丰都县'
    }, {
      'code': '002040001031',
      'text': '垫江县'
    }, {
      'code': '002040001032',
      'text': '武隆县'
    }, {
      'code': '002040001033',
      'text': '忠县'
    }, {
      'code': '002040001034',
      'text': '开县'
    }, {
      'code': '002040001035',
      'text': '云阳县'
    }, {
      'code': '002040001036',
      'text': '奉节县'
    }, {
      'code': '002040001037',
      'text': '巫山县'
    }, {
      'code': '002040001038',
      'text': '巫溪县'
    }, {
      'code': '002040001040',
      'text': '石柱土家族自治县'
    }, {
      'code': '002040001041',
      'text': '秀山土家族苗族自治县'
    }, {
      'code': '002040001042',
      'text': '酉阳土家族苗族自治县'
    }, {
      'code': '002040001043',
      'text': '彭水苗族土家族自治县'
    }, {
      'code': '002040001044',
      'text': '两江新区'
    }, {
      'code': '002040001081',
      'text': '江津区'
    }, {
      'code': '002040001082',
      'text': '合川市'
    }, {
      'code': '002040001083',
      'text': '永川市'
    }, {
      'code': '002040001084',
      'text': '南川市'
    }, {
      'code': '002040001089',
      'text': '黔江区'
    }],
    'text': '重庆市'
  }],
  'text': '重庆'
}, {
  'code': '002041',
  'children': [{
    'code': '002041001',
    'children': [{
      'code': '002041001004',
      'text': '锦江区'
    }, {
      'code': '002041001005',
      'text': '青羊区'
    }, {
      'code': '002041001006',
      'text': '金牛区'
    }, {
      'code': '002041001007',
      'text': '武侯区'
    }, {
      'code': '002041001008',
      'text': '成华区'
    }, {
      'code': '002041001010',
      'text': '郫县'
    }, {
      'code': '002041001012',
      'text': '龙泉驿区'
    }, {
      'code': '002041001013',
      'text': '青白江区'
    }, {
      'code': '002041001015',
      'text': '高新区'
    }, {
      'code': '002041001016',
      'text': '高新西区'
    }, {
      'code': '002041001021',
      'text': '金堂县'
    }, {
      'code': '002041001022',
      'text': '双流区'
    }, {
      'code': '002041001023',
      'text': '温江区'
    }, {
      'code': '002041001024',
      'text': '郫都区'
    }, {
      'code': '002041001025',
      'text': '新都区'
    }, {
      'code': '002041001029',
      'text': '大邑县'
    }, {
      'code': '002041001031',
      'text': '蒲江县'
    }, {
      'code': '002041001032',
      'text': '新津县'
    }, {
      'code': '002041001081',
      'text': '都江堰市'
    }, {
      'code': '002041001082',
      'text': '彭州市'
    }, {
      'code': '002041001083',
      'text': '邛崃市'
    }, {
      'code': '002041001084',
      'text': '崇州市'
    }, {
      'code': '002041001085',
      'text': '简阳市'
    }],
    'text': '成都市'
  }, {
    'code': '002041003',
    'children': [{
      'code': '002041003002',
      'text': '自流井区'
    }, {
      'code': '002041003003',
      'text': '贡井区'
    }, {
      'code': '002041003004',
      'text': '大安区'
    }, {
      'code': '002041003011',
      'text': '沿滩区'
    }, {
      'code': '002041003021',
      'text': '荣县'
    }, {
      'code': '002041003022',
      'text': '富顺县'
    }],
    'text': '自贡市'
  }, {
    'code': '002041004',
    'children': [{
      'code': '002041004002',
      'text': '东区'
    }, {
      'code': '002041004003',
      'text': '西区'
    }, {
      'code': '002041004011',
      'text': '仁和区'
    }, {
      'code': '002041004021',
      'text': '米易县'
    }, {
      'code': '002041004022',
      'text': '盐边县'
    }],
    'text': '攀枝花市'
  }, {
    'code': '002041005',
    'children': [{
      'code': '002041005002',
      'text': '江阳区'
    }, {
      'code': '002041005003',
      'text': '纳溪区'
    }, {
      'code': '002041005004',
      'text': '龙马潭区'
    }, {
      'code': '002041005021',
      'text': '泸县'
    }, {
      'code': '002041005022',
      'text': '合江县'
    }, {
      'code': '002041005024',
      'text': '叙永县'
    }, {
      'code': '002041005025',
      'text': '古蔺县'
    }],
    'text': '泸州市'
  }, {
    'code': '002041006',
    'children': [{
      'code': '002041006003',
      'text': '旌阳区'
    }, {
      'code': '002041006023',
      'text': '中江县'
    }, {
      'code': '002041006026',
      'text': '罗江县'
    }, {
      'code': '002041006081',
      'text': '广汉市'
    }, {
      'code': '002041006082',
      'text': '什邡市'
    }, {
      'code': '002041006083',
      'text': '绵竹市'
    }],
    'text': '德阳市'
  }, {
    'code': '002041007',
    'children': [{
      'code': '002041007003',
      'text': '涪城区'
    }, {
      'code': '002041007004',
      'text': '游仙区'
    }, {
      'code': '002041007010',
      'text': '高新区'
    }, {
      'code': '002041007011',
      'text': '经开区'
    }, {
      'code': '002041007022',
      'text': '三台县'
    }, {
      'code': '002041007023',
      'text': '盐亭县'
    }, {
      'code': '002041007024',
      'text': '安县'
    }, {
      'code': '002041007025',
      'text': '梓潼县'
    }, {
      'code': '002041007026',
      'text': '北川县'
    }, {
      'code': '002041007027',
      'text': '平武县'
    }, {
      'code': '002041007081',
      'text': '江油市'
    }, {
      'code': '002041007082',
      'text': '经济技术开发区'
    }, {
      'code': '002041007083',
      'text': '农科区'
    }, {
      'code': '002041007084',
      'text': '仙海区'
    }],
    'text': '绵阳市'
  }, {
    'code': '002041008',
    'children': [{
      'code': '002041008002',
      'text': '市中区'
    }, {
      'code': '002041008011',
      'text': '元坝区'
    }, {
      'code': '002041008012',
      'text': '朝天区'
    }, {
      'code': '002041008013',
      'text': '昭化区'
    }, {
      'code': '002041008014',
      'text': '利州区'
    }, {
      'code': '002041008021',
      'text': '旺苍县'
    }, {
      'code': '002041008022',
      'text': '青川县'
    }, {
      'code': '002041008023',
      'text': '剑阁县'
    }, {
      'code': '002041008024',
      'text': '苍溪县'
    }],
    'text': '广元市'
  }, {
    'code': '002041009',
    'children': [{
      'code': '002041009002',
      'text': '市中区'
    }, {
      'code': '002041009010',
      'text': '安居区'
    }, {
      'code': '002041009011',
      'text': '船山区'
    }, {
      'code': '002041009021',
      'text': '蓬溪县'
    }, {
      'code': '002041009022',
      'text': '射洪县'
    }, {
      'code': '002041009023',
      'text': '大英县'
    }],
    'text': '遂宁市'
  }, {
    'code': '002041010',
    'children': [{
      'code': '002041010002',
      'text': '市中区'
    }, {
      'code': '002041010011',
      'text': '东兴区'
    }, {
      'code': '002041010024',
      'text': '威远县'
    }, {
      'code': '002041010025',
      'text': '资中县'
    }, {
      'code': '002041010028',
      'text': '隆昌县'
    }],
    'text': '内江市'
  }, {
    'code': '002041011',
    'children': [{
      'code': '002041011002',
      'text': '市中区'
    }, {
      'code': '002041011011',
      'text': '沙湾区'
    }, {
      'code': '002041011012',
      'text': '五通桥区'
    }, {
      'code': '002041011013',
      'text': '金口河区'
    }, {
      'code': '002041011023',
      'text': '犍为县'
    }, {
      'code': '002041011024',
      'text': '井研县'
    }, {
      'code': '002041011026',
      'text': '夹江县'
    }, {
      'code': '002041011029',
      'text': '沐川县'
    }, {
      'code': '002041011032',
      'text': '峨边彝族自治县'
    }, {
      'code': '002041011033',
      'text': '马边彝族自治县'
    }, {
      'code': '002041011081',
      'text': '峨眉山市'
    }],
    'text': '乐山市'
  }, {
    'code': '002041013',
    'children': [{
      'code': '002041013002',
      'text': '顺庆区'
    }, {
      'code': '002041013003',
      'text': '高坪区'
    }, {
      'code': '002041013004',
      'text': '嘉陵区'
    }, {
      'code': '002041013021',
      'text': '南部县'
    }, {
      'code': '002041013022',
      'text': '营山县'
    }, {
      'code': '002041013023',
      'text': '蓬安县'
    }, {
      'code': '002041013024',
      'text': '仪陇县'
    }, {
      'code': '002041013025',
      'text': '西充县'
    }, {
      'code': '002041013081',
      'text': '阆中市'
    }],
    'text': '南充市'
  }, {
    'code': '002041015',
    'children': [{
      'code': '002041015002',
      'text': '翠屏区'
    }, {
      'code': '002041015021',
      'text': '宜宾县'
    }, {
      'code': '002041015022',
      'text': '南溪县'
    }, {
      'code': '002041015023',
      'text': '江安县'
    }, {
      'code': '002041015024',
      'text': '长宁县'
    }, {
      'code': '002041015025',
      'text': '高县'
    }, {
      'code': '002041015026',
      'text': '珙县'
    }, {
      'code': '002041015027',
      'text': '筠连县'
    }, {
      'code': '002041015028',
      'text': '兴文县'
    }, {
      'code': '002041015029',
      'text': '屏山县'
    }, {
      'code': '002041015030',
      'text': '叙州区'
    }],
    'text': '宜宾市'
  }, {
    'code': '002041016',
    'children': [{
      'code': '002041016002',
      'text': '广安区'
    }, {
      'code': '002041016010',
      'text': '前锋区'
    }, {
      'code': '002041016021',
      'text': '岳池县'
    }, {
      'code': '002041016022',
      'text': '武胜县'
    }, {
      'code': '002041016023',
      'text': '邻水县'
    }, {
      'code': '002041016081',
      'text': '华蓥市'
    }],
    'text': '广安市'
  }, {
    'code': '002041030',
    'children': [{
      'code': '002041030001',
      'text': '万州区'
    }, {
      'code': '002041030002',
      'text': '万源市'
    }, {
      'code': '002041030010',
      'text': '通川区'
    }, {
      'code': '002041030011',
      'text': '达川区'
    }, {
      'code': '002041030021',
      'text': '达县'
    }, {
      'code': '002041030022',
      'text': '宣汉县'
    }, {
      'code': '002041030023',
      'text': '开江县'
    }, {
      'code': '002041030029',
      'text': '大竹县'
    }, {
      'code': '002041030030',
      'text': '渠县'
    }],
    'text': '达州市'
  }, {
    'code': '002041031',
    'children': [{
      'code': '002041031001',
      'text': '雨城区'
    }, {
      'code': '002041031022',
      'text': '名山县'
    }, {
      'code': '002041031023',
      'text': '荥经县'
    }, {
      'code': '002041031024',
      'text': '汉源县'
    }, {
      'code': '002041031025',
      'text': '石棉县'
    }, {
      'code': '002041031026',
      'text': '天全县'
    }, {
      'code': '002041031027',
      'text': '芦山县'
    }, {
      'code': '002041031028',
      'text': '宝兴县'
    }],
    'text': '雅安市'
  }, {
    'code': '002041032',
    'children': [{
      'code': '002041032021',
      'text': '汶川县'
    }, {
      'code': '002041032022',
      'text': '理县'
    }, {
      'code': '002041032023',
      'text': '茂县'
    }, {
      'code': '002041032024',
      'text': '松潘县'
    }, {
      'code': '002041032025',
      'text': '九寨沟县'
    }, {
      'code': '002041032026',
      'text': '金川县'
    }, {
      'code': '002041032027',
      'text': '小金县'
    }, {
      'code': '002041032028',
      'text': '黑水县'
    }, {
      'code': '002041032029',
      'text': '马尔康县'
    }, {
      'code': '002041032030',
      'text': '壤塘县'
    }, {
      'code': '002041032031',
      'text': '阿坝县'
    }, {
      'code': '002041032032',
      'text': '若尔盖县'
    }, {
      'code': '002041032033',
      'text': '红原县'
    }],
    'text': '阿坝藏族羌族自治州'
  }, {
    'code': '002041033',
    'children': [{
      'code': '002041033021',
      'text': '康定县'
    }, {
      'code': '002041033022',
      'text': '泸定县'
    }, {
      'code': '002041033023',
      'text': '丹巴县'
    }, {
      'code': '002041033024',
      'text': '九龙县'
    }, {
      'code': '002041033025',
      'text': '雅江县'
    }, {
      'code': '002041033026',
      'text': '道孚县'
    }, {
      'code': '002041033027',
      'text': '炉霍县'
    }, {
      'code': '002041033028',
      'text': '甘孜县'
    }, {
      'code': '002041033029',
      'text': '新龙县'
    }, {
      'code': '002041033030',
      'text': '德格县'
    }, {
      'code': '002041033031',
      'text': '白玉县'
    }, {
      'code': '002041033032',
      'text': '石渠县'
    }, {
      'code': '002041033033',
      'text': '色达县'
    }, {
      'code': '002041033034',
      'text': '理塘县'
    }, {
      'code': '002041033035',
      'text': '巴塘县'
    }, {
      'code': '002041033036',
      'text': '乡城县'
    }, {
      'code': '002041033037',
      'text': '稻城县'
    }, {
      'code': '002041033038',
      'text': '得荣县'
    }],
    'text': '甘孜藏族自治州'
  }, {
    'code': '002041034',
    'children': [{
      'code': '002041034001',
      'text': '西昌市'
    }, {
      'code': '002041034022',
      'text': '木里藏族自治县'
    }, {
      'code': '002041034023',
      'text': '盐源县'
    }, {
      'code': '002041034024',
      'text': '德昌县'
    }, {
      'code': '002041034025',
      'text': '会理县'
    }, {
      'code': '002041034026',
      'text': '会东县'
    }, {
      'code': '002041034027',
      'text': '宁南县'
    }, {
      'code': '002041034028',
      'text': '普格县'
    }, {
      'code': '002041034029',
      'text': '布拖县'
    }, {
      'code': '002041034030',
      'text': '金阳县'
    }, {
      'code': '002041034031',
      'text': '昭觉县'
    }, {
      'code': '002041034032',
      'text': '喜德县'
    }, {
      'code': '002041034033',
      'text': '冕宁县'
    }, {
      'code': '002041034034',
      'text': '越西县'
    }, {
      'code': '002041034035',
      'text': '甘洛县'
    }, {
      'code': '002041034036',
      'text': '美姑县'
    }, {
      'code': '002041034037',
      'text': '雷波县'
    }],
    'text': '凉山彝族自治州'
  }, {
    'code': '002041037',
    'children': [{
      'code': '002041037001',
      'text': '巴中区'
    }, {
      'code': '002041037010',
      'text': '恩阳区'
    }, {
      'code': '002041037011',
      'text': '巴州区'
    }, {
      'code': '002041037021',
      'text': '通江县'
    }, {
      'code': '002041037022',
      'text': '南江县'
    }, {
      'code': '002041037023',
      'text': '平昌县'
    }],
    'text': '巴中市'
  }, {
    'code': '002041038',
    'children': [{
      'code': '002041038021',
      'text': '东坡区'
    }, {
      'code': '002041038022',
      'text': '仁寿县'
    }, {
      'code': '002041038023',
      'text': '彭山县'
    }, {
      'code': '002041038024',
      'text': '洪雅县'
    }, {
      'code': '002041038025',
      'text': '丹棱县'
    }, {
      'code': '002041038026',
      'text': '青神县'
    }],
    'text': '眉山市'
  }, {
    'code': '002041039',
    'children': [{
      'code': '002041039001',
      'text': '雁江区'
    }, {
      'code': '002041039002',
      'text': '简阳市'
    }, {
      'code': '002041039021',
      'text': '安岳县'
    }, {
      'code': '002041039022',
      'text': '乐至县'
    }],
    'text': '资阳市'
  }],
  'text': '四川'
}, {
  'code': '002042',
  'children': [{
    'code': '002042001',
    'children': [{
      'code': '002042001002',
      'text': '南明区'
    }, {
      'code': '002042001003',
      'text': '云岩区'
    }, {
      'code': '002042001010',
      'text': '观山湖区'
    }, {
      'code': '002042001011',
      'text': '花溪区'
    }, {
      'code': '002042001012',
      'text': '乌当区'
    }, {
      'code': '002042001013',
      'text': '白云区'
    }, {
      'code': '002042001021',
      'text': '开阳县'
    }, {
      'code': '002042001022',
      'text': '息烽县'
    }, {
      'code': '002042001023',
      'text': '修文县'
    }, {
      'code': '002042001081',
      'text': '清镇市'
    }, {
      'code': '002042001082',
      'text': '小河区'
    }, {
      'code': '002042001083',
      'text': '金阳区'
    }],
    'text': '贵阳市'
  }, {
    'code': '002042002',
    'children': [{
      'code': '002042002001',
      'text': '钟山区'
    }, {
      'code': '002042002002',
      'text': '盘县特区'
    }, {
      'code': '002042002003',
      'text': '六枝特区'
    }, {
      'code': '002042002021',
      'text': '水城县'
    }],
    'text': '六盘水市'
  }, {
    'code': '002042003',
    'children': [{
      'code': '002042003002',
      'text': '红花岗区'
    }, {
      'code': '002042003021',
      'text': '遵义县'
    }, {
      'code': '002042003022',
      'text': '桐梓县'
    }, {
      'code': '002042003023',
      'text': '绥阳县'
    }, {
      'code': '002042003024',
      'text': '正安县'
    }, {
      'code': '002042003025',
      'text': '道真仡佬族苗族自治县'
    }, {
      'code': '002042003026',
      'text': '务川仡佬族苗族自治县'
    }, {
      'code': '002042003027',
      'text': '凤冈县'
    }, {
      'code': '002042003028',
      'text': '湄潭县'
    }, {
      'code': '002042003029',
      'text': '余庆县'
    }, {
      'code': '002042003030',
      'text': '习水县'
    }, {
      'code': '002042003081',
      'text': '赤水市'
    }, {
      'code': '002042003082',
      'text': '仁怀市'
    }, {
      'code': '002042003083',
      'text': '汇川区'
    }, {
      'code': '002042003084',
      'text': '播州区'
    }],
    'text': '遵义市'
  }, {
    'code': '002042022',
    'children': [{
      'code': '002042022001',
      'text': '铜仁市'
    }, {
      'code': '002042022010',
      'text': '碧江区'
    }, {
      'code': '002042022022',
      'text': '江口县'
    }, {
      'code': '002042022023',
      'text': '玉屏侗族自治县'
    }, {
      'code': '002042022024',
      'text': '石阡县'
    }, {
      'code': '002042022025',
      'text': '思南县'
    }, {
      'code': '002042022026',
      'text': '印江土家族苗族自治县'
    }, {
      'code': '002042022027',
      'text': '德江县'
    }, {
      'code': '002042022028',
      'text': '沿河土家族自治县'
    }, {
      'code': '002042022029',
      'text': '松桃苗族自治县'
    }, {
      'code': '002042022030',
      'text': '万山特区'
    }],
    'text': '铜仁地区'
  }, {
    'code': '002042023',
    'children': [{
      'code': '002042023001',
      'text': '兴义市'
    }, {
      'code': '002042023022',
      'text': '兴仁县'
    }, {
      'code': '002042023023',
      'text': '普安县'
    }, {
      'code': '002042023024',
      'text': '晴隆县'
    }, {
      'code': '002042023025',
      'text': '贞丰县'
    }, {
      'code': '002042023026',
      'text': '望谟县'
    }, {
      'code': '002042023027',
      'text': '册亨县'
    }, {
      'code': '002042023028',
      'text': '安龙县'
    }],
    'text': '黔西南布依族苗族自治州'
  }, {
    'code': '002042024',
    'children': [{
      'code': '002042024001',
      'text': '毕节市'
    }, {
      'code': '002042024010',
      'text': '七星关区'
    }, {
      'code': '002042024022',
      'text': '大方县'
    }, {
      'code': '002042024023',
      'text': '黔西县'
    }, {
      'code': '002042024024',
      'text': '金沙县'
    }, {
      'code': '002042024025',
      'text': '织金县'
    }, {
      'code': '002042024026',
      'text': '纳雍县'
    }, {
      'code': '002042024027',
      'text': '威宁彝族回族苗族自治县'
    }, {
      'code': '002042024028',
      'text': '赫章县'
    }],
    'text': '毕节地区'
  }, {
    'code': '002042025',
    'children': [{
      'code': '002042025001',
      'text': '西秀区'
    }, {
      'code': '002042025026',
      'text': '平坝县'
    }, {
      'code': '002042025027',
      'text': '普定县'
    }, {
      'code': '002042025028',
      'text': '关岭布依族苗族自治县'
    }, {
      'code': '002042025029',
      'text': '镇宁布依族苗族自治县'
    }, {
      'code': '002042025030',
      'text': '紫云苗族布依族自治县'
    }],
    'text': '安顺市'
  }, {
    'code': '002042026',
    'children': [{
      'code': '002042026001',
      'text': '凯里市'
    }, {
      'code': '002042026022',
      'text': '黄平县'
    }, {
      'code': '002042026023',
      'text': '施秉县'
    }, {
      'code': '002042026024',
      'text': '三穗县'
    }, {
      'code': '002042026025',
      'text': '镇远县'
    }, {
      'code': '002042026026',
      'text': '岑巩县'
    }, {
      'code': '002042026027',
      'text': '天柱县'
    }, {
      'code': '002042026028',
      'text': '锦屏县'
    }, {
      'code': '002042026029',
      'text': '剑河县'
    }, {
      'code': '002042026030',
      'text': '台江县'
    }, {
      'code': '002042026031',
      'text': '黎平县'
    }, {
      'code': '002042026032',
      'text': '榕江县'
    }, {
      'code': '002042026033',
      'text': '从江县'
    }, {
      'code': '002042026034',
      'text': '雷山县'
    }, {
      'code': '002042026035',
      'text': '麻江县'
    }, {
      'code': '002042026036',
      'text': '丹寨县'
    }],
    'text': '黔东南苗族侗族自治州'
  }, {
    'code': '002042027',
    'children': [{
      'code': '002042027001',
      'text': '都匀市'
    }, {
      'code': '002042027002',
      'text': '福泉市'
    }, {
      'code': '002042027022',
      'text': '荔波县'
    }, {
      'code': '002042027023',
      'text': '贵定县'
    }, {
      'code': '002042027025',
      'text': '瓮安县'
    }, {
      'code': '002042027026',
      'text': '独山县'
    }, {
      'code': '002042027027',
      'text': '平塘县'
    }, {
      'code': '002042027028',
      'text': '罗甸县'
    }, {
      'code': '002042027029',
      'text': '长顺县'
    }, {
      'code': '002042027030',
      'text': '龙里县'
    }, {
      'code': '002042027031',
      'text': '惠水县'
    }, {
      'code': '002042027032',
      'text': '三都水族自治县'
    }],
    'text': '黔南布依族苗族自治州'
  }],
  'text': '贵州'
}, {
  'code': '002043',
  'children': [{
    'code': '002043001',
    'children': [{
      'code': '002043001002',
      'text': '五华区'
    }, {
      'code': '002043001003',
      'text': '盘龙区'
    }, {
      'code': '002043001011',
      'text': '官渡区'
    }, {
      'code': '002043001012',
      'text': '西山区'
    }, {
      'code': '002043001013',
      'text': '东川区'
    }, {
      'code': '002043001021',
      'text': '呈贡县'
    }, {
      'code': '002043001022',
      'text': '晋宁县'
    }, {
      'code': '002043001024',
      'text': '富民县'
    }, {
      'code': '002043001025',
      'text': '宜良县'
    }, {
      'code': '002043001026',
      'text': '石林彝族自治县'
    }, {
      'code': '002043001027',
      'text': '嵩明县'
    }, {
      'code': '002043001028',
      'text': '禄劝彝族苗族自治县'
    }, {
      'code': '002043001029',
      'text': '寻甸回族彝族自治县'
    }, {
      'code': '002043001081',
      'text': '安宁市'
    }],
    'text': '昆明市'
  }, {
    'code': '002043003',
    'children': [{
      'code': '002043003002',
      'text': '麒麟区'
    }, {
      'code': '002043003021',
      'text': '马龙县'
    }, {
      'code': '002043003022',
      'text': '陆良县'
    }, {
      'code': '002043003023',
      'text': '师宗县'
    }, {
      'code': '002043003024',
      'text': '罗平县'
    }, {
      'code': '002043003025',
      'text': '富源县'
    }, {
      'code': '002043003026',
      'text': '会泽县'
    }, {
      'code': '002043003028',
      'text': '沾益县'
    }, {
      'code': '002043003081',
      'text': '宣威市'
    }],
    'text': '曲靖市'
  }, {
    'code': '002043004',
    'children': [{
      'code': '002043004002',
      'text': '红塔区'
    }, {
      'code': '002043004021',
      'text': '江川县'
    }, {
      'code': '002043004022',
      'text': '澄江县'
    }, {
      'code': '002043004023',
      'text': '通海县'
    }, {
      'code': '002043004024',
      'text': '华宁县'
    }, {
      'code': '002043004025',
      'text': '易门县'
    }, {
      'code': '002043004026',
      'text': '峨山彝族自治县'
    }, {
      'code': '002043004027',
      'text': '新平彝族傣族自治县'
    }, {
      'code': '002043004028',
      'text': '元江哈尼族彝族傣族自治县'
    }],
    'text': '玉溪市'
  }, {
    'code': '002043021',
    'children': [{
      'code': '002043021001',
      'text': '昭阳区'
    }, {
      'code': '002043021022',
      'text': '鲁甸县'
    }, {
      'code': '002043021023',
      'text': '巧家县'
    }, {
      'code': '002043021024',
      'text': '盐津县'
    }, {
      'code': '002043021025',
      'text': '大关县'
    }, {
      'code': '002043021026',
      'text': '永善县'
    }, {
      'code': '002043021027',
      'text': '绥江县'
    }, {
      'code': '002043021028',
      'text': '镇雄县'
    }, {
      'code': '002043021029',
      'text': '彝良县'
    }, {
      'code': '002043021030',
      'text': '威信县'
    }, {
      'code': '002043021031',
      'text': '水富县'
    }],
    'text': '昭通市'
  }, {
    'code': '002043023',
    'children': [{
      'code': '002043023001',
      'text': '楚雄市'
    }, {
      'code': '002043023022',
      'text': '双柏县'
    }, {
      'code': '002043023023',
      'text': '牟定县'
    }, {
      'code': '002043023024',
      'text': '南华县'
    }, {
      'code': '002043023025',
      'text': '姚安县'
    }, {
      'code': '002043023026',
      'text': '大姚县'
    }, {
      'code': '002043023027',
      'text': '永仁县'
    }, {
      'code': '002043023028',
      'text': '元谋县'
    }, {
      'code': '002043023029',
      'text': '武定县'
    }, {
      'code': '002043023031',
      'text': '禄丰县'
    }],
    'text': '楚雄彝族自治州'
  }, {
    'code': '002043025',
    'children': [{
      'code': '002043025001',
      'text': '个旧市'
    }, {
      'code': '002043025002',
      'text': '开远市'
    }, {
      'code': '002043025022',
      'text': '蒙自县'
    }, {
      'code': '002043025023',
      'text': '屏边苗族自治县'
    }, {
      'code': '002043025024',
      'text': '建水县'
    }, {
      'code': '002043025025',
      'text': '石屏县'
    }, {
      'code': '002043025026',
      'text': '弥勒县'
    }, {
      'code': '002043025027',
      'text': '泸西县'
    }, {
      'code': '002043025028',
      'text': '元阳县'
    }, {
      'code': '002043025029',
      'text': '红河县'
    }, {
      'code': '002043025030',
      'text': '金平苗族瑶族傣族自治县'
    }, {
      'code': '002043025031',
      'text': '绿春县'
    }, {
      'code': '002043025032',
      'text': '河口瑶族自治县'
    }],
    'text': '红河哈尼族彝族自治州'
  }, {
    'code': '002043026',
    'children': [{
      'code': '002043026021',
      'text': '文山县'
    }, {
      'code': '002043026022',
      'text': '砚山县'
    }, {
      'code': '002043026023',
      'text': '西畴县'
    }, {
      'code': '002043026024',
      'text': '麻栗坡县'
    }, {
      'code': '002043026025',
      'text': '马关县'
    }, {
      'code': '002043026026',
      'text': '丘北县'
    }, {
      'code': '002043026027',
      'text': '广南县'
    }, {
      'code': '002043026028',
      'text': '富宁县'
    }],
    'text': '文山壮族苗族自治州'
  }, {
    'code': '002043027',
    'children': [{
      'code': '002043027001',
      'text': '思茅区'
    }, {
      'code': '002043027022',
      'text': '宁洱哈尼族彝族自治县'
    }, {
      'code': '002043027023',
      'text': '墨江哈尼族自治县'
    }, {
      'code': '002043027024',
      'text': '景东彝族自治县'
    }, {
      'code': '002043027025',
      'text': '景谷傣族彝族自治县'
    }, {
      'code': '002043027026',
      'text': '镇沅彝族哈尼族拉祜族自治县'
    }, {
      'code': '002043027027',
      'text': '江城哈尼族彝族自治县'
    }, {
      'code': '002043027028',
      'text': '孟连傣族拉祜族佤族自治县'
    }, {
      'code': '002043027029',
      'text': '澜沧拉祜族自治县'
    }, {
      'code': '002043027030',
      'text': '西盟佤族自治县'
    }],
    'text': '普洱市'
  }, {
    'code': '002043028',
    'children': [{
      'code': '002043028001',
      'text': '景洪市'
    }, {
      'code': '002043028022',
      'text': '勐海县'
    }, {
      'code': '002043028023',
      'text': '勐腊县'
    }],
    'text': '西双版纳傣族自治州'
  }, {
    'code': '002043029',
    'children': [{
      'code': '002043029001',
      'text': '大理市'
    }, {
      'code': '002043029022',
      'text': '漾濞彝族自治县'
    }, {
      'code': '002043029023',
      'text': '祥云县'
    }, {
      'code': '002043029024',
      'text': '宾川县'
    }, {
      'code': '002043029025',
      'text': '弥渡县'
    }, {
      'code': '002043029026',
      'text': '南涧彝族自治县'
    }, {
      'code': '002043029027',
      'text': '巍山彝族回族自治县'
    }, {
      'code': '002043029028',
      'text': '永平县'
    }, {
      'code': '002043029029',
      'text': '云龙县'
    }, {
      'code': '002043029030',
      'text': '洱源县'
    }, {
      'code': '002043029031',
      'text': '剑川县'
    }, {
      'code': '002043029032',
      'text': '鹤庆县'
    }, {
      'code': '002043029099',
      'text': '大理县'
    }],
    'text': '大理白族自治州'
  }, {
    'code': '002043030',
    'children': [{
      'code': '002043030022',
      'text': '施甸县'
    }, {
      'code': '002043030023',
      'text': '腾冲县'
    }, {
      'code': '002043030024',
      'text': '龙陵县'
    }, {
      'code': '002043030025',
      'text': '昌宁县'
    }, {
      'code': '002043030099',
      'text': '隆阳区'
    }],
    'text': '保山市'
  }, {
    'code': '002043031',
    'children': [{
      'code': '002043031001',
      'text': '畹町市'
    }, {
      'code': '002043031002',
      'text': '瑞丽市'
    }, {
      'code': '002043031003',
      'text': '潞西市'
    }, {
      'code': '002043031010',
      'text': '芒市'
    }, {
      'code': '002043031022',
      'text': '梁河县'
    }, {
      'code': '002043031023',
      'text': '盈江县'
    }, {
      'code': '002043031024',
      'text': '陇川县'
    }],
    'text': '德宏傣族景颇族自治州'
  }, {
    'code': '002043032',
    'children': [{
      'code': '002043032021',
      'text': '玉龙纳西族自治县'
    }, {
      'code': '002043032022',
      'text': '永胜县'
    }, {
      'code': '002043032023',
      'text': '华坪县'
    }, {
      'code': '002043032024',
      'text': '宁蒗彝族自治县'
    }, {
      'code': '002043032099',
      'text': '古城区'
    }],
    'text': '丽江市'
  }, {
    'code': '002043033',
    'children': [{
      'code': '002043033021',
      'text': '泸水县'
    }, {
      'code': '002043033023',
      'text': '福贡县'
    }, {
      'code': '002043033024',
      'text': '贡山独龙族怒族自治县'
    }, {
      'code': '002043033025',
      'text': '兰坪白族普米族自治县'
    }],
    'text': '怒江傈僳族自治州'
  }, {
    'code': '002043034',
    'children': [{
      'code': '002043034021',
      'text': '中甸县'
    }, {
      'code': '002043034022',
      'text': '德钦县'
    }, {
      'code': '002043034023',
      'text': '维西傈僳族自治县'
    }, {
      'code': '002043034099',
      'text': '香格里拉县'
    }],
    'text': '迪庆藏族自治州'
  }, {
    'code': '002043035',
    'children': [{
      'code': '002043035022',
      'text': '凤庆县'
    }, {
      'code': '002043035023',
      'text': '云县'
    }, {
      'code': '002043035024',
      'text': '永德县'
    }, {
      'code': '002043035025',
      'text': '镇康县'
    }, {
      'code': '002043035026',
      'text': '双江拉祜族佤族布朗族傣族自治县'
    }, {
      'code': '002043035027',
      'text': '耿马傣族佤族自治县'
    }, {
      'code': '002043035028',
      'text': '沧源佤族自治县'
    }, {
      'code': '002043035099',
      'text': '临翔区'
    }],
    'text': '临沧市'
  }],
  'text': '云南'
}, {
  'code': '002044',
  'children': [{
    'code': '002044001',
    'children': [{
      'code': '002044001002',
      'text': '城关区'
    }, {
      'code': '002044001021',
      'text': '林周县'
    }, {
      'code': '002044001022',
      'text': '当雄县'
    }, {
      'code': '002044001023',
      'text': '尼木县'
    }, {
      'code': '002044001024',
      'text': '曲水县'
    }, {
      'code': '002044001025',
      'text': '堆龙德庆县'
    }, {
      'code': '002044001026',
      'text': '达孜县'
    }, {
      'code': '002044001027',
      'text': '墨竹工卡县'
    }],
    'text': '拉萨市'
  }, {
    'code': '002044021',
    'children': [{
      'code': '002044021021',
      'text': '昌都县'
    }, {
      'code': '002044021022',
      'text': '江达县'
    }, {
      'code': '002044021023',
      'text': '贡觉县'
    }, {
      'code': '002044021024',
      'text': '类乌齐县'
    }, {
      'code': '002044021025',
      'text': '丁青县'
    }, {
      'code': '002044021026',
      'text': '察雅县'
    }, {
      'code': '002044021027',
      'text': '八宿县'
    }, {
      'code': '002044021028',
      'text': '左贡县'
    }, {
      'code': '002044021029',
      'text': '芒康县'
    }, {
      'code': '002044021032',
      'text': '洛隆县'
    }, {
      'code': '002044021033',
      'text': '边坝县'
    }, {
      'code': '002044021034',
      'text': '盐井县'
    }, {
      'code': '002044021035',
      'text': '碧土县'
    }, {
      'code': '002044021036',
      'text': '妥坝县'
    }, {
      'code': '002044021037',
      'text': '生达县'
    }, {
      'code': '002044021099',
      'text': '昌都地区直属'
    }, {
      'code': '002044021100',
      'text': '卡若区'
    }],
    'text': '昌都地区'
  }, {
    'code': '002044022',
    'children': [{
      'code': '002044022021',
      'text': '乃东县'
    }, {
      'code': '002044022022',
      'text': '扎囊县'
    }, {
      'code': '002044022023',
      'text': '贡嘎县'
    }, {
      'code': '002044022024',
      'text': '桑日县'
    }, {
      'code': '002044022025',
      'text': '琼结县'
    }, {
      'code': '002044022026',
      'text': '曲松县'
    }, {
      'code': '002044022027',
      'text': '措美县'
    }, {
      'code': '002044022028',
      'text': '洛扎县'
    }, {
      'code': '002044022029',
      'text': '加查县'
    }, {
      'code': '002044022031',
      'text': '隆子县'
    }, {
      'code': '002044022032',
      'text': '错那县'
    }, {
      'code': '002044022033',
      'text': '浪卡子县'
    }, {
      'code': '002044022099',
      'text': '山南地区直属'
    }],
    'text': '山南地区'
  }, {
    'code': '002044023',
    'children': [{
      'code': '002044023001',
      'text': '日喀则市'
    }, {
      'code': '002044023022',
      'text': '南木林县'
    }, {
      'code': '002044023023',
      'text': '江孜县'
    }, {
      'code': '002044023024',
      'text': '定日县'
    }, {
      'code': '002044023025',
      'text': '萨迦县'
    }, {
      'code': '002044023026',
      'text': '拉孜县'
    }, {
      'code': '002044023027',
      'text': '昂仁县'
    }, {
      'code': '002044023028',
      'text': '谢通门县'
    }, {
      'code': '002044023029',
      'text': '白朗县'
    }, {
      'code': '002044023030',
      'text': '仁布县'
    }, {
      'code': '002044023031',
      'text': '康马县'
    }, {
      'code': '002044023032',
      'text': '定结县'
    }, {
      'code': '002044023033',
      'text': '仲巴县'
    }, {
      'code': '002044023034',
      'text': '亚东县'
    }, {
      'code': '002044023035',
      'text': '吉隆县'
    }, {
      'code': '002044023036',
      'text': '聂拉木县'
    }, {
      'code': '002044023037',
      'text': '萨嘎县'
    }, {
      'code': '002044023038',
      'text': '岗巴县'
    }, {
      'code': '002044023098',
      'text': '日喀则市属'
    }, {
      'code': '002044023099',
      'text': '日喀则地区直属'
    }, {
      'code': '002044023100',
      'text': '桑珠孜区'
    }],
    'text': '日喀则地区'
  }, {
    'code': '002044024',
    'children': [{
      'code': '002044024021',
      'text': '那曲县'
    }, {
      'code': '002044024022',
      'text': '嘉黎县'
    }, {
      'code': '002044024023',
      'text': '比如县'
    }, {
      'code': '002044024024',
      'text': '聂荣县'
    }, {
      'code': '002044024025',
      'text': '安多县'
    }, {
      'code': '002044024026',
      'text': '申扎县'
    }, {
      'code': '002044024027',
      'text': '索县'
    }, {
      'code': '002044024028',
      'text': '班戈县'
    }, {
      'code': '002044024029',
      'text': '巴青县'
    }, {
      'code': '002044024030',
      'text': '尼玛县'
    }, {
      'code': '002044024098',
      'text': '双湖县'
    }, {
      'code': '002044024099',
      'text': '那曲地区直属'
    }],
    'text': '那曲地区'
  }, {
    'code': '002044025',
    'children': [{
      'code': '002044025021',
      'text': '普兰县'
    }, {
      'code': '002044025022',
      'text': '札达县'
    }, {
      'code': '002044025023',
      'text': '噶尔县'
    }, {
      'code': '002044025024',
      'text': '日土县'
    }, {
      'code': '002044025025',
      'text': '革吉县'
    }, {
      'code': '002044025026',
      'text': '改则县'
    }, {
      'code': '002044025027',
      'text': '措勤县'
    }, {
      'code': '002044025028',
      'text': '隆格尔县'
    }],
    'text': '阿里地区'
  }, {
    'code': '002044026',
    'children': [{
      'code': '002044026021',
      'text': '林芝县'
    }, {
      'code': '002044026022',
      'text': '工布江达县'
    }, {
      'code': '002044026023',
      'text': '米林县'
    }, {
      'code': '002044026024',
      'text': '墨脱县'
    }, {
      'code': '002044026025',
      'text': '波密县'
    }, {
      'code': '002044026026',
      'text': '察隅县'
    }, {
      'code': '002044026027',
      'text': '朗县'
    }, {
      'code': '002044026098',
      'text': '巴宜区'
    }, {
      'code': '002044026099',
      'text': '林芝市直属'
    }],
    'text': '林芝地区'
  }],
  'text': '西藏'
}, {
  'code': '002051',
  'children': [{
    'code': '002051001',
    'children': [{
      'code': '002051001002',
      'text': '新城区'
    }, {
      'code': '002051001003',
      'text': '碑林区'
    }, {
      'code': '002051001004',
      'text': '莲湖区'
    }, {
      'code': '002051001010',
      'text': '西安武警工程学院'
    }, {
      'code': '002051001011',
      'text': '灞桥区'
    }, {
      'code': '002051001012',
      'text': '未央区'
    }, {
      'code': '002051001013',
      'text': '雁塔区'
    }, {
      'code': '002051001014',
      'text': '阎良区'
    }, {
      'code': '002051001015',
      'text': '临潼区'
    }, {
      'code': '002051001021',
      'text': '长安县'
    }, {
      'code': '002051001022',
      'text': '蓝田县'
    }, {
      'code': '002051001024',
      'text': '周至县'
    }, {
      'code': '002051001025',
      'text': '户县'
    }, {
      'code': '002051001026',
      'text': '高陵县'
    }, {
      'code': '002051001027',
      'text': '高新区'
    }, {
      'code': '002051001028',
      'text': '鄠邑区'
    }],
    'text': '西安市'
  }, {
    'code': '002051002',
    'children': [{
      'code': '002051002002',
      'text': '城区'
    }, {
      'code': '002051002003',
      'text': '郊区'
    }, {
      'code': '002051002010',
      'text': '耀州区'
    }, {
      'code': '002051002021',
      'text': '耀县'
    }, {
      'code': '002051002022',
      'text': '宜君县'
    }, {
      'code': '002051002097',
      'text': '王益区'
    }, {
      'code': '002051002098',
      'text': '新区县'
    }, {
      'code': '002051002099',
      'text': '印台区'
    }],
    'text': '铜川市'
  }, {
    'code': '002051003',
    'children': [{
      'code': '002051003002',
      'text': '渭滨区'
    }, {
      'code': '002051003003',
      'text': '金台区'
    }, {
      'code': '002051003021',
      'text': '宝鸡县'
    }, {
      'code': '002051003022',
      'text': '凤翔县'
    }, {
      'code': '002051003023',
      'text': '岐山县'
    }, {
      'code': '002051003024',
      'text': '扶风县'
    }, {
      'code': '002051003026',
      'text': '眉县'
    }, {
      'code': '002051003027',
      'text': '陇县'
    }, {
      'code': '002051003028',
      'text': '千阳县'
    }, {
      'code': '002051003029',
      'text': '麟游县'
    }, {
      'code': '002051003030',
      'text': '凤县'
    }, {
      'code': '002051003031',
      'text': '太白县'
    }, {
      'code': '002051003099',
      'text': '陈仓区'
    }],
    'text': '宝鸡市'
  }, {
    'code': '002051004',
    'children': [{
      'code': '002051004002',
      'text': '秦都区'
    }, {
      'code': '002051004003',
      'text': '杨陵区'
    }, {
      'code': '002051004004',
      'text': '渭城区'
    }, {
      'code': '002051004022',
      'text': '三原县'
    }, {
      'code': '002051004023',
      'text': '泾阳县'
    }, {
      'code': '002051004024',
      'text': '乾县'
    }, {
      'code': '002051004025',
      'text': '礼泉县'
    }, {
      'code': '002051004026',
      'text': '永寿县'
    }, {
      'code': '002051004027',
      'text': '彬县'
    }, {
      'code': '002051004028',
      'text': '长武县'
    }, {
      'code': '002051004029',
      'text': '旬邑县'
    }, {
      'code': '002051004030',
      'text': '淳化县'
    }, {
      'code': '002051004031',
      'text': '武功县'
    }, {
      'code': '002051004081',
      'text': '兴平市'
    }],
    'text': '咸阳市'
  }, {
    'code': '002051005',
    'children': [{
      'code': '002051005002',
      'text': '临渭区'
    }, {
      'code': '002051005021',
      'text': '华县'
    }, {
      'code': '002051005022',
      'text': '潼关县'
    }, {
      'code': '002051005023',
      'text': '大荔县'
    }, {
      'code': '002051005024',
      'text': '合阳县'
    }, {
      'code': '002051005025',
      'text': '澄城县'
    }, {
      'code': '002051005026',
      'text': '蒲城县'
    }, {
      'code': '002051005027',
      'text': '白水县'
    }, {
      'code': '002051005028',
      'text': '富平县'
    }, {
      'code': '002051005081',
      'text': '韩城市'
    }, {
      'code': '002051005082',
      'text': '华阴市'
    }, {
      'code': '002051005098',
      'text': '华州区'
    }, {
      'code': '002051005099',
      'text': '开发区'
    }],
    'text': '渭南市'
  }, {
    'code': '002051006',
    'children': [{
      'code': '002051006002',
      'text': '宝塔区'
    }, {
      'code': '002051006010',
      'text': '吴起县'
    }, {
      'code': '002051006021',
      'text': '延长县'
    }, {
      'code': '002051006022',
      'text': '延川县'
    }, {
      'code': '002051006023',
      'text': '子长县'
    }, {
      'code': '002051006024',
      'text': '安塞县'
    }, {
      'code': '002051006025',
      'text': '志丹县'
    }, {
      'code': '002051006026',
      'text': '吴旗县'
    }, {
      'code': '002051006027',
      'text': '甘泉县'
    }, {
      'code': '002051006028',
      'text': '富县'
    }, {
      'code': '002051006029',
      'text': '洛川县'
    }, {
      'code': '002051006030',
      'text': '宜川县'
    }, {
      'code': '002051006031',
      'text': '黄龙县'
    }, {
      'code': '002051006032',
      'text': '黄陵县'
    }],
    'text': '延安市'
  }, {
    'code': '002051007',
    'children': [{
      'code': '002051007002',
      'text': '汉台区'
    }, {
      'code': '002051007010',
      'text': '经济开发区南区'
    }, {
      'code': '002051007021',
      'text': '南郑县'
    }, {
      'code': '002051007022',
      'text': '城固县'
    }, {
      'code': '002051007023',
      'text': '洋县'
    }, {
      'code': '002051007024',
      'text': '西乡县'
    }, {
      'code': '002051007025',
      'text': '勉县'
    }, {
      'code': '002051007026',
      'text': '宁强县'
    }, {
      'code': '002051007027',
      'text': '略阳县'
    }, {
      'code': '002051007028',
      'text': '镇巴县'
    }, {
      'code': '002051007029',
      'text': '留坝县'
    }, {
      'code': '002051007030',
      'text': '佛坪县'
    }],
    'text': '汉中市'
  }, {
    'code': '002051024',
    'children': [{
      'code': '002051024001',
      'text': '安康市'
    }, {
      'code': '002051024002',
      'text': '汉滨区'
    }, {
      'code': '002051024022',
      'text': '汉阴县'
    }, {
      'code': '002051024023',
      'text': '石泉县'
    }, {
      'code': '002051024024',
      'text': '宁陕县'
    }, {
      'code': '002051024025',
      'text': '紫阳县'
    }, {
      'code': '002051024026',
      'text': '岚皋县'
    }, {
      'code': '002051024027',
      'text': '平利县'
    }, {
      'code': '002051024028',
      'text': '镇坪县'
    }, {
      'code': '002051024029',
      'text': '旬阳县'
    }, {
      'code': '002051024030',
      'text': '白河县'
    }],
    'text': '安康市'
  }, {
    'code': '002051025',
    'children': [{
      'code': '002051025001',
      'text': '商州市'
    }, {
      'code': '002051025022',
      'text': '洛南县'
    }, {
      'code': '002051025023',
      'text': '丹凤县'
    }, {
      'code': '002051025024',
      'text': '商南县'
    }, {
      'code': '002051025025',
      'text': '山阳县'
    }, {
      'code': '002051025026',
      'text': '镇安县'
    }, {
      'code': '002051025027',
      'text': '柞水县'
    }, {
      'code': '002051025099',
      'text': '商州区'
    }],
    'text': '商洛市'
  }, {
    'code': '002051027',
    'children': [{
      'code': '002051027001',
      'text': '榆林市'
    }, {
      'code': '002051027022',
      'text': '神木县'
    }, {
      'code': '002051027023',
      'text': '府谷县'
    }, {
      'code': '002051027024',
      'text': '横山县'
    }, {
      'code': '002051027025',
      'text': '靖边县'
    }, {
      'code': '002051027026',
      'text': '定边县'
    }, {
      'code': '002051027027',
      'text': '绥德县'
    }, {
      'code': '002051027028',
      'text': '米脂县'
    }, {
      'code': '002051027029',
      'text': '佳县'
    }, {
      'code': '002051027030',
      'text': '吴堡县'
    }, {
      'code': '002051027031',
      'text': '清涧县'
    }, {
      'code': '002051027032',
      'text': '子洲县'
    }, {
      'code': '002051027099',
      'text': '榆阳区'
    }],
    'text': '榆林市'
  }],
  'text': '陕西'
}, {
  'code': '002052',
  'children': [{
    'code': '002052001',
    'children': [{
      'code': '002052001002',
      'text': '城关区'
    }, {
      'code': '002052001003',
      'text': '七里河区'
    }, {
      'code': '002052001004',
      'text': '西固区'
    }, {
      'code': '002052001005',
      'text': '安宁区'
    }, {
      'code': '002052001011',
      'text': '红古区'
    }, {
      'code': '002052001021',
      'text': '永登县'
    }, {
      'code': '002052001022',
      'text': '皋兰县'
    }, {
      'code': '002052001023',
      'text': '榆中县'
    }, {
      'code': '002052001024',
      'text': '兰州新区'
    }],
    'text': '兰州市'
  }, {
    'code': '002052002',
    'children': [{
      'code': '002052002001',
      'text': '嘉峪关市'
    }, {
      'code': '002052002010',
      'text': '雄关区'
    }, {
      'code': '002052002011',
      'text': '镜铁区'
    }, {
      'code': '002052002012',
      'text': '长城区'
    }],
    'text': '嘉峪关市'
  }, {
    'code': '002052003',
    'children': [{
      'code': '002052003002',
      'text': '金川区'
    }, {
      'code': '002052003021',
      'text': '永昌县'
    }],
    'text': '金昌市'
  }, {
    'code': '002052004',
    'children': [{
      'code': '002052004002',
      'text': '白银区'
    }, {
      'code': '002052004003',
      'text': '平川区'
    }, {
      'code': '002052004022',
      'text': '靖远县'
    }, {
      'code': '002052004023',
      'text': '景泰县'
    }, {
      'code': '002052004024',
      'text': '会宁县'
    }],
    'text': '白银市'
  }, {
    'code': '002052005',
    'children': [{
      'code': '002052005002',
      'text': '秦州区'
    }, {
      'code': '002052005021',
      'text': '清水县'
    }, {
      'code': '002052005022',
      'text': '秦安县'
    }, {
      'code': '002052005023',
      'text': '甘谷县'
    }, {
      'code': '002052005024',
      'text': '武山县'
    }, {
      'code': '002052005025',
      'text': '张家川回族自治县'
    }, {
      'code': '002052005099',
      'text': '麦积区'
    }],
    'text': '天水市'
  }, {
    'code': '002052021',
    'children': [{
      'code': '002052021001',
      'text': '玉门市'
    }, {
      'code': '002052021003',
      'text': '敦煌市'
    }, {
      'code': '002052021023',
      'text': '金塔县'
    }, {
      'code': '002052021024',
      'text': '肃北蒙古族自治县'
    }, {
      'code': '002052021025',
      'text': '阿克塞哈萨克族自治县'
    }, {
      'code': '002052021026',
      'text': '瓜州县'
    }, {
      'code': '002052021099',
      'text': '肃州区'
    }, {
      'code': '002052021100',
      'text': '东风场区'
    }],
    'text': '酒泉市'
  }, {
    'code': '002052022',
    'children': [{
      'code': '002052022022',
      'text': '肃南裕固族自治县'
    }, {
      'code': '002052022023',
      'text': '民乐县'
    }, {
      'code': '002052022024',
      'text': '临泽县'
    }, {
      'code': '002052022025',
      'text': '高台县'
    }, {
      'code': '002052022026',
      'text': '山丹县'
    }, {
      'code': '002052022099',
      'text': '甘州区'
    }],
    'text': '张掖市'
  }, {
    'code': '002052023',
    'children': [{
      'code': '002052023022',
      'text': '民勤县'
    }, {
      'code': '002052023023',
      'text': '古浪县'
    }, {
      'code': '002052023026',
      'text': '天祝藏族自治县'
    }, {
      'code': '002052023099',
      'text': '凉州区'
    }],
    'text': '武威市'
  }, {
    'code': '002052024',
    'children': [{
      'code': '002052024024',
      'text': '通渭县'
    }, {
      'code': '002052024025',
      'text': '陇西县'
    }, {
      'code': '002052024026',
      'text': '渭源县'
    }, {
      'code': '002052024027',
      'text': '临洮县'
    }, {
      'code': '002052024028',
      'text': '漳县'
    }, {
      'code': '002052024029',
      'text': '岷县'
    }, {
      'code': '002052024099',
      'text': '安定区'
    }],
    'text': '定西市'
  }, {
    'code': '002052026',
    'children': [{
      'code': '002052026021',
      'text': '武都区'
    }, {
      'code': '002052026023',
      'text': '宕昌县'
    }, {
      'code': '002052026024',
      'text': '成县'
    }, {
      'code': '002052026025',
      'text': '康县'
    }, {
      'code': '002052026026',
      'text': '文县'
    }, {
      'code': '002052026027',
      'text': '西和县'
    }, {
      'code': '002052026028',
      'text': '礼县'
    }, {
      'code': '002052026029',
      'text': '两当县'
    }, {
      'code': '002052026030',
      'text': '徽县'
    }, {
      'code': '002052026031',
      'text': '碧口县'
    }],
    'text': '陇南市'
  }, {
    'code': '002052027',
    'children': [{
      'code': '002052027022',
      'text': '泾川县'
    }, {
      'code': '002052027023',
      'text': '灵台县'
    }, {
      'code': '002052027024',
      'text': '崇信县'
    }, {
      'code': '002052027025',
      'text': '华亭县'
    }, {
      'code': '002052027026',
      'text': '庄浪县'
    }, {
      'code': '002052027027',
      'text': '静宁县'
    }, {
      'code': '002052027099',
      'text': '崆峒区'
    }],
    'text': '平凉市'
  }, {
    'code': '002052028',
    'children': [{
      'code': '002052028001',
      'text': '西峰区'
    }, {
      'code': '002052028022',
      'text': '环县'
    }, {
      'code': '002052028023',
      'text': '华池县'
    }, {
      'code': '002052028024',
      'text': '合水县'
    }, {
      'code': '002052028025',
      'text': '正宁县'
    }, {
      'code': '002052028026',
      'text': '宁县'
    }, {
      'code': '002052028027',
      'text': '镇原县'
    }, {
      'code': '002052028097',
      'text': '庆城县'
    }],
    'text': '庆阳市'
  }, {
    'code': '002052029',
    'children': [{
      'code': '002052029001',
      'text': '临夏市（县级市）'
    }, {
      'code': '002052029021',
      'text': '临夏县'
    }, {
      'code': '002052029022',
      'text': '康乐县'
    }, {
      'code': '002052029023',
      'text': '永靖县'
    }, {
      'code': '002052029024',
      'text': '广河县'
    }, {
      'code': '002052029025',
      'text': '和政县'
    }, {
      'code': '002052029026',
      'text': '东乡族自治县'
    }, {
      'code': '002052029027',
      'text': '积石山保安族东乡族撒拉族自治县'
    }],
    'text': '临夏回族自治州'
  }, {
    'code': '002052030',
    'children': [{
      'code': '002052030001',
      'text': '合作市'
    }, {
      'code': '002052030021',
      'text': '临潭县'
    }, {
      'code': '002052030022',
      'text': '卓尼县'
    }, {
      'code': '002052030023',
      'text': '舟曲县'
    }, {
      'code': '002052030024',
      'text': '迭部县'
    }, {
      'code': '002052030025',
      'text': '玛曲县'
    }, {
      'code': '002052030026',
      'text': '碌曲县'
    }, {
      'code': '002052030027',
      'text': '夏河县'
    }],
    'text': '甘南藏族自治州'
  }],
  'text': '甘肃'
}, {
  'code': '002053',
  'children': [{
    'code': '002053001',
    'children': [{
      'code': '002053001002',
      'text': '城东区'
    }, {
      'code': '002053001003',
      'text': '城中区'
    }, {
      'code': '002053001004',
      'text': '城西区'
    }, {
      'code': '002053001005',
      'text': '城北区'
    }, {
      'code': '002053001021',
      'text': '大通回族土族自治县'
    }, {
      'code': '002053001022',
      'text': '湟中县'
    }, {
      'code': '002053001023',
      'text': '湟源县'
    }],
    'text': '西宁市'
  }, {
    'code': '002053021',
    'children': [{
      'code': '002053021021',
      'text': '平安县'
    }, {
      'code': '002053021022',
      'text': '民和回族土族自治县'
    }, {
      'code': '002053021023',
      'text': '乐都县'
    }, {
      'code': '002053021026',
      'text': '互助土族自治县'
    }, {
      'code': '002053021027',
      'text': '化隆回族自治县'
    }, {
      'code': '002053021028',
      'text': '循化撒拉族自治县'
    }],
    'text': '海东地区'
  }, {
    'code': '002053022',
    'children': [{
      'code': '002053022021',
      'text': '门源回族自治县'
    }, {
      'code': '002053022022',
      'text': '祁连县'
    }, {
      'code': '002053022023',
      'text': '海晏县'
    }, {
      'code': '002053022024',
      'text': '刚察县'
    }, {
      'code': '002053022099',
      'text': '海北州属'
    }],
    'text': '海北藏族自治州'
  }, {
    'code': '002053023',
    'children': [{
      'code': '002053023021',
      'text': '同仁县'
    }, {
      'code': '002053023022',
      'text': '尖扎县'
    }, {
      'code': '002053023023',
      'text': '泽库县'
    }, {
      'code': '002053023024',
      'text': '河南蒙古族自治县'
    }, {
      'code': '002053023099',
      'text': '黄南州属'
    }],
    'text': '黄南藏族自治州'
  }, {
    'code': '002053025',
    'children': [{
      'code': '002053025021',
      'text': '共和县'
    }, {
      'code': '002053025022',
      'text': '同德县'
    }, {
      'code': '002053025023',
      'text': '贵德县'
    }, {
      'code': '002053025024',
      'text': '兴海县'
    }, {
      'code': '002053025025',
      'text': '贵南县'
    }, {
      'code': '002053025099',
      'text': '海南州属'
    }],
    'text': '海南藏族自治州'
  }, {
    'code': '002053026',
    'children': [{
      'code': '002053026021',
      'text': '玛沁县'
    }, {
      'code': '002053026022',
      'text': '班玛县'
    }, {
      'code': '002053026023',
      'text': '甘德县'
    }, {
      'code': '002053026024',
      'text': '达日县'
    }, {
      'code': '002053026025',
      'text': '久治县'
    }, {
      'code': '002053026026',
      'text': '玛多县'
    }, {
      'code': '002053026099',
      'text': '果洛州属'
    }],
    'text': '果洛藏族自治州'
  }, {
    'code': '002053027',
    'children': [{
      'code': '002053027021',
      'text': '玉树县'
    }, {
      'code': '002053027022',
      'text': '杂多县'
    }, {
      'code': '002053027023',
      'text': '称多县'
    }, {
      'code': '002053027024',
      'text': '治多县'
    }, {
      'code': '002053027025',
      'text': '囊谦县'
    }, {
      'code': '002053027026',
      'text': '曲麻莱县'
    }, {
      'code': '002053027099',
      'text': '玉树州属'
    }],
    'text': '玉树藏族自治州'
  }, {
    'code': '002053028',
    'children': [{
      'code': '002053028001',
      'text': '格尔木市'
    }, {
      'code': '002053028002',
      'text': '德令哈市'
    }, {
      'code': '002053028010',
      'text': '大柴旦行委'
    }, {
      'code': '002053028011',
      'text': '冷湖行委'
    }, {
      'code': '002053028012',
      'text': '茫崖行委'
    }, {
      'code': '002053028021',
      'text': '乌兰县'
    }, {
      'code': '002053028022',
      'text': '都兰县'
    }, {
      'code': '002053028023',
      'text': '天峻县'
    }, {
      'code': '002053028099',
      'text': '海西州属'
    }],
    'text': '海西蒙古族藏族自治州'
  }],
  'text': '青海'
}, {
  'code': '002054',
  'children': [{
    'code': '002054001',
    'children': [{
      'code': '002054001021',
      'text': '永宁县'
    }, {
      'code': '002054001022',
      'text': '贺兰县'
    }, {
      'code': '002054001095',
      'text': '灵武市'
    }, {
      'code': '002054001097',
      'text': '西夏区'
    }, {
      'code': '002054001098',
      'text': '金凤区'
    }, {
      'code': '002054001099',
      'text': '兴庆区'
    }],
    'text': '银川市'
  }, {
    'code': '002054002',
    'children': [{
      'code': '002054002002',
      'text': '大武口区'
    }, {
      'code': '002054002021',
      'text': '平罗县'
    }, {
      'code': '002054002023',
      'text': '惠农区'
    }],
    'text': '石嘴山市'
  }, {
    'code': '002054003',
    'children': [{
      'code': '002054003002',
      'text': '利通区'
    }, {
      'code': '002054003023',
      'text': '盐池县'
    }, {
      'code': '002054003024',
      'text': '同心县'
    }, {
      'code': '002054003081',
      'text': '青铜峡市'
    }, {
      'code': '002054003099',
      'text': '红寺堡开发区'
    }],
    'text': '吴忠市'
  }, {
    'code': '002054022',
    'children': [{
      'code': '002054022023',
      'text': '西吉县'
    }, {
      'code': '002054022024',
      'text': '隆德县'
    }, {
      'code': '002054022025',
      'text': '泾源县'
    }, {
      'code': '002054022026',
      'text': '彭阳县'
    }, {
      'code': '002054022099',
      'text': '原州区'
    }],
    'text': '固原市'
  }, {
    'code': '002054023',
    'children': [{
      'code': '002054023001',
      'text': '沙坡头区'
    }, {
      'code': '002054023002',
      'text': '中宁县'
    }, {
      'code': '002054023003',
      'text': '海原县'
    }],
    'text': '中卫市'
  }],
  'text': '宁夏'
}, {
  'code': '002055',
  'children': [{
    'code': '002055001',
    'children': [{
      'code': '002055001002',
      'text': '天山区'
    }, {
      'code': '002055001003',
      'text': '沙依巴克区'
    }, {
      'code': '002055001004',
      'text': '新市区'
    }, {
      'code': '002055001005',
      'text': '水磨沟区'
    }, {
      'code': '002055001006',
      'text': '头屯河区'
    }, {
      'code': '002055001007',
      'text': '达坂城区'
    }, {
      'code': '002055001008',
      'text': '米东区'
    }, {
      'code': '002055001021',
      'text': '乌鲁木齐县'
    }],
    'text': '乌鲁木齐市'
  }, {
    'code': '002055002',
    'children': [{
      'code': '002055002002',
      'text': '独山子区'
    }, {
      'code': '002055002003',
      'text': '克拉玛依区'
    }, {
      'code': '002055002004',
      'text': '白碱滩区'
    }, {
      'code': '002055002005',
      'text': '乌尔禾区'
    }, {
      'code': '002055002006',
      'text': '小拐乡'
    }],
    'text': '克拉玛依市'
  }, {
    'code': '002055021',
    'children': [{
      'code': '002055021001',
      'text': '吐鲁番市'
    }, {
      'code': '002055021022',
      'text': '鄯善县'
    }, {
      'code': '002055021023',
      'text': '托克逊县'
    }, {
      'code': '002055021025',
      'text': '高昌区'
    }],
    'text': '吐鲁番地区'
  }, {
    'code': '002055022',
    'children': [{
      'code': '002055022001',
      'text': '哈密市'
    }, {
      'code': '002055022022',
      'text': '巴里坤哈萨克自治县'
    }, {
      'code': '002055022023',
      'text': '伊吾县'
    }],
    'text': '哈密地区'
  }, {
    'code': '002055023',
    'children': [{
      'code': '002055023001',
      'text': '昌吉市'
    }, {
      'code': '002055023002',
      'text': '阜康市'
    }, {
      'code': '002055023023',
      'text': '呼图壁县'
    }, {
      'code': '002055023024',
      'text': '玛纳斯县'
    }, {
      'code': '002055023025',
      'text': '奇台县'
    }, {
      'code': '002055023027',
      'text': '吉木萨尔县'
    }, {
      'code': '002055023028',
      'text': '木垒哈萨克自治县'
    }, {
      'code': '002055023029',
      'text': '准东经济技术开发区'
    }],
    'text': '昌吉回族自治州'
  }, {
    'code': '002055027',
    'children': [{
      'code': '002055027001',
      'text': '博乐市'
    }, {
      'code': '002055027022',
      'text': '精河县'
    }, {
      'code': '002055027023',
      'text': '温泉县'
    }, {
      'code': '002055027024',
      'text': '阿拉山口口岸'
    }, {
      'code': '002055027025',
      'text': '双河市'
    }],
    'text': '博尔塔拉蒙古自治州'
  }, {
    'code': '002055028',
    'children': [{
      'code': '002055028001',
      'text': '库尔勒市'
    }, {
      'code': '002055028022',
      'text': '轮台县'
    }, {
      'code': '002055028023',
      'text': '尉犁县'
    }, {
      'code': '002055028024',
      'text': '若羌县'
    }, {
      'code': '002055028025',
      'text': '且末县'
    }, {
      'code': '002055028026',
      'text': '焉耆回族自治县'
    }, {
      'code': '002055028027',
      'text': '和静县'
    }, {
      'code': '002055028028',
      'text': '和硕县'
    }, {
      'code': '002055028029',
      'text': '博湖县'
    }],
    'text': '巴音郭楞蒙古族自治州'
  }, {
    'code': '002055029',
    'children': [{
      'code': '002055029001',
      'text': '阿克苏市'
    }, {
      'code': '002055029022',
      'text': '温宿县'
    }, {
      'code': '002055029023',
      'text': '库车县'
    }, {
      'code': '002055029024',
      'text': '沙雅县'
    }, {
      'code': '002055029025',
      'text': '新和县'
    }, {
      'code': '002055029026',
      'text': '拜城县'
    }, {
      'code': '002055029027',
      'text': '乌什县'
    }, {
      'code': '002055029028',
      'text': '阿瓦提县'
    }, {
      'code': '002055029029',
      'text': '柯坪县'
    }],
    'text': '阿克苏地区'
  }, {
    'code': '002055030',
    'children': [{
      'code': '002055030001',
      'text': '阿图什市'
    }, {
      'code': '002055030022',
      'text': '阿克陶县'
    }, {
      'code': '002055030023',
      'text': '阿合奇县'
    }, {
      'code': '002055030024',
      'text': '乌恰县'
    }],
    'text': '克孜勒苏柯尔克孜自治州'
  }, {
    'code': '002055031',
    'children': [{
      'code': '002055031001',
      'text': '喀什市'
    }, {
      'code': '002055031021',
      'text': '疏附县'
    }, {
      'code': '002055031022',
      'text': '疏勒县'
    }, {
      'code': '002055031023',
      'text': '英吉沙县'
    }, {
      'code': '002055031024',
      'text': '泽普县'
    }, {
      'code': '002055031025',
      'text': '莎车县'
    }, {
      'code': '002055031026',
      'text': '叶城县'
    }, {
      'code': '002055031027',
      'text': '麦盖提县'
    }, {
      'code': '002055031028',
      'text': '岳普湖县'
    }, {
      'code': '002055031029',
      'text': '伽师县'
    }, {
      'code': '002055031030',
      'text': '巴楚县'
    }, {
      'code': '002055031031',
      'text': '塔什库尔干塔吉克自治县'
    }, {
      'code': '002055031032',
      'text': '奎依巴格镇'
    }],
    'text': '喀什地区'
  }, {
    'code': '002055032',
    'children': [{
      'code': '002055032001',
      'text': '和田市'
    }, {
      'code': '002055032021',
      'text': '和田县'
    }, {
      'code': '002055032022',
      'text': '墨玉县'
    }, {
      'code': '002055032023',
      'text': '皮山县'
    }, {
      'code': '002055032024',
      'text': '洛浦县'
    }, {
      'code': '002055032025',
      'text': '策勒县'
    }, {
      'code': '002055032026',
      'text': '于田县'
    }, {
      'code': '002055032027',
      'text': '民丰县'
    }],
    'text': '和田地区'
  }, {
    'code': '002055040',
    'children': [{
      'code': '002055040001',
      'text': '奎屯市'
    }],
    'text': '奎屯地区'
  }, {
    'code': '002055041',
    'children': [{
      'code': '002055041001',
      'text': '伊宁市'
    }, {
      'code': '002055041021',
      'text': '伊宁县'
    }, {
      'code': '002055041022',
      'text': '察布查尔锡伯自治县'
    }, {
      'code': '002055041023',
      'text': '霍城县'
    }, {
      'code': '002055041024',
      'text': '巩留县'
    }, {
      'code': '002055041025',
      'text': '新源县'
    }, {
      'code': '002055041026',
      'text': '昭苏县'
    }, {
      'code': '002055041027',
      'text': '特克斯县'
    }, {
      'code': '002055041028',
      'text': '尼勒克县'
    }, {
      'code': '002055041029',
      'text': '霍尔果斯口岸'
    }, {
      'code': '002055041030',
      'text': '奎屯市'
    }, {
      'code': '002055041031',
      'text': '霍尔果斯市'
    }],
    'text': '伊犁哈萨克自治州'
  }, {
    'code': '002055042',
    'children': [{
      'code': '002055042001',
      'text': '塔城市'
    }, {
      'code': '002055042002',
      'text': '乌苏市'
    }, {
      'code': '002055042021',
      'text': '额敏县'
    }, {
      'code': '002055042023',
      'text': '沙湾县'
    }, {
      'code': '002055042024',
      'text': '托里县'
    }, {
      'code': '002055042025',
      'text': '裕民县'
    }, {
      'code': '002055042026',
      'text': '和布克塞尔蒙古自治县'
    }],
    'text': '塔城地区'
  }, {
    'code': '002055043',
    'children': [{
      'code': '002055043001',
      'text': '阿勒泰市'
    }, {
      'code': '002055043021',
      'text': '布尔津县'
    }, {
      'code': '002055043022',
      'text': '富蕴县'
    }, {
      'code': '002055043023',
      'text': '福海县'
    }, {
      'code': '002055043024',
      'text': '哈巴河县'
    }, {
      'code': '002055043025',
      'text': '青河县'
    }, {
      'code': '002055043026',
      'text': '吉木乃县'
    }, {
      'code': '002055043027',
      'text': '北屯市'
    }],
    'text': '阿勒泰地区'
  }, {
    'code': '002055090',
    'children': [{
      'code': '002055090001',
      'text': '石河子市'
    }],
    'text': '石河子市'
  }, {
    'code': '002055091',
    'children': [{
      'code': '002055091',
      'text': '阿拉尔市'
    }],
    'text': '阿拉尔市'
  }, {
    'code': '002055092',
    'children': [{
      'code': '002055092',
      'text': '图木舒克市'
    }],
    'text': '图木舒克市'
  }, {
    'code': '002055093',
    'children': [{
      'code': '002055093',
      'text': '五家渠市'
    }],
    'text': '五家渠市'
  }, {
    'code': '002055094',
    'children': [{
      'code': '002055094001',
      'text': '铁门关市'
    }],
    'text': '铁门关市'
  }, {
    'code': '002055095',
    'children': [{
      'code': '002055095001',
      'text': '昆玉市'
    }],
    'text': '昆玉市'
  }],
  'text': '新疆'
}];